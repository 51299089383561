const ClaimedReward = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 15C15.728 15 18.75 12.09 18.75 8.5C18.75 4.91 15.728 2 12 2C8.272 2 5.25 4.91 5.25 8.5C5.25 12.09 8.272 15 12 15Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5038 5.43298L13.0347 6.4974C13.1066 6.64173 13.2956 6.78605 13.4665 6.81312L14.4292 6.97548C15.041 7.07471 15.185 7.52573 14.7441 7.96774L13.9973 8.71644C13.8714 8.84272 13.7994 9.08628 13.8444 9.26669L14.0603 10.1958C14.2313 10.9265 13.8444 11.2151 13.1966 10.8272L12.2969 10.295C12.2055 10.2461 12.1036 10.2206 12 10.2206C11.8964 10.2206 11.7945 10.2461 11.7031 10.295L10.8034 10.8272C10.1556 11.2061 9.76873 10.9265 9.93968 10.1958L10.1556 9.26669C10.1916 9.0953 10.1286 8.84272 10.0027 8.71644L9.2559 7.96774C8.81504 7.52573 8.959 7.08373 9.5708 6.97548L10.5335 6.81312C10.6954 6.78605 10.8844 6.64173 10.9563 6.4974L11.4872 5.43298C11.7481 4.85567 12.2159 4.85567 12.5038 5.43298V5.43298Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.51977 13.5201L7.50977 20.9001C7.50977 21.8001 8.13977 22.2401 8.91977 21.8701L11.5998 20.6001C11.8198 20.4901 12.1898 20.4901 12.4098 20.6001L15.0998 21.8701C15.8698 22.2301 16.5098 21.8001 16.5098 20.9001V13.3401"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClaimedReward;
