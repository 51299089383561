import React, { useEffect, useState, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Staking as StakingUI } from "../components/Staking/Staking";
import { Calculator } from "./Calculator/Calculator";
import { toast } from "react-toastify";
import Web3 from "web3";
import { Button } from "./UI/button/Button";
import { Popup } from "./UI/popup/Popup";

// hooks
import { useConnectMeta } from "../hooks/use-connect";
import { useStake } from "../hooks/use-stake";
import { useTableParameters } from "../hooks/useTableParameters";
import { useMobileWidth } from "../hooks/useMobileWidth";
import { useOnScreen } from "../hooks/useOnScreen";
import { useApp } from "../hooks/use-app";
import axios from "../api/axios";
import { decryptEnv } from "../utils/decryptEnv";
import { axiosUtility } from "../utils/axiosUtility";

import { AddSquareIcon } from "../assets/svg";
import { accountSummaryData } from "../dummyComponents/staking";

const Staking = ({ translates, helpSupportClick }) => {
  const Router = decryptEnv(process.env.REACT_APP_STAKING_CONTRACT_ADDRESS);
  const tokenAddress = decryptEnv(process.env.REACT_APP_TOKEN_ADDRESS);

  const [createStakingPopUpActive, setCreateStakingPopUpActive] =
    useState(false);
  const [approveResonse, setApproveResonse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetchCount, setFetchCount] = useState(0);
  const [apyPercent, setApyPercent] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [unstakeLoading, setUnstakeLoading] = useState(false);
  const [harvestLoading, setHarvestLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [balanceStakeLoading, setBalanceStakeLoading] = useState(false);
  const [stakingLoading, setStakingLoading] = useState(false);
  const [currencyStakes, setCurrencyStakes] = useState([]);
  const [currencyStakesLoading, setCurrencyStakesLoading] = useState(false);

  const infiniteScrollRef = createRef();
  const isLoadMoreButtonOnScreen = useOnScreen(infiniteScrollRef);

  const providerType = useSelector((state) => state.connect.providerType);
  const exchangeRate = useSelector((state) => state.appState?.rates?.atr?.usd);
  const sideBarOpen = useSelector((state) => state.appState.sideBarOpen);
  const appState = useSelector((state) => state.appState);
  const triedReconnect = useSelector((state) => state.appState?.triedReconnect);
  const hasMoreData = useSelector((state) => state.stake.hasMoreData);
  const tiers = useSelector((state) => state.appState?.tiers);
  const tokenBalance = useSelector((state) => state.connect.tokenBalance);
  const accountsData = useSelector((state) => state?.appState?.accountsData);
  const lastConnectionType = useSelector(
    (state) => state.connect.lastConnectionType
  );

  const {
    depositAmount,
    balance,
    stakersInfo,
    stackContractInfo,
    timeperiod,
    stakersRecord,
    isAllowance,
    timeperiodDate,
  } = useSelector((state) => state.stake);

  const { width } = useMobileWidth();
  const { updateState } = useApp();
  const { active, account, library } = useConnectMeta();
  const dispatch = useDispatch();
  const { durationOptions } = useTableParameters("staking");
  const {
    approve,
    stake,
    unstake,
    harvest,
    setMaxWithdrawal: handleMaxClick,
    handleTimeperiodDate,
    handleDepositAmount,
    handleTimePeriod,
    getStackerInfo,
    checkAllowance,
  } = useStake({ Router, tokenAddress });

  const isActive = appState?.userData?.active;
  const stakedTotal = appState?.userData?.stakedTotal;

  const getWalletBalance = async () => {
    let response = await axiosUtility("/api/accounts/get_account", {}, "POST");

    setWalletBalance(
      response?.data?.data?.accountBalances.find(
        (obj) => obj.account_category === "main"
      ).balance
    );
  };

  useEffect(() => {
    getWalletBalance();
    if (
      account &&
      ((triedReconnect && active) ||
        providerType === "walletConnect" ||
        lastConnectionType === "email")
    ) {
      getStackerInfo(0 + 5 * fetchCount, 10 + 5 * fetchCount)
        .then(() => {
          setIsFetching(false);
          setLoading(false);
        })
        .catch((error) => {
          setIsFetching(false);
          setLoading(false);
        });
    }
  }, [account, triedReconnect, active, fetchCount]);

  async function refetchStakersRecord() {
    try {
      dispatch({
        type: "UPDATE_STAKE_STATE",
        payload: {
          stakersRecord: [],
        },
      });
      setLoading(true);
      setIsFetching(false);
      await getStackerInfo(0, 10 + 5 * fetchCount);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (
      account &&
      ((triedReconnect && active) || providerType === "walletConnect")
    ) {
      checkAllowance();
    }
  }, [account, triedReconnect, active, depositAmount]);

  const handleConnect = () => {
    if (sideBarOpen) {
      dispatch({
        type: "SET_SIDE_BAR",
        payload: { sideBarOpen: !sideBarOpen },
      });
    } else {
      dispatch({
        type: "SET_SIDE_BAR",
        payload: { sideBarOpen: !sideBarOpen, sideBar: "connect" },
      });
    }
  };

  const handlePopUpOpen = () => {
    setCreateStakingPopUpActive(true);
    getWalletBalance();
  };

  const th = [
    {
      name: "Staked Amount",
      width: 18,
      mobileWidth: width > 400 ? 25 : 100,
      id: 0,
    },
    {
      name: "Remaining Days",
      width: 18,
      mobileWidth: width > 400 ? 25 : 100,
      id: 1,
    },
    {
      name: "Real Time Rewards",
      width: 18,
      mobileWidth: 25,
      id: 2,
    },
    {
      name: "Stake Date",
      width: 18,
      id: 3,
    },
    {
      name: "Unstake Date",
      width: 18,
      id: 4,
    },
    {
      name: "APR",
      width: 10,
      mobileWidth: width > 400 ? 25 : false,
      id: 5,
    },
    {
      name: "",
      width: 0,
      id: 6,
      mobileWidth: 35,
      className: "table-button-none",
      onClick: (index) => {
        if (accountsData[2]?.balance < 0) {
          toast.error("Balance is less than 0. Cannot unstake.", {
            autoClose: false,
          });
          return;
        }
        setUnstakeLoading(true);
        unstake(
          index,
          () => {
            setUnstakeLoading(false);
            axios
              .post("api/transactions/unstake_transaction", {
                address: account,
                index,
              })
              .then((res) => {
                refetchStakersRecord();
              })
              .catch((e) => {
                console.log(e);
              });
          },
          () => {
            setUnstakeLoading(false);
          }
        );
      },
    },
    {
      name: "",
      width: 0,
      id: 7,
      mobileWidth: 20,
      className: "table-button-none",
      onClick: (index) => {
        setHarvestLoading(true);
        harvest(
          index,
          (resept) => {
            setHarvestLoading(false);
            axios
              .post("api/transactions/harvest_transaction", {
                resept,
              })
              .then((res) => {
                refetchStakersRecord();
              })
              .catch((e) => {
                console.log(e);
              });
          },
          () => {
            setHarvestLoading(false);
          }
        );
      },
    },
  ];

  const currencyStakesTableHead = [
    {
      name: "Staked Amount",
      width: 15,
      mobileWidth: width > 400 ? 25 : 100,
      id: 0,
    },
    {
      name: "Period",
      width: 15,
      mobileWidth: width > 400 ? 25 : 100,
      id: 1,
    },
    {
      name: "Percentage",
      width: 15,
      mobileWidth: width > 400 ? 25 : false,
      id: 2,
    },
    {
      name: "Reward",
      width: 15,
      mobileWidth: width > 400 ? 25 : false,
      id: 3,
    },
    {
      name: "Till Today By Seconds",
      width: 15,
      id: 4,
    },
    {
      name: "Till Unstake Date",
      width: 15,
      id: 5,
    },
    {
      name: "Unstake Date",
      width: 15,
      id: 6,
    },
  ];

  function roundUpToTwoDecimals(number) {
    const roundedNumber = Math.ceil(number * 100) / 100;
    return roundedNumber.toFixed(2);
  }

  const handleCalculatorSubmit = async (stakeAfterApprove) => {
    setApproveResonse(null);
    if (!account) {
      handleConnect();
    }

    async function handleStake() {
      let refStatus = appState?.userData?.referralStatus;
      let stakeAmount = parseFloat(
        roundUpToTwoDecimals(+depositAmount / +appState?.rates?.["atr"]?.usd)
      );
      let allowStake = false;

      if (!refStatus) {
        if (stakedTotal < 500) {
          if (stakeAmount + stakedTotal <= 500) {
            allowStake = true;
          }
        }
      } else {
        allowStake = true;
      }

      if (allowStake) {
        stake(
          roundUpToTwoDecimals(+depositAmount / +appState?.rates?.["atr"]?.usd),
          async () => {
            await axios
              .post(
                "/api/accounts/activate-account",
                {
                  address: account,
                },
                {
                  timeout: 120000,
                }
              )
              .then((res) => {
                if (res.data?.account) {
                  updateState();
                }
              })
              .catch((e) => {});
            setStakingLoading(false);
            refetchStakersRecord();
            handleDepositAmount("");
            handleTimePeriod(5);
            setTimeout(() => {
              setCreateStakingPopUpActive(false);
            }, 3000);
          },
          () => {
            setStakingLoading(false);
            toast.error("Staking failed, please try again.", {
              autoClose: false,
            });
          }
        );
      } else {
        setStakingLoading(false);
        toast.error("You can't stake this amount.", {
          autoClose: false,
        });
      }
    }

    setStakingLoading(true);
    if (account && isAllowance) {
      approve(
        () => {
          setStakingLoading(false);
          toast.success("Approved successfully, please stake desired amount.", {
            autoClose: 8000,
          });
          if (stakeAfterApprove) {
            setStakingLoading(true);
            handleStake();
          }
        },
        () => {
          setBalanceStakeLoading(false);
          setStakingLoading(false);
          toast.error("Approval failed, please try again.", {
            autoClose: false,
          });
        },
        depositAmount / (appState?.rates?.atr?.usd ?? 2)
      );
    }
    if (account && !isAllowance) {
      handleStake();
    }
  };

  const tableEmptyData = {
    label: "Stake to earn AONE reward",
    button: (
      <Button
        element={"referral-button"}
        label={"Create Staking"}
        icon={<AddSquareIcon />}
        onClick={handlePopUpOpen}
        customStyles={{ height: "44px", flexDirection: "row" }}
      />
    ),
  };

  const handleClose = () => {
    handleTimePeriod(5);
    handleDepositAmount("");
    setCreateStakingPopUpActive(false);
  };

  useEffect(() => {
    if (isLoadMoreButtonOnScreen) {
      setIsFetching(true);
      setFetchCount((prevCount) => prevCount + 1);
    }
  }, [isLoadMoreButtonOnScreen]);

  useEffect(() => {
    async function getCurrencyStakes() {
      setCurrencyStakesLoading(true);
      axios
        .post("/api/transactions/get_currency_stakes", {})
        .then((res) => {
          setCurrencyStakes(res?.data);
          setCurrencyStakesLoading(false);
        })
        .catch((e) => {
          setCurrencyStakesLoading(false);
        });
    }
    getCurrencyStakes();
  }, []);

  async function handleWalletSubmit() {
    setBalanceStakeLoading(true);
    const makeWithdrawal = () => {
      axios
        .post("/api/transactions/make_withdrawal", {
          address_to: account,
          amount: +depositAmount / appState?.rates?.["atr"]?.usd,
          accountType: "ATAR",
          rate: appState?.rates?.["atr"]?.usd,
          fee: 0
        })
        .then((res) => {
          //let hash = res?.data?.hash;

          if (res?.data.success) {
            toast.success(
              "AONE tokens successfully withdrawn. Now you can continue staking.",
              {
                autoClose: 8000,
              }
            );
            handleCalculatorSubmit(true);
          }
        })
        .catch((e) => {
          toast.error(
            e?.response?.data?.message ??
              "Withdrawal failed, please try again.",
            {
              autoClose: false,
            }
          );
          setBalanceStakeLoading(false);
        });
    };

    try {
      // wc
      if (
        depositAmount >=
        accountsData.find((acc) => acc.account_category === "main").balance
      ) {
        setBalanceStakeLoading(false);
        return toast.error("Insufficient funds", { autoClose: false });
      }
      
      makeWithdrawal();
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (
      tiers &&
      depositAmount &&
      (library || providerType === "walletConnect")
    ) {
      let prevStakedAmount = 0;

      for (let i = 0; i < stakersRecord.length; i++) {
        let stakedAmount = Number(stakersRecord[i].amount);
        prevStakedAmount = prevStakedAmount + stakedAmount;
      }

      function findTierId(amount, tiers) {
        for (const tier of tiers) {
          let minStakeAmt = Web3.utils.fromWei(tier?.minStakeAmt, "ether");
          let maxStakeAmt = Web3.utils.fromWei(tier?.maxStakeAmt, "ether");

          if (
            amount / Number(appState?.rates?.["atr"]?.usd) >= minStakeAmt &&
            amount / Number(appState?.rates?.["atr"]?.usd) <= maxStakeAmt
          ) {
            return tier?.tierId;
          }
        }

        return "0";
      }

      let result = findTierId(parseInt(depositAmount), tiers);
      //let expectedTierId = findExpectedTierId(parseInt(depositAmount), prevStakedAmount, tiers);
      let apy = tiers?.filter((item) => item.tierId === result);
      let dividor = 1;
      if (timeperiod === 5) {
        dividor = 2;
      } else {
        dividor = 3;
      }

      setApyPercent(
        parseFloat(apy[0]?.bonuses[timeperiod].bonus / 10) / dividor
      );
    }
  }, [depositAmount, tiers, timeperiod, providerType, stakersRecord]);

  const ranges = useSelector((state) => state.appState?.ranges);
  const currentTier = useSelector(
    (state) => state.appState?.userData?.tier?.value
  );

  function getRangeName(depositAmount, ranges) {
    const splitCamelCase = (word) =>
      word.replace(/([a-z])([A-Z])/g, "$1 $2").toUpperCase();

    for (const [range, { min, max }] of Object.entries(ranges)) {
      const rangeName = splitCamelCase(range);
      const depositInUSD =
        +depositAmount + +stakedTotal * appState?.rates?.atr?.usd;
      const currentTierUpperCase = currentTier?.toUpperCase();

      if (
        depositInUSD >= +min &&
        depositInUSD <= +max &&
        currentTierUpperCase !== rangeName
      ) {
        return rangeName;
      }
    }

    return "";
  }

  const rangeName = getRangeName(depositAmount, ranges);

  return (
    <>
      <StakingUI
        account={account}
        walletBalance={walletBalance}
        stackContractInfo={stackContractInfo}
        loading={loading || balanceStakeLoading}
        accountSummaryData={accountSummaryData(stakersInfo, balance)}
        tableHead={th}
        currencyStakesTableHead={currencyStakesTableHead}
        stakersRecord={stakersRecord}
        tableEmptyData={tableEmptyData}
        handlePopUpOpen={handlePopUpOpen}
        hasMoreData={hasMoreData}
        infiniteScrollRef={infiniteScrollRef}
        isFetching={isFetching}
        unstakeLoading={unstakeLoading}
        harvestLoading={harvestLoading}
        isActive={isActive}
        currencyStakes={currencyStakes}
        currencyStakesLoading={currencyStakesLoading}
        balanceStakeLoading={balanceStakeLoading}
        translates={translates}
        helpSupportClick={helpSupportClick}
      />
      {createStakingPopUpActive && (
        <Popup
          popUpElement={
            <Calculator
              {...{
                durationOptions,
                handleCalculatorSubmit,
                handleMaxClick,
                loading,
                isAllowance,
                walletBalance,
                tokenBalance,
                exchangeRate,
                account,
                timeperiod,
                handleTimePeriod,
                depositAmount,
                handleDepositAmount,
                timeperiodDate,
                handleTimeperiodDate,
                stakingLoading,
                translates: translates,
                handleWalletSubmit,
                hasRerferralActive:
                  !appState?.userData?.tier?.value ||
                  appState?.userData?.tier?.value === "Novice Navigator"
                    ? false
                    : true,
                rates: appState?.rates,
                apyPercent,
              }}
              approveResonse={approveResonse}
              isActive={isActive}
              rangeName={rangeName}
              currentTier={currentTier}
            />
          }
          label={"Staking Calculator"}
          handlePopUpClose={handleClose}
          description={"Stake AONE to earn AONE reward"}
        />
      )}
    </>
  );
};

export default Staking;
