import {
  ClaimedReward,
  CurrentStake,
  Earn,
  TotalStaked,
  TotalUnstaked,
  WalletBalance,
} from "../assets/svg";

export const accountSummaryData = (stakersInfo, balance) => [
  [
    {
      icon: <CurrentStake />,
      title: "Current Stake",
      value: stakersInfo.currentStaked,
    },
    {
      icon: <Earn />,
      title: "Earn",
      value: stakersInfo.realtimeReward,
    },
    {
      icon: <ClaimedReward />,
      title: "Claimed Reward",
      value: stakersInfo.totalClaimedRewardTokenUser,
    },
  ],
  [
    {
      icon: <WalletBalance />,
      title: "Your Wallet Balance",
      value: balance,
    },
    {
      icon: <TotalStaked />,
      title: "Total Staked",
      value: stakersInfo.totalStakedTokenUser,
    },
    {
      icon: <TotalUnstaked />,
      title: "Total Unstaked",
      value: stakersInfo.totalUnstakedTokenUser,
    },
  ],
];

