import React, { useState, useEffect } from "react";
import { Visual } from "../../UI/visual/Visual";
import Account from "../../../assets/svg/Account";
import { Button } from "../../UI/button/Button";
import { Input } from "../../UI/input/Input";
import { HelpText } from "../../UI/helpText/HelpText";
import MetaMask from "../../../assets/svg/MetaMask2";
import translates from "../../../translatesNew.json";

import "./TransferFromAcc.css";

let defaultData = [
  {
    networkName: "BSC Smart Chain",
    tokenStandard: "BEP-20",
  },
  {
    networkName: "Ethereum",
    tokenStandard: "ERC-20",
  },
  {
    networkName: "TRON",
    tokenStandard: "TRC-20",
  },
  {
    networkName: "Polygon",
    tokenStandard: "Polygon",
  },
];

export const TransferFromAcc = ({
  setNetwork,
  network,
  accountType,
  sideBarClose,
  inputs,
  currentObject,
  cardImg,
  handleSubmit,
  buttonLabel,
  accountBalance,
  accountBalanceSecond,
  label,
  transferSubmitLoading,
  withdrawSubmitLoading,
  helpTitle,
  chosenAccount,
  account,
  type,
}) => {
  const handleInputChange = (e, params) => {
    const { name, onChange } = params;

    let data;
    if (!e.target) {
      data = {
        target: {
          value: e,
          name,
        },
      };
      return onChange(data);
    }

    onChange(e);
  };

  useEffect(() => {
    if (accountType === "bnb" || accountType === "ATAR") {
      setNetwork({
        networkName: "BSC Smart Chain",
        tokenStandard: "BEP-20",
      });
    }
    if (accountType === "btc") {
      setNetwork({
        networkName: "Bitcoin",
        tokenStandard: "native",
      });
    }
    if (accountType === "eth") {
      setNetwork({
        networkName: "Ethereum",
        tokenStandard: "ERC-20",
      });
    }
    if (accountType === "trx") {
      setNetwork({
        networkName: "TRON",
        tokenStandard: "TRC-20",
      });
    }
  }, [accountType]);

  return (
    <>
      <Visual
        label={label}
        element={"popup-header"}
        customStyles={{ width: "100%", maxWidth: "100%" }}
        onClick={sideBarClose}
      />
      <div className="sidebar-body">
        <div className="withdraw-to-acc-container">
          <div className="withdraw-to-acc-card">
            <img src={cardImg} className="withdraw-to-acc-card-img" />
            <div className="withdraw-to-acc-card_header">
              <Account type={accountType?.toLowerCase()} />
              <h4 className="font-16">
                {accountType === "ATAR" ? chosenAccount?.toUpperCase() : ""}{" "}
                account
              </h4>
            </div>
            <div className="withdraw-to-acc-card_content">
              <h4 className="font-14">
                {accountType === "ATAR" ? "AONE" : chosenAccount?.toUpperCase()}{" "}
                Balance
              </h4>
              <p>
                <span className="font-14">Available: </span>
                {accountBalance}
              </p>
              <span className="font-14">Total: {accountBalanceSecond}</span>
            </div>
          </div>

          <p style={{ color: "#FFA726", fontSize: "14px" }}>
            {`Network: ${network.networkName}`}
          </p>
          {accountType === "usdt" && (
            <>
              <div className="withdraw-to-acc-input-wrapper">
                <Input
                  type={"lable-input-select"}
                  emptyFieldErr={false}
                  defaultData={defaultData}
                  selectType={"network"}
                  label={"Select Network"}
                  selectLabel={network.networkName}
                  selectHandler={(e) => setNetwork(e)}
                  customStyles={{ width: "100%" }}
                  active={true}
                  title={"select network"}
                  customInputStyles={{
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                  }}
                  disabled={false}
                />
              </div>
            </>
          )}
          <div className="withdraw-to-acc-inputs">
            {inputs?.map((params, index) => {
              let selectedOption;
              if (params.type === "lable-input-select") {
                selectedOption = params?.options.find(
                  (option) => option.value === currentObject[params?.name]
                );
              }
              return (
                <div
                  key={`transfer_item_${index}`}
                  className="withdraw-to-acc-input-main-wrapper"
                >
                  {type === "Withdraw" && (
                    <div>
                      <div className="address-wrap">
                        <p>{translates.your_address.en}</p>
                        <p>
                          <MetaMask width="24" />
                          <span>Metamask</span>
                        </p>
                      </div>
                      <p className="account_address">{account}</p>
                    </div>
                  )}
                  <div className="withdraw-to-acc-input-wrapper" key={index}>
                    <Input
                      key={index}
                      type={params?.type}
                      inputType={params?.inputType}
                      label={params.title}
                      name={params.name}
                      value={
                        params?.type === "lable-input-select"
                          ? selectedOption?.name ||
                            params?.defaultAny ||
                            params?.options[0]?.value
                          : currentObject[params?.name] === undefined
                          ? params?.defaultAny
                          : currentObject[params?.name]
                      }
                      customStyles={{ width: "100%" }}
                      selectHandler={(opt) => {
                        handleInputChange(opt, params);
                      }}
                      placeholder={params?.placeholder}
                      disabled={params?.disabled}
                      onChange={(e) => handleInputChange(e, params)}
                      defaultData={params?.options}
                      customInputStyles={{
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                      }}
                      svg={
                        params?.type === "lable-input-select"
                          ? selectedOption?.svg
                          : params?.svg
                      }
                    />
                    {params?.rightText && (
                      <span className="font-14 withdraw-to-acc-input-right">
                        {params?.rightText}
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {helpTitle && (
            <HelpText
              status={"warning"}
              title={helpTitle}
              color={"#6A6D76"}
              icon={true}
              customStyles={{ marginBottom: "5px" }}
            />
          )}
          <Button
            label={buttonLabel}
            size={"btn-lg"}
            type={"btn-primary"}
            element={"button"}
            customStyles={{
              margin: "0",
              width: "100%",
              backgroundColor: "#C38C5C",
            }}
            onClick={handleSubmit}
            disabled={transferSubmitLoading || withdrawSubmitLoading}
          />
        </div>
      </div>
    </>
  );
};
