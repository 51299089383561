import React from "react";
import { Button } from "../../UI/button/Button";
import ScreenSvg from "../../../assets/svg/ScreenSvg";

import styles from "./EmptyContent.module.css";

const EmptyContent = ({ description }) => {
  return (
    <div className={styles.emptyContent}>
      <ScreenSvg />
      <p className={styles.emptyContentDescription}>{description}</p>
      <Button
        element={"button"}
        size={"btn-lg"}
        type={"btn-primary"}
        label={"Back to Trade"}
        active={true}
        onClick={() => {
          console.log("Auto Place");
        }}
      />
    </div>
  );
};

export default EmptyContent;
