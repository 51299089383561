const Earn = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 8.4999C22.0001 9.39738 21.8144 10.2852 21.4545 11.1073C21.0946 11.9295 20.5683 12.6682 19.9088 13.277C19.2493 13.8857 18.4709 14.3513 17.6227 14.6444C16.7744 14.9375 15.8746 15.0517 14.98 14.9799C14.8548 13.441 14.1867 11.9967 13.095 10.9049C12.0032 9.81316 10.5589 9.14505 9.01999 9.0199C8.95145 8.14798 9.05957 7.2712 9.33789 6.44207C9.61621 5.61293 10.059 4.84848 10.6397 4.19451C11.2205 3.54054 11.9272 3.01049 12.7176 2.63611C13.5081 2.26173 14.3659 2.05072 15.2398 2.01571C16.1137 1.9807 16.9857 2.12241 17.8035 2.43236C18.6214 2.74231 19.3683 3.21412 19.9994 3.81954C20.6306 4.42496 21.1331 5.15154 21.4769 5.95576C21.8206 6.75997 21.9986 7.6253 22 8.4999V8.4999Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 15.5C15 17.2239 14.3152 18.8772 13.0962 20.0962C11.8772 21.3152 10.2239 22 8.5 22C6.77609 22 5.12279 21.3152 3.90381 20.0962C2.68482 18.8772 2 17.2239 2 15.5C2 13.7761 2.68482 12.1228 3.90381 10.9038C5.12279 9.68482 6.77609 9 8.5 9C10.2239 9 11.8772 9.68482 13.0962 10.9038C14.3152 12.1228 15 13.7761 15 15.5V15.5Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.62 14.62L8.5 13L9.38 14.62L11 15.5L9.38 16.38L8.5 18L7.62 16.38L6 15.5L7.62 14.62Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Earn;
