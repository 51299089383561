import React from "react";
import { useState } from "react";
import { Input } from "../../../../../UI/input/Input";
import { Tabs } from "../../../../../UI/tabs/Tabs";
import { Switches } from "../../../../../UI/switches/Switches";
import { Button } from "../../../../../UI/button/Button";
import styles from "../TradeSideBar.module.css";

function OrderEntry() {
  const [amountProgressValue, setAmountProgressValue] = useState(1000);
  const [switch1Active, setSwitch1Active] = useState(false);
  const [switch2Active, setSwitch2Active] = useState(false);
  const [switch3Active, setSwitch3Active] = useState(false);

  const [moreOptions, setMoreOptions] = useState(false);

  const showMoreOptions = () => {
    setMoreOptions(!moreOptions);
  };

  const amountProgressOnchange = () => {
    console.log("close");
  };

  const switchHandler = (index) => {
    if (index === 0) {
      setSwitch1Active(!switch1Active);
    }
    if (index === 1) {
      setSwitch2Active(!switch2Active);
    }
    if (index === 2) {
      setSwitch3Active(!switch3Active);
    }
  };

  return (
    <div className={styles.buySellBtn}>
      <Tabs type={"two-component-tabs"} />
      <div className={styles.tradeOptions}>
        <div className={styles.flex}>
          <Input
            type={"plus_minus"}
            customStyles={{
              width: "100%",
            }}
            min={5000}
            max={500000}
            step={5000}
            decrimnet={() => console.log("-")}
            incrimnet={() => console.log("+")}
            label={"Amount lot"}
            value={amountProgressValue}
            onChange={amountProgressOnchange}
          />
          <Input
            type={"plus_minus"}
            customStyles={{
              width: "100%",
            }}
            min={5000}
            max={500000}
            step={5000}
            decrimnet={() => console.log("-")}
            incrimnet={() => console.log("+")}
            label={"Amount lot"}
            value={amountProgressValue}
            onChange={amountProgressOnchange}
          />
        </div>
        <div className={styles.flex}>
          <div className={styles.side}>
            <p className="font-12">Margin Impact $:</p>
            <span>40.900</span>
            <p className="font-12">Value $:</p>
            <span>40.244</span>
          </div>
          <div className={styles.side}>
            <p className="font-12">Spread:</p>
            <span>0.903</span>
            <p className="font-12">Leverage:</p>
            <span>4.3430</span>
          </div>
        </div>
      </div>
      <div className={styles.moreOptions}>
        <div className={styles.morOprionsBtn} onClick={showMoreOptions}>
          <div
            style={{ transform: moreOptions ? "rotate(90deg)" : "" }}
            className={styles.triangle}
          ></div>
          <p
            className={styles.triangleTitle}
            style={{ color: moreOptions ? "#c38c5c" : "" }}
          >
            More Options
          </p>
        </div>
        <div
          className={`${styles.switches} ${
            moreOptions ? styles.showOptions : ""
          }`}
        >
          <div>
            <div className={styles.flex}>
              <Switches
                type={"sm-switches"}
                onChange={() => switchHandler(0)}
                value={switch1Active}
              />
              <p>Execute When Price Hits</p>
            </div>

            <div
              className={`${styles.flex} ${styles.transform} ${
                switch1Active ? styles.transformed : ""
              }`}
            >
              <Input
                type={"plus_minus"}
                customStyles={{
                  width: "100%",
                }}
                min={5000}
                max={500000}
                step={5000}
                decrimnet={() => console.log("-")}
                incrimnet={() => console.log("+")}
                label={"Amount lot"}
                value={amountProgressValue}
                onChange={amountProgressOnchange}
              />
              <Input
                type={"plus_minus"}
                customStyles={{
                  width: "100%",
                }}
                min={5000}
                max={500000}
                step={5000}
                decrimnet={() => console.log("-")}
                incrimnet={() => console.log("+")}
                label={"Amount lot"}
                value={amountProgressValue}
                onChange={amountProgressOnchange}
              />
            </div>
          </div>
          <div>
            <div className={styles.flex}>
              <Switches
                type={"sm-switches"}
                onChange={() => switchHandler(1)}
                value={switch2Active}
              />
              <p>Take Profit</p>
            </div>

            <div
              className={`${styles.flex} ${styles.transform} ${
                switch2Active ? styles.transformed : ""
              }`}
            >
              <Input
                type={"plus_minus"}
                customStyles={{
                  width: "100%",
                }}
                min={5000}
                max={500000}
                step={5000}
                decrimnet={() => console.log("-")}
                incrimnet={() => console.log("+")}
                label={"Amount lot"}
                value={amountProgressValue}
                onChange={amountProgressOnchange}
              />
              <Input
                type={"plus_minus"}
                customStyles={{
                  width: "100%",
                }}
                min={5000}
                max={500000}
                step={5000}
                decrimnet={() => console.log("-")}
                incrimnet={() => console.log("+")}
                label={"Amount lot"}
                value={amountProgressValue}
                onChange={amountProgressOnchange}
              />
            </div>
          </div>
          <div>
            <div className={styles.flex}>
              <Switches
                type={"sm-switches"}
                onChange={() => switchHandler(2)}
                value={switch3Active}
              />
              <p>Stop Loss</p>
            </div>

            <div
              className={`${styles.flex} ${styles.transform} ${
                switch3Active ? styles.transformed : ""
              }`}
            >
              <Input
                type={"plus_minus"}
                customStyles={{
                  width: "100%",
                }}
                min={5000}
                max={500000}
                step={5000}
                decrimnet={() => console.log("-")}
                incrimnet={() => console.log("+")}
                label={"Amount lot"}
                value={amountProgressValue}
                onChange={amountProgressOnchange}
              />
              <Input
                type={"plus_minus"}
                customStyles={{
                  width: "100%",
                }}
                min={5000}
                max={500000}
                step={5000}
                decrimnet={() => console.log("-")}
                incrimnet={() => console.log("+")}
                label={"Amount lot"}
                value={amountProgressValue}
                onChange={amountProgressOnchange}
              />
            </div>
          </div>
        </div>
      </div>
      <Button
        label={"Buy ATR"}
        size={"btn-lg"}
        type={"btn-primary"}
        arrow={"arrow-none"}
        element={"button"}
        customStyles={{ width: "100%", marginTop: "30px" }}
        onChange={console.log("hi")}
      />
    </div>
  );
}

export default OrderEntry;
