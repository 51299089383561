import React from "react";

const News = () => {
  return (
    <svg 
      width="38" 
      height="38" 
      viewBox="0 0 38 38" 
      fill="currentColor" 
      xmlns="http://www.w3.org/2000/svg" 
      data-qa="news-icon"
      style={{ width:"100%", height:"100%" }}
    >
      <path d="M30.375 7H12.875C11.3984 7 10.25 8.20312 10.25 9.625V28C10.25 28.9844 9.42969 29.75 8.5 29.75C7.51562 29.75 6.75 28.9844 6.75 28V11.375C6.75 10.9375 6.3125 10.5 5.875 10.5C5.38281 10.5 5 10.9375 5 11.375V28C5 29.9688 6.53125 31.5 8.5 31.5H28.625C31.0312 31.5 33 29.5859 33 27.125V9.625C33 8.20312 31.7969 7 30.375 7ZM31.25 27.125C31.25 28.6016 30.0469 29.75 28.625 29.75H11.5078C11.7812 29.2578 12 28.6562 12 28V9.625C12 9.1875 12.3828 8.75 12.875 8.75H30.375C30.8125 8.75 31.25 9.1875 31.25 9.625V27.125ZM19.875 21.875H14.625C14.1328 21.875 13.75 22.3125 13.75 22.75C13.75 23.2422 14.1328 23.625 14.625 23.625H19.875C20.3125 23.625 20.75 23.2422 20.75 22.75C20.75 22.3125 20.3125 21.875 19.875 21.875ZM28.625 21.875H23.375C22.8828 21.875 22.5 22.3125 22.5 22.75C22.5 23.2422 22.8828 23.625 23.375 23.625H28.625C29.0625 23.625 29.5 23.2422 29.5 22.75C29.5 22.3125 29.0625 21.875 28.625 21.875ZM19.875 25.375H14.625C14.1328 25.375 13.75 25.8125 13.75 26.25C13.75 26.7422 14.1328 27.125 14.625 27.125H19.875C20.3125 27.125 20.75 26.7422 20.75 26.25C20.75 25.8125 20.3125 25.375 19.875 25.375ZM28.625 25.375H23.375C22.8828 25.375 22.5 25.8125 22.5 26.25C22.5 26.7422 22.8828 27.125 23.375 27.125H28.625C29.0625 27.125 29.5 26.7422 29.5 26.25C29.5 25.8125 29.0625 25.375 28.625 25.375ZM27.75 10.5H15.5C14.5156 10.5 13.75 11.3203 13.75 12.25V17.5C13.75 18.4844 14.5156 19.25 15.5 19.25H27.75C28.6797 19.25 29.5 18.4844 29.5 17.5V12.25C29.5 11.3203 28.6797 10.5 27.75 10.5ZM27.75 17.5H15.5V12.25H27.75V17.5Z" fill="currentColor"></path>
    </svg>
  );
};

export default News;
