import AccountsSvg from "../assets/svg/AccountsSvg";
import TransactionsSvg from "../assets/svg/TransactionsSvg";
import TopupSvg from "../assets/svg/TopupSvg";
import TradeSvg from "../assets/svg/Trade";
import PositionsSvg from "../assets/svg/Positions";
import OrdersSvg from "../assets/svg/Orders";
import HistorySvg from "../assets/svg/History";
import SignalsSvg from "../assets/svg/Signals";
import NewsSvg from "../assets/svg/News";

export const links = [
  {
    to: "/dashboard",
    label: "Accounts",
    svg: <AccountsSvg />,
  },
  {
    to: "/transactions",
    label: "Transaction History",
    svg: <TransactionsSvg />,
  },
  // {
  //   to: "/top-up",
  //   label: "Top Up",
  //   svg: <TopupSvg />,
  // },
];

export const tradeLinks = [
  {
    to: "/trade",
    label: "Trade",
    svg: (
      <span style={{ width: "28px", height: "28px" }}>
        <TradeSvg />
      </span>
    ),
  },
  {
    to: "/positions",
    label: "Positions",
    svg: (
      <span style={{ width: "28px", height: "28px" }}>
        <PositionsSvg />
      </span>
    ),
  },
  {
    to: "/orders",
    label: "Orders",
    svg: (
      <span style={{ width: "28px", height: "28px" }}>
        <OrdersSvg />
      </span>
    ),
  },
  {
    to: "/history",
    label: "History",
    svg: (
      <span style={{ width: "28px", height: "28px" }}>
        <HistorySvg />
      </span>
    ),
  },
  {
    to: "/signals",
    label: "Signals",
    svg: (
      <span style={{ width: "26px", height: "26px" }}>
        <SignalsSvg />
      </span>
    ),
  },
  {
    to: "/news",
    label: "News",
    svg: (
      <span style={{ width: "26px", height: "26px" }}>
        <NewsSvg />
      </span>
    ),
  },
];

export const tradePriceData = {
  balance: {
    value: 10000,
    currency: "AONE",
  },
  freeMargin: {
    value: 5000,
    currency: "AONE",
  },
  usedMargin: {
    value: 471,
    currency: "AONE",
  },
  profit: {
    value: 2300,
    currency: "AONE",
  },
  equity: {
    value: 2300,
    currency: "AONE",
  },
  marginLevel: {
    value: 100,
    dir: false,
    measure: "%",
  },
};
