import React from "react";

const GoldLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
    >
      <rect
        x="0.5"
        y="1"
        width="31"
        height="31"
        rx="15.5"
        fill="url(#paint0_linear_40_8939)"
        stroke="url(#paint1_linear_40_8939)"
      />
      <path
        d="M9.87559 20.0447C9.29186 18.9714 9 17.782 9 16.4765C9 15.1709 9.30442 13.9846 9.91325 12.9176C10.5284 11.8506 11.3726 11.0158 12.4459 10.4132C13.5254 9.80442 14.7274 9.5 16.0518 9.5C17.2883 9.5 18.4149 9.76048 19.4317 10.2814C20.4486 10.7961 21.3022 11.5054 21.9926 12.4092L19.9684 13.8968C19.529 13.2754 18.961 12.7827 18.2643 12.4186C17.5676 12.0546 16.8175 11.8726 16.0141 11.8726C15.4241 11.8726 14.8624 11.9824 14.3288 12.2021C13.8016 12.4155 13.3371 12.7168 12.9354 13.1059C12.54 13.4951 12.223 13.9815 11.9845 14.5652C11.7523 15.149 11.6362 15.786 11.6362 16.4765C11.6362 17.1606 11.7523 17.7977 11.9845 18.3877C12.223 18.9714 12.5432 19.4641 12.9449 19.8658C13.3466 20.2675 13.8204 20.5814 14.3665 20.8073C14.9126 21.0333 15.4932 21.1463 16.1083 21.1463C16.6794 21.1463 17.2035 21.0584 17.6806 20.8826C18.1576 20.7006 18.553 20.4621 18.8668 20.1671C19.1807 19.8658 19.4317 19.5332 19.62 19.1691C19.8146 18.7988 19.9433 18.4097 20.0061 18.0017H15.5151V15.7609H22.5104V23.2458H20.0061V21.7206C19.4725 22.3106 18.848 22.7562 18.1325 23.0575C17.4169 23.3525 16.6669 23.5 15.8823 23.5C14.5705 23.5 13.3874 23.1924 12.3329 22.5773C11.2784 21.956 10.4593 21.1117 9.87559 20.0447Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_40_8939"
          x1="0.36923"
          y1="2.12319"
          x2="33.9012"
          y2="4.39119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="1" stopColor="white" stopOpacity="0.02" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_40_8939"
          x1="6.95385"
          y1="0.499999"
          x2="24.2795"
          y2="14.2939"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopColor="white" stopOpacity="0.05" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GoldLogo;
