import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../../../../api/axios";
import { Input } from "../../../../UI/input/Input";
import { Button } from "../../../../UI/button/Button";
import { toast } from "react-toastify";

const HelpSupport = ({ setHelpSupport, helpSupport }) => {
  const defaultData = [
    {
      name: "Account Assistance",
      value: "Account Assistance",
    },
    {
      name: "Transaction Support",
      value: "Transaction Support",
    },
    {
      name: "Security Concerns",
      value: "Security Concerns",
    },
    {
      name: "Technical Issues",
      value: "Technical Issue",
    },
    {
      name: "Trade and Exchange",
      value: "Trade and Exchange",
    },
    {
      name: "Feedback and Suggestions",
      value: "Feedback and Suggestions",
    },
  ];

  const selectData = [
    {
      id: 1,
      title: "option 1",
      list: [],
    },
    {
      id: 2,
      title: "option 2",
      list: [],
    },
    {
      id: 3,
      title: "option 3",
      list: [],
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState("General Inquiry");
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(false);

  const userName = useSelector((state) => state.appState?.userData?.meta?.name);
  const userEmail = useSelector(
    (state) => state.appState?.userData?.meta?.email
  );
  const userAddress = useSelector(
    (state) => state.appState?.userData?.meta?.address
  );

  const selectHandler = (value) => {
    setSelectedCategory(value);
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSubmit = () => {
    if (!inputText.trim()) {
      toast.error("Please enter your text");
      return;
    }

    setLoading(true);
    axios
      .post("/api/accounts/send-email-to-support", {
        selectedCategory: selectedCategory,
        inputText: inputText,
        userName: userName,
        userEmail: userEmail,
        userAddress: userAddress,
      })
      .then((response) => {
        toast.success(response?.data);
        setHelpSupport(false);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.data);
        setHelpSupport(false);
        setLoading(false);
      });
  };

  return (
    <div className="confirm-list">
      <Input
        type="lable-input-select"
        // selectData={selectData}
        emptyFieldErr={false}
        defaultData={defaultData}
        label="Select Category"
        selectHandler={selectHandler}
        selectLabel="General Inquiry"
        active={true}
        status="warning"
        title="your text"
        color="#FFA726"
        disabled={false}
      />
      <Input
        type="textarea"
        label="Enter your text"
        name="textarea input"
        placeholder="Type text"
        customStyles={{
          resize: "none",
          border: "1px solid hsla(0,0%,100%,.2)",
        }}
        onChange={handleInputChange}
        rows={10}
        columns={20}
      />
      <Button
        element="button"
        size="btn-lg"
        type="btn-primary"
        label="Submit"
        customStyles={{ width: "100%" }}
        onClick={handleSubmit}
        disabled={loading}
      />
    </div>
  );
};

export default HelpSupport;
