import { useDispatch } from "react-redux";

const useHandleSidebar = () => {
  const dispatch = useDispatch();

  const handleSidebarOpen = (sideBar, accountType) => {
    if (
      ["exchange", "withdraw", "stake", "transfer"].includes(sideBar) &&
      accountType
    ) {
      dispatch({
        type: "SET_EXCHANGE_ACCOUNT_TYPE",
        payload: accountType,
      });
    }

    dispatch({
      type: "SET_SIDE_BAR",
      payload: { sideBarOpen: true, sideBar },
    });
  };

  return handleSidebarOpen;
};

export default useHandleSidebar;
