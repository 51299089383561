import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Web3 from "web3";
import { Web3ReactProvider } from "@web3-react/core";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { config } from "./utils/wagmiConfig";
import { decryptEnv } from "./utils/decryptEnv";
import store, { persistor } from "./store/index";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import ErrorBoundary from "./utils/ErrorBoundary";

import "./index.css";

const queryClient = new QueryClient();

function getLibrary(provider) {
  return new Web3(provider);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ErrorBoundary>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </ErrorBoundary>
            </PersistGate>
          </Provider>
        </Web3ReactProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
