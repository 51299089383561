import EmptyContent from "../emptyContent/EmptyContent";
import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../../UI/table/Table";
import { Button } from "../../UI/button/Button";

import { useMobileWidth } from "../../../hooks/useMobileWidth";
import translates from "../../../translates.json";
import ArrowDownSvg from "../../../assets/svg/ArrowDownSvg";

import styles from "./Positions.module.css";

const Positions = () => {
  let header;

  const [mobileExpand, setMobileExpand] = useState(null);
  const [transactionHeader, setTransactionHeader] = useState(header);

  const positionsData = useSelector((state) => state.trade.positionsData);

  const { width } = useMobileWidth();
  let mobile = width <= 1200;
  const dispatch = useDispatch();

  let mobileExpandFunc = (id) => {
    if (width <= 1200) {
      if (id !== mobileExpand) {
        setMobileExpand(id);
      } else {
        setMobileExpand(null);
      }
    }
  };

  useEffect(() => {
    if (mobile) {
      let header = [
        {
          name: "Symbol",
          mobileWidth: 25,
          width: 10,
          id: 0,
          height: "40px",
        },
        {
          name: "Side",
          mobileWidth: 25,
          width: 10,
          id: 1,
          height: "40px",
        },
        {
          name: "Size",
          mobileWidth: 25,
          width: 10,
          id: 2,
          height: "40px",
        },
        {
          name: "value $",
          mobileWidth: 25,
          width: 10,
          id: 3,
          height: "40px",
        },
      ];

      setTransactionHeader(header);
    } else {
      let header = [
        {
          name: "Symbol",
          mobileWidth: 25,
          width: 10,
          id: 0,
          height: "40px",
        },
        {
          name: "Side",
          mobileWidth: 25,
          width: 10,
          id: 1,
          height: "40px",
        },
        {
          name: "Size",
          mobileWidth: 25,
          width: 10,
          id: 2,
          height: "40px",
        },
        {
          name: "value $",
          mobileWidth: 25,
          width: 10,
          id: 3,
          height: "40px",
        },
        {
          name: "Profit/loss $",
          mobileWidth: 25,
          width: 10,
          id: 4,
          height: "40px",
        },
        {
          name: "Open Price",
          mobileWidth: 25,
          width: 10,
          id: 5,
          height: "40px",
        },
        {
          name: "Stop Loss",
          mobileWidth: 25,
          width: 10,
          id: 6,
          height: "40px",
        },
        {
          name: "Take Profit",
          mobileWidth: 25,
          width: 10,
          id: 7,
          height: "40px",
        },
        {
          name: "Swap $",
          mobileWidth: 25,
          width: 10,
          id: 8,
          height: "40px",
        },
      ];

      setTransactionHeader(header);
    }
  }, [mobile]);

  let tableData = positionsData?.map((item, index) => {
    let symbol = item?.symbol.toUpperCase();
    let side = item?.side;
    let size = item?.size;
    let value = item?.value;
    let profitLoss = item?.profitLoss;
    let openPrice = item?.openPrice;
    let takeProfit = item?.takeProfit;
    let stopLoss = item?.stopLoss;
    let swap = item?.swap;
    let id = item?._id;

    return (
      <div
        className={`table-parent ${
          mobileExpand == id ? "active" : ""
        } dashboard-table-parent`}
        key={index}
      >
        <div className="table">
          <div
            className={`td col dashboard-td`}
            style={{
              width: mobile ? "25%" : "10%",
            }}
          >
            <span>{symbol}</span>
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              display: mobile ? "none" : "flex",
              width: mobile ? "25%" : "10%",
            }}
          >
            <span>{side}</span>
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              color: size
                ? "rgba(103, 190, 122, 0.6)"
                : "rgba(232, 116, 80, 0.6)",
              width: mobile ? "25%" : "10%",
            }}
          >
            {size}
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              color: value
                ? "rgba(103, 190, 122, 0.6)"
                : "rgba(232, 116, 80, 0.6)",
              width: mobile ? "25%" : "10%",
            }}
          >
            {value}
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              display: mobile ? "none" : "flex",
              width: mobile ? "25%" : "10%",
            }}
          >
            <span>{profitLoss}</span>
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              color: openPrice
                ? "rgba(103, 190, 122, 0.6)"
                : "rgba(232, 116, 80, 0.6)",
              width: mobile ? "25%" : "10%",
            }}
          >
            {openPrice}
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              display: mobile ? "none" : "flex",
              width: mobile ? "25%" : "10%",
            }}
          >
            <Button
              element={"button"}
              size={"btn-lg"}
              type={"btn-secondary"}
              label={"Add +"}
              active={takeProfit}
              customStyles={{
                height: "30px",
              }}
              onClick={() => {
                console.log("take profit");
              }}
            />
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              display: mobile ? "none" : "flex",
              width: mobile ? "25%" : "10%",
            }}
          >
            <Button
              element={"button"}
              size={"btn-lg"}
              type={"btn-secondary"}
              label={"Add +"}
              active={stopLoss}
              customStyles={{
                height: "30px",
              }}
              onClick={() => {
                console.log("stop loss");
              }}
            />
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              display: mobile ? "none" : "flex",
              width: mobile ? "25%" : "10%",
            }}
          >
            <span>{swap}</span>
          </div>
        </div>
        <div
          className="icon-place"
          onClick={mobile ? () => mobileExpandFunc(id) : null}
        >
          <ArrowDownSvg />
        </div>
        <div className="table-mobile">
          <div className="table-mobile-content">
            <div className={`td dashboard-td`}>
              <span>{size}</span>
            </div>
            <div className={`td dashboard-td`}>
              <Button
                element={"button"}
                size={"btn-lg"}
                type={"btn-secondary"}
                label={"Add +"}
                active={true}
                customStyles={{
                  height: "30px",
                }}
              />
            </div>
            <div className={`td dashboard-td`}>
              <Button
                element={"button"}
                size={"btn-lg"}
                type={"btn-secondary"}
                label={"Add +"}
                active={true}
                customStyles={{
                  height: "30px",
                }}
              />
            </div>
            <div className={`td dashboard-td`}>
              <span>{swap}</span>
            </div>
            <div className={`td dashboard-td`}>
              <span>{value}</span>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      {/* <EmptyContent description="You have no open positions" /> */}
      <Table
        tableData={tableData}
        tableFooter={null}
        tableHead={transactionHeader}
        customStyles={{
          border: "1px solid rgba(255, 255, 255, 0.1)",
          borderRadius: "20px",
          background: "rgba(255, 255, 255, 0.01)",
          backdropFilter: "blur(5px)",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
        customHeadStyles={{
          height: "81px",
          alignItems: "center",
        }}
        translates={translates}
        // loading={loading}
      />
    </div>
  );
};

export default Positions;
