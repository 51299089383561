import React from "react";

const BitcoinLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
    >
      <rect
        x="0.5"
        y="1"
        width="31"
        height="31"
        rx="15.5"
        fill="url(#paint0_linear_40_8900)"
        stroke="url(#paint1_linear_40_8900)"
      />
      <path
        d="M21.9191 14.9594C22.1646 13.28 20.8856 12.3757 19.1351 11.7685L19.7035 9.49475L18.3212 9.14594L17.7657 11.3421C17.404 11.2453 17.0293 11.1613 16.6547 11.0773L17.2102 8.84235L15.8279 8.5L15.2595 10.7737L14.381 10.567L12.4431 10.0826L12.0943 11.5618C12.0943 11.5618 13.1214 11.8008 13.102 11.8137C13.2911 11.841 13.4624 11.9402 13.5803 12.0906C13.6982 12.2409 13.7536 12.4309 13.735 12.6211L13.0891 15.2049C13.1382 15.2138 13.186 15.229 13.2312 15.2501L13.0891 15.2243L12.1912 18.8351C12.1707 18.8993 12.1377 18.9588 12.094 19.0102C12.0504 19.0616 11.997 19.1038 11.9369 19.1345C11.8769 19.1651 11.8113 19.1835 11.7441 19.1887C11.6769 19.1938 11.6093 19.1856 11.5453 19.1645L10.5376 18.9126L9.85938 20.5275L11.6616 20.9732L12.6498 21.2315L12.075 23.5311L13.4637 23.8799L14.0322 21.5997C14.4068 21.7031 14.775 21.8 15.1367 21.8839L14.5683 24.1577L15.9571 24.5L16.532 22.2004C18.8961 22.6461 20.6725 22.4717 21.4217 20.3272C22.0225 18.609 21.4217 17.6143 20.1299 16.9683C20.6072 16.8761 21.0409 16.6295 21.3643 16.2665C21.6876 15.9035 21.8826 15.4442 21.9191 14.9594ZM18.7475 19.3971C18.3212 21.1217 15.4209 20.1916 14.4843 19.959L15.2401 16.8973C16.1832 17.1169 19.1932 17.582 18.7475 19.3712V19.3971ZM19.1803 14.9336C18.7863 16.4968 16.3769 15.7023 15.5889 15.5085L16.28 12.718C17.0616 12.9118 19.5873 13.2735 19.1803 14.9078V14.9336Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_40_8900"
          x1="0.36923"
          y1="2.12319"
          x2="33.9012"
          y2="4.39119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="1" stopColor="white" stopOpacity="0.02" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_40_8900"
          x1="6.95385"
          y1="0.499999"
          x2="24.2795"
          y2="14.2939"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopColor="white" stopOpacity="0.05" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BitcoinLogo;
