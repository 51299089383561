import { useCallback } from "react";

function useFormattedNumber() {
  const formatNumber = useCallback((number, decimalPlaces = 4) => {
    if (typeof number !== "number") {
      return "";
    }

    const parts = number.toString().split(".");

    // Ensure that decimalPlaces is not negative
    decimalPlaces = Math.max(decimalPlaces, 0);

    if (parts.length === 2) {
      const decimalPart = parts[1].substring(0, decimalPlaces); // Get up to decimalPlaces
      return `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${decimalPart}`;
    } else {
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }, []);

  return { formatNumber };
}

export default useFormattedNumber;
