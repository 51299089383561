import React from "react";
import { useParams } from "react-router-dom";
import { useExtensionsData } from "../hooks/useExtensionsData";
import { InnerExtensions as ExtensionItemUI } from "./Extensions/InnerExtensions";

const ExtensionItem = ({ helpSupportClick }) => {
  const { extensionsCardsData } = useExtensionsData();
  const { id } = useParams();

  return (
    <ExtensionItemUI
      extensionsCardsData={extensionsCardsData}
      id={id}
      helpSupportClick={helpSupportClick}
    />
  );
};

export default ExtensionItem;
