import React from "react";

const Arrow = () => {
  return (
    <div style={{ transform: `rotate(${-90}deg)` }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.018"
        height="18"
        fill="rgb(53, 219, 64)"
      >
        <path d="M10.018 18v-5h10V5h-10V0L0 8.939 10.018 18z" />
      </svg>
    </div>
  );
};

export default Arrow;
