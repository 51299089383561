import React from "react";
import { HelpText } from "../helpText/HelpText";

import "./DisabledPage.css";

export const DisabledPage = ({ order }) => {
  return (
    <>
      <div
        className={`${"disabled-account-overlay"} ${
          order === "dashboard" ? "disabled-account-overlay-dashboard" : ""
        }`}
      ></div>
      <HelpText
        className="message"
        status="warning"
        icon={true}
        title={
          " This account is disabled. Try to contact support. Note: disabled user can still use onchain activites like staking."
        }
      />
    </>
  );
};
