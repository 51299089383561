import BSCsvg from "../assets/svg/BSCsvg";
import UsdtSvg from "../assets/svg/UsdtSvg";
import EtherSvg from "../assets/svg/EtherSvg";
import BnbSvg from "../assets/svg/BnbSvg";

export const methods = [
  {
    id: "BNB",
    title: "BNB",
    svg: <BnbSvg />,
    ethereumAddress: "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
  },
  // {
  //   id: "USDT",
  //   title: "USDT",
  //   svg: <UsdtSvg />,
  //   ethereumAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  // },
  // Test bnb
  // {
  //   id: "BNB",
  //   title: "BNB",
  //   svg: <BnbSvg />,
  //   ethereumAddress: "0x3FAF7e4Fe6a1c30F78Cc3A83755E33364BAb77Ed"
  // },
  // End test bnb
];

export const rpcs = [
  {
    id: "BSC",
    title: "BSC",
    svg: <BnbSvg />,
    rpc1: process.env.REACT_APP_BSC_RPC1,
    rpc2: process.env.REACT_APP_BSC_RPC2,
  },
  // {
  //   id: "ETH",
  //   title: "ETH",
  //   svg: <EtherSvg />,
  //  rpc1: process.env.REACT_APP_ETH_RPC1,
  //  rpc2: process.env.REACT_APP_ETH_RPC2,
  // },
  // {
  //   id: "BSC",
  //   title: "BSC",
  //   svg: <BnbSvg />,
  //   rpc1: process.env.REACT_APP_BSC_TESTNET_RPC1,
  //   rpc2: process.env.REACT_APP_BSC_TESTNET_RPC2,
  // },
];
