import React from "react";

const AlogoSvg = () => {
  return (
    <svg
      width="368"
      height="284"
      viewBox="0 0 368 284"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.7"
        d="M366.647 0.569702L298.6 283.493H235.564L296.737 63.9269L295.891 63.4493L144.892 223.213L144.094 224.057H145.255H175.998L159.119 283.493H87.9226H45.5623H1.83588L279.119 0.569702H366.647Z"
        stroke="#C38C5C"
      />
    </svg>
  );
};

export default AlogoSvg;
