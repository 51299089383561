const Success = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(43,49,95,1)",
      }}
    >
      Success
    </div>
  );
};

export default Success;
