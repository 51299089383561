import EmptyContent from "./emptyContent/EmptyContent";

const Orders = () => {
    return (
        <div>
            <EmptyContent description="You have no open orders" />
        </div>
    );
};

export default Orders;