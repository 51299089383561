import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { useDisconnect } from "wagmi";

import axios from "../api/axios";

export const useApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deactivate } = useWeb3React();
  const { disconnect } = useDisconnect();

  const logout = () => {
    dispatch({ type: "SET_EXCHANGE_DETAILS", payload: {} });
    dispatch({ type: "SET_TRANSACTION_STATUS", payload: "" });
    dispatch({ type: "SET_LOGOUT_WITH_EMAIL" });
    dispatch({ type: "SET_ADDRESS", payload: "" });
    dispatch({ type: "SET_SIDE_BAR", payload: { sideBarOpen: false } });
    dispatch({ type: "SET_LAST_CONNECTION_TYPE", payload: "" });
    dispatch({
      type: "UPDATE_STAKE_STATE",
      payload: {
        stakersRecord: [],
      },
    });

    deactivate();
    disconnect();

    axios
      .post("/api/accounts/logout", {})
      .then((res) => {
        navigate("/");
      })
      .catch((e) => {});
  };

  const updateState = async (callback) => {
    await axios
      .post("/api/accounts/get_account", {})
      .then((res) => {
        let result = res?.data?.data;

        if (result?.accounts?.length > 0) {
          let exts1 = result?.accounts?.[0].extensions;

          if (result?.accounts?.[0]?.active) {
            exts1.dashboard = "true";
          }

          dispatch({
            type: "SET_USER_DATA",
            payload: res.data.data.accounts[0],
          });

          dispatch({
            type: "UPDATE_ACTIVE_EXTENSIONS",
            payload: exts1,
          });

          dispatch({
            type: "SET_EXTENSIONS_LOADED",
            payload: true,
          });

          dispatch({
            type: "SET_ACCOUNTS_DATA",
            payload: res.data.data.accountBalances,
          });

          if (callback) callback();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const values = useMemo(
    () => ({
      updateState,
      logout,
    }),
    []
  );

  return values;
};
