import React from "react";

const OwnAccountSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 15C2 18.87 5.13 22 9 22L7.95 20.25"
        stroke="#C38C5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4229 11.2258H12.2584C11.5808 11.2053 10.9381 10.9205 10.4678 10.4321C9.99758 9.94379 9.73715 9.29081 9.74226 8.61289C9.74226 7.17096 10.9132 6 12.3552 6C13.0386 6.00331 13.6937 6.27422 14.1798 6.75466C14.666 7.23511 14.9446 7.88687 14.956 8.57027C14.9674 9.25368 14.7106 9.91436 14.2407 10.4107C13.7709 10.9071 13.1253 11.1997 12.4423 11.2258H12.4229ZM12.3552 7.45161C11.7164 7.45161 11.1939 7.97419 11.1939 8.61289C11.1939 9.24192 11.6874 9.75483 12.3068 9.77418C12.3068 9.7645 12.3648 9.7645 12.4326 9.77418C13.0422 9.73547 13.5164 9.23225 13.5164 8.61289C13.5164 7.97419 12.9939 7.45161 12.3552 7.45161Z"
        fill="#C38C5C"
      />
      <path
        d="M12.3548 18.0001C11.2516 18.0001 10.1484 17.7097 9.28709 17.1388C8.47419 16.5968 8 15.813 8 14.9807C8 14.1485 8.46451 13.3549 9.28709 12.813C11.0097 11.6711 13.7 11.6711 15.4129 12.813C16.2258 13.3549 16.7 14.1485 16.7 14.971C16.7 15.8033 16.2355 16.5872 15.4129 17.1388C14.5613 17.7194 13.458 18.0001 12.3548 18.0001ZM10.0903 14.0227C9.67419 14.3033 9.45161 14.642 9.45161 14.9807C9.45161 15.3194 9.68387 15.6581 10.0903 15.9388C11.3193 16.7614 13.3806 16.7614 14.6097 15.9388C15.0258 15.6581 15.258 15.3194 15.2484 14.9807C15.2484 14.642 15.0161 14.3033 14.6097 14.0227C13.3903 13.2001 11.3193 13.2001 10.0903 14.0227Z"
        fill="#C38C5C"
      />
      <path
        d="M22 9C22 5.13 18.87 2 15 2L16.05 3.75"
        stroke="#C38C5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OwnAccountSvg;
