import { useState } from "react";
import { Popup } from "../UI/popup/Popup";
import { Button } from "../UI/button/Button";
import ConfirmPaymentPopup from "./ConfirmPaymentPopup";
import { Input } from "../UI/input/Input";
import { HelpText } from "../UI/helpText/HelpText";
import { HelpCard } from "../UI/helpCard/HelpCard";

import "./TopUpDashboard.css";

export const TopUpDashboard = ({
  handlePaymentConfirm,
  receivePaymentAddress,
  methods,
  qrcode,
  tranasctionFee,
  exchangeRate = 0,
  handlePurchaseEvent,
  rpcs,
  rates,
  exchangeDetails,
  createChargeLoading,
  translates,
  cancel_exchange,
  setExchangeDetails,
  active,
  transactionStatus,
  disableCancel,
  closePopup,
}) => {
  const [selectedMethod, setSelectedMethod] = useState("BNB");
  const [selectedChain, setSelectedChain] = useState("BSC");
  const [amountUSD, setAmountUSD] = useState(0);

  const [tokenError, setTokenError] = useState(null);

  const handleMethodSelect = (method) => {
    setSelectedMethod(method);
  };

  function handleChainSelect(chain) {
    setSelectedChain(chain);
  }

  const handleUSDAmountChange = (event) => {
    const value = event.target.value;

    if (!isNaN(value) && value >= 0) {
      if (value > 0) setTokenError(null);
      setAmountUSD(Number(value));
    } else if (exchangeDetails?.amountUSD) {
      setAmountUSD(Number(exchangeDetails?.amountUSD));
    }
  };

  const handlePurchase = () => {
    if (amountUSD <= 0) {
      setTokenError("Amount has to be greater than 0");
      return;
    }
    setTokenError(null);
    handlePurchaseEvent(
      selectedMethod,
      selectedChain,
      roundUpToTwoDecimals(
        (+amountUSD + +tranasctionFee) /
          rates?.[selectedMethod?.toLowerCase()]?.usd
      ),
      amountUSD
    );
  };

  function countViaRate(amount) {
    return Number((amount / Number(exchangeRate))?.toFixed(2));
  }

  function roundUpToTwoDecimals(number) {
    const roundedNumber = Math.ceil(number * 10000) / 10000;
    return exchangeDetails?.exchangeId
      ? exchangeDetails.amount
      : roundedNumber.toFixed(4);
  }

  return (
    <div className="topupDashboard_main">
      <h1>{translates?.purchase.en}</h1>
      <p>{translates?.please_choose_the_payment_currency.en}</p>
      <div className="LandingSteps__topUpOptions">
        {methods.map((method) => (
          <div
            key={method.id}
            className={`topup_steps_methodBox ${
              selectedMethod === method.id ? "topup_steps_selected" : ""
            }`}
            onClick={() => {
              if (method.id === "ETH" || method.id === "USDT") {
                setSelectedChain("ETH");
              }
              handleMethodSelect(method.id);
            }}
          >
            {method.title}
            <img src={method.logo} className="topup_method_logo" alt="" />
            {method.svg}
          </div>
        ))}
      </div>
      <p>{translates?.please_choose_a_network.en}</p>
      <div className="LandingSteps__topUpOptions">
        {rpcs.map((chain) => (
          <div
            key={chain.id}
            className={`topup_steps_methodBox ${
              selectedChain === chain.id ? "topup_steps_selected" : ""
            } ${
              selectedMethod === "ETH" && chain.id !== "ETH"
                ? "topup_steps_disabled"
                : ""
            } ${
              selectedMethod === "USDT" && chain.id !== "ETH"
                ? "topup_steps_disabled"
                : ""
            }`}
            onClick={() => {
              if (selectedMethod === "ETH" || selectedMethod === "USDT") {
                return;
              }
              handleChainSelect(chain.id);
            }}
          >
            {chain.title}
            <img src={chain.logo} className="topup_method_logo" alt="" />
            {chain.svg}
          </div>
        ))}
      </div>
      <div className="topupDashboard_title-bottomContainer">
        <div className="topupDashboard_bottom-left">
          <p className="topupDashboard_title">
            {translates?.please_enter_desired_amount.en}
          </p>
          <p className="topupDashboard_info">
            {translates?.enter_the_amount_you_would.en}
          </p>
          <div className="topupDashboard_inputContainer">
            <Input
              type={"default"}
              value={amountUSD}
              inputType={"text"}
              placeholder="Enter amount"
              onChange={handleUSDAmountChange}
              customStyles={{ width: "100%" }}
              disabled={exchangeDetails?.exchangeId ? true : false}
              customInputStyles={{
                border: "1px solid rgba(255, 255, 255, 0.1)",
              }}
            />
            <div className="topupDashboard_inputOverlay">
              <p className="topupDashboard_inputOverlay_text">$</p>
            </div>
          </div>

          <p className="topupDashboard_info-exchangeRate">
            1 USD = {countViaRate(1)} AONE
          </p>
          {tokenError && (
            <HelpText status={"error"} title={tokenError} color={"#FF0C46"} />
          )}
        </div>
        <div className="topupDashboard_bottom-right">
          <h3>{translates?.purchase_information.en}</h3>
          <div className="topupDashboard_bottom-row">
            <p>{translates?.amount.en}:</p>
            <p>
              {amountUSD ? amountUSD : 0} USD = {countViaRate(amountUSD)} AONE
            </p>
          </div>
          <div className="topupDashboard_bottom-row">
            <p>{translates?.transaction_fee.en}: </p>
            <p> {tranasctionFee} USD</p>
          </div>
          <h3 className="topupDashboard_bottom-result">
            TOTAL: {`${amountUSD + Number(tranasctionFee)} USD`}
          </h3>
          <Button
            element="button"
            label={createChargeLoading ? "Loading..." : `Top Up`}
            type="btn-secondary"
            size="btn-lg"
            customStyles={{
              width: "100%",
              margin: "0",
            }}
            onClick={handlePurchase}
            disabled={createChargeLoading}
          />
        </div>

        {exchangeDetails?.exchangeId && !active && !closePopup && (
          <Popup
            popUpElement={
              <ConfirmPaymentPopup
                exchangeRate={exchangeRate}
                tranasctionFee={tranasctionFee}
                rates={rates}
                receivePaymentAddress={receivePaymentAddress}
                handlePaymentConfirm={handlePaymentConfirm}
                qrcode={qrcode}
                selectedMethod={selectedMethod}
                tokenAmount={amountUSD}
                exchangeDetails={exchangeDetails}
                cancel_exchange={cancel_exchange}
                disableCancel={disableCancel}
              />
            }
            label={"Confirm Payment"}
            handlePopUpClose={() => setExchangeDetails({})}
          />
        )}
        {
          <HelpCard
            result={transactionStatus === "approved" ? "success" : "canceled"}
            text={
              transactionStatus === "approved"
                ? "Payment complited"
                : "Payment canceled"
            }
            body={"notification"}
            active={active}
          />
        }
      </div>
    </div>
  );
};
