import React from "react";
import styles from "../TradeSideBar.module.css";

function InstrumentDetails() {
  let marketSentiment = (
    <div className={styles.marketSentiment}>
      <div className={styles.marketSentimentValues}>
        <div>50%</div>
        <div>50%</div>
      </div>
      <div className={styles.lines}>
        <div className={styles.green} style={{width: "90%"}}></div>
        <div className={styles.red} style={{width: "10%"}}></div>
      </div>
    </div>
  );
  const data = [
    {
      title: "Market sentiment",
      content: marketSentiment,
    },
    {
      title: "Current buy price",
      content: "9000",
    },
    {
      title: "Current sell price",
      content: "9000",
    },
    {
      title: "Currency",
      content: "9000",
    },
    {
      title: "Lot size",
      content: "9000",
    },
    {
      title: "Trading hours",
      content: "9000",
    },
    {
      title: "Spread",
      content: "9000",
    },
    {
      title: "Leverage",
      content: "9000",
    },
    {
      title: "Margin, %",
      content: "9000",
    },
    {
      title: "Minimum Trade Size",
      content: "9000",
    },
    {
      title: "Maximum Trade Size",
      content: "9000",
    },
    {
      title: "Overnight interest (yearly rate) Buy",
      content: "9000",
    },
    {
      title: "Overnight interest (yearly rate) Sell",
      content: "9000",
    },
    {
      title: "Overnight type",
      content: "9000",
    },
    {
      title: "Exchange",
      content: "9000",
    },
    {
      title: "Measurement unit",
      content: "9000",
    },
    {
      title: "Day change, %",
      content: "9000",
    },
    {
      title: "Week change, %",
      content: "9000",
    },
    {
      title: "Month change, %",
      content: "9000",
    },
  ];

  return (
    <div className={styles.buySellBtn}>
      <div className={styles.table}>
        {data.map((item, index) => {
          return (
            <div className={styles.item}>
              <div className={styles.itemTitle}>{item.title}</div>
              <div className={styles.itemContent}>{item.content}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InstrumentDetails;
