const WalletBalance = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7516 16.8599V18.8899C10.7516 20.6099 9.15156 21.9999 7.18156 21.9999C5.21156 21.9999 3.60156 20.6099 3.60156 18.8899V16.8599C3.60156 18.5799 5.20156 19.7999 7.18156 19.7999C9.15156 19.7999 10.7516 18.5699 10.7516 16.8599Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7498 14.11C10.7498 14.61 10.6098 15.07 10.3698 15.47C9.77984 16.44 8.56984 17.05 7.16984 17.05C5.76984 17.05 4.55984 16.43 3.96984 15.47C3.72984 15.07 3.58984 14.61 3.58984 14.11C3.58984 13.25 3.98984 12.48 4.62984 11.92C5.27984 11.35 6.16984 11.01 7.15984 11.01C8.14984 11.01 9.03984 11.36 9.68984 11.92C10.3498 12.47 10.7498 13.25 10.7498 14.11V14.11Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7516 14.11V16.86C10.7516 18.58 9.15156 19.8 7.18156 19.8C5.21156 19.8 3.60156 18.57 3.60156 16.86V14.11C3.60156 12.39 5.20156 11 7.18156 11C8.17156 11 9.06156 11.35 9.71156 11.91C10.3516 12.47 10.7516 13.25 10.7516 14.11V14.11Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0002 10.97V13.03C22.0002 13.58 21.5602 14.03 21.0002 14.05H19.0402C17.9602 14.05 16.9702 13.26 16.8802 12.18C16.8202 11.55 17.0602 10.96 17.4802 10.55C17.8502 10.17 18.3602 9.94995 18.9202 9.94995H21.0002C21.5602 9.96995 22.0002 10.42 22.0002 10.97Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 10.5V8.5C2 5.78 3.64 3.88 6.19 3.56C6.45 3.52 6.72 3.5 7 3.5H16C16.26 3.5 16.51 3.51 16.75 3.55C19.33 3.85 21 5.76 21 8.5V9.95H18.92C18.36 9.95 17.85 10.17 17.48 10.55C17.06 10.96 16.82 11.55 16.88 12.18C16.97 13.26 17.96 14.05 19.04 14.05H21V15.5C21 18.5 19 20.5 16 20.5H13.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WalletBalance;
