import { useState, useEffect, useMemo } from "react";
import Web3 from "web3";
import { Popup } from "./UI/popup/Popup";
import { LandingSteps } from "./LandingSteps/LandingSteps";
import { Button } from "../components/UI/button/Button";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useConnect } from "wagmi";

import { getBalance } from "../utils/getBalance";
import { socket } from "../api/socket";
import { useConnectMeta } from "../hooks/use-connect";
import { useApp } from "../hooks/use-app";
import { useStake } from "../hooks/use-stake";
import { injected } from "../utils/connector";
import { useTableParameters } from "../hooks/useTableParameters";
import { decryptEnv } from "../utils/decryptEnv";

import QRCode from "qrcode";
import axios from "../api/axios";

import {
  methods,
  rpcs,
  paymentTypes,
} from "../dummyComponents/landingRegistration";

const LandingRegistration = ({
  step,
  setStep,
  setInitialRegister,
  translates,
}) => {
  const Router = decryptEnv(process.env.REACT_APP_STAKING_CONTRACT_ADDRESS);
  const tokenAddress = decryptEnv(process.env.REACT_APP_TOKEN_ADDRESS);

  const triedReconnect = useSelector((state) => state.appState?.triedReconnect);
  const appState = useSelector((state) => state.appState);
  const rates = useSelector((state) => state.appState?.rates);
  const providerType = useSelector((state) => state.connect.providerType);
  const tiers = useSelector((state) => state.appState?.tiers);
  const exchangeDetails = useSelector((state) => state.connect.exchangeDetails);
  const transactionStatus = useSelector(
    (state) => state.connect.transactionStatus
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { durationOptions } = useTableParameters("staking");
  const { account, connectMetaMask, active, library } = useConnectMeta();
  const { updateState, logout } = useApp();
  const { connect, connectors, error, status, variables } = useConnect();

  // Transaction fee should be converted from usd to aone
  const [transactionFee] = useState(20);
  const [hostedUrl, setHostedUrl] = useState("");
  const [amountError, setAmountError] = useState("");
  const [apyPercent, setApyPercent] = useState(0);
  const [referralCodeAlreadyUsed, setReferralCodeAlreadyUsed] = useState(false);
  const tokenBalance = useSelector((state) => state.connect.tokenBalance);
  const mainAccount = useMemo(
    () =>
      appState?.accountsData?.find((acc) => acc?.account_category === "main"),
    [appState?.accountsData]
  );
  const [disableCancel, setDisableCancel] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [watchReferral, setWatchReferral] = useState(true);
  const [coinbaseLoading, setCoinbaseLoading] = useState(false);
  const [stakingLoading, setStakingLoading] = useState(false);
  const [approveResonse, setApproveResonse] = useState(null);
  const [referralCodeChecked, setReferralCodeChecked] = useState(false);
  const [finishLoading, setFinishLoading] = useState(false);
  const [activeRange, setActiveRange] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [createChargeLoading, setCreateChargeLoading] = useState(false);
  const [checkReferralCodeState, setCheckReferralCodeState] = useState({
    loading: false,
    message:
      "Referral code is required, please check referral code before staking",
    status: "warning",
  });
  const [referralState, setReferralState] = useState({
    value: "",
    loading: false,
    message: "",
    status: "",
  });
  const [registrationState, setRegistrationState] = useState({
    loading: false,
    fullnameError: "",
    emailError: "",
    referralError: "",
    emailSent: false,
  });

  useEffect(() => {
    if (hostedUrl) {
      window.location.href = hostedUrl;
    }
  }, [hostedUrl]);

  useEffect(() => {
    if (account && triedReconnect && active) {
      setRegistrationState({
        loading: false,
        fullnameError: "",
        emailError: "",
        referralError: "",
        emailSent: false,
      });
    }
  }, [account, triedReconnect, active]);

  async function handleRegistration({ fullName, email, referral }) {
    const errors = {};
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!fullName || /^\s*$/.test(fullName)) {
      errors.fullNameError = "Full Name is required";
    }

    if (email && !emailRegex.test(email)) {
      errors.emailError = "Invalid email";
    }

    if (!email) {
      errors.emailError = "Email is required";
    }

    if (Object.keys(errors).length > 0) {
      setRegistrationState({
        ...registrationState,
        ...errors,
      });
      return;
    }

    setRegistrationState({
      ...registrationState,
      loading: true,
    });

    const checkEmail = async () => {
      try {
        const res = await axios.post("/api/accounts/check-email", {
          email: email,
        });
        let { status, msg } = res?.data;

        if (!status) {
          setRegistrationState({
            ...registrationState,
            emailError: msg,
          });
          setRegistrationState({
            ...registrationState,
            emailError: msg,
            loading: false,
          });
          return false;
        }
        return true;
      } catch (e) {
        setRegistrationState({
          ...registrationState,
          emailError:
            "This email provider is not supported. Please use a different email provider.",
          loading: false,
        });
        return false;
      }
    };

    const emailIsValid = await checkEmail();

    if (!emailIsValid) {
      return;
    }

    async function updateProfile() {
      if (library || providerType === "walletConnect") {
        axios
          .post("/api/accounts/update_profile", {
            address: account,
            name: fullName,
            email,
          })
          .then((res) => {
            if (res?.data === "email sent") {
              setRegistrationState((prev) => ({
                ...prev,
                emailSent: true,
                loading: false,
              }));
            }

            let step = 3;

            if (
              appState?.rates?.atr?.usd &&
              tokenBalance >= 100 / appState?.rates?.atr?.usd
            ) {
              step = 4;
            }
            axios
              .post("/api/accounts/handle-step", { step, address: account })
              .then((e) => {
                setStep(e?.data?.account?.step ?? 3);
                setRegistrationState({
                  ...registrationState,
                  loading: false,
                });
              })
              .catch((e) => {
                setRegistrationState({
                  ...registrationState,
                  loading: false,
                });
                toast.error("Something went wrong!", {
                  autoClose: false,
                });
              });
          })
          .catch((err) => {
            if (err?.response?.data === "email already exists & is verified") {
              setRegistrationState((prev) => ({
                ...prev,
                loading: false,
              }));
              toast.error("Email is already in use.", {
                autoClose: false,
              });
            }
          });
      }
    }

    updateProfile();
  }

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    // referral: "",
  });

  useEffect(() => {
    if (appState?.userData) {
      setFormData({
        fullName: appState?.userData?.meta?.name ?? "",
        email: appState?.userData?.meta?.email ?? "",
      });
    }
  }, [appState?.userData]);

  async function resendEmail() {
    axios.post("/api/accounts/resend-email", {
      address: account,
    });
    // .then((res) => {
    //   console.log(res.response);
    // })
    // .catch((e) => {
    //   console.log(e.response);
    // });
  }

  async function handleCoindbasePayment(amount) {
    if (!rates) {
      toast.error("Something went wrong!", {
        autoClose: false,
      });
      setStakingLoading(false);
      return;
    }

    const buyAmount = (Number(amount) - 1) / Number(rates?.["atr"]?.usd);

    if (buyAmount < 50) {
      toast.error("Minimum amount is 100 $", {
        autoClose: false,
      });
      setStakingLoading(false);
      return;
    }

    if (buyAmount > 250000) {
      toast.error("Maximum amount is 500,000 $", {
        autoClose: false,
      });
      setStakingLoading(false);
      return;
    }

    if (
      (buyAmount > 250 && buyAmount < 2500) ||
      (buyAmount > 250 && buyAmount % 2500 !== 0)
    ) {
      toast.error("Amount higher than 500 $ should be multiple of 5,000 $", {
        autoClose: false,
      });
      setStakingLoading(false);
      return;
    }

    setCoinbaseLoading(true);

    axios
      .post("api/transactions/coinbase_deposit_transaction", {
        from: account,
        amount,
      })
      .then((res) => {
        setHostedUrl(res?.data?.responseData?.hosted_url);
        setCoinbaseLoading(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong!", {
          autoClose: false,
        });
        setCoinbaseLoading(false);
      });
  }

  const {
    approve,
    stake,
    handleTimeperiodDate,
    handleDepositAmount,
    handleTimePeriod,
  } = useStake({
    Router: Router,
    tokenAddress: tokenAddress,
  });

  const { depositAmount, timeperiod, isAllowance, timeperiodDate } =
    useSelector((state) => state.stake);

  const inputs = [
    {
      title: "Amount",
      name: "amount",
      type: "default",
      placeholder: "0",
      onChange: (e) => {
        handleDepositAmount(e.target.value);
      },
    },
  ];

  function amountProgressOnchange(e) {
    const originalValue = e.target.value;
    let value = Number(originalValue);

    if (isNaN(value)) {
      setAmountError("Please enter a valid number");
    } else if (value < 0) {
      setAmountError("Please enter a positive number");
    } else if (value < 100) {
      setAmountError("Minimum amount is 100 $");
    } else if (value >= 100 && value <= 500) {
      setAmountError("");
    } else if (value > 500 && value % 5000 !== 0) {
      setAmountError("Above 500 $, the amount must be a multiple of 5,000 $");
    } else if (value > 500000) {
      setAmountError("Maximum limit is 500,000 $");
    } else {
      setAmountError("");
    }

    handleDepositAmount(value);
  }

  useEffect(() => {
    if (step !== 3 && step !== 4) {
      return;
    }

    let timer;
    let count = 0;

    const manageStepByBalance = () => {
      if (library || providerType === "walletConnect") {
        if (
          //tokenBalance >= 100 / appState?.rates?.atr?.usd &&
          tokenBalance >= 100 &&
          tokenBalance <= 500 &&
          activeRange === "non-referral" &&
          tokenBalance >= 5000 &&
          activeRange === "referral" &&
          step === 3
          //appState?.rates?.atr?.usd
        ) {
          clearInterval(timer);
          axios
            .post("/api/accounts/handle-step", { step: 4, address: account })
            .then((e) => {
              setStep(
                appState?.userData?.step > 4 ? appState?.userData?.step : 4
              );
              setRegistrationState({
                ...registrationState,
                loading: false,
              });
            })
            .catch((e) => {
              setRegistrationState({
                ...registrationState,
                loading: false,
              });
              toast.error("Something went wrong!", {
                autoClose: false,
              });
            });
        }

        count++;
      }

      getBalance(providerType, library, tokenAddress, account).then((res) => {
        if (tokenBalance !== res) {
          setShowSuccess(false);
          dispatch({
            type: "GET_TOKEN_BALANCE",
            payload: res,
          });
        }
      });

      if (count >= 360) {
        clearInterval(timer);
      }
    };

    manageStepByBalance();

    timer = setInterval(manageStepByBalance, 10000);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, library]);

  // Activate account on register
  async function activateAccount() {
    axios
      .post(
        "/api/accounts/activate-account",
        {
          address: account,
        },
        {
          timeout: 120000,
        }
      )
      .then((res) => {
        updateState();
        setTimeout(() => {
          handleDepositAmount(0);
        }, 3000);
      })
      .catch((e) => {});
  }

  const handleDepositSubmit = async () => {
    setStakingLoading(true);

    if (!depositAmount && !isAllowance) {
      setApproveResonse({
        status: "error",
        message: "Please enter a valid amount",
      });
      setTimeout(() => {
        setStakingLoading(false);
        setApproveResonse(null);
      }, 3000);
      return;
    }

    if (account && isAllowance) {
      approve(
        () => {
          setStakingLoading(false);
          toast.success("Approved successfully, please stake desired amount.", {
            autoClose: 8000,
          });
        },
        () => {
          setStakingLoading(false);
          toast.error("Approval failed, please try again.", {
            autoClose: false,
          });
        },
        depositAmount / (appState?.rates?.atr?.usd ?? 2)
      );
    }

    if (account && !isAllowance) {
      const buyAmount = Number(depositAmount);
      if (buyAmount < 100) {
        toast.error("Minimum amount is 100", {
          autoClose: false,
        });
        setStakingLoading(false);
        return;
      }
      if (buyAmount > 500000) {
        toast.error("Maximum amount is 500000", {
          autoClose: false,
        });
        setStakingLoading(false);
        return;
      }

      if (
        (buyAmount > 500 && buyAmount < 5000) ||
        (buyAmount > 500 && buyAmount % 5000 !== 0)
      ) {
        toast.error("Amount higher than 500 should be multiple of 5000", {
          autoClose: false,
        });

        setStakingLoading(false);
        return;
      }

      if (buyAmount > 500 && !referralState.value) {
        setReferralState({
          ...referralState,
          message: "empty",
          status: "",
        });

        toast.error("Please enter referral code", {
          autoClose: false,
        });

        setStakingLoading(false);
        return;
      }

      proceedStake(referralState.value);
    }
  };

  async function handleAfterStake() {
    setStep(5);
    axios
      .post("/api/accounts/handle-step", {
        address: account,
        step: 5,
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
    // Activate
    activateAccount();
    setStakingLoading(false);
    handleDepositAmount("");
    handleTimePeriod(5);
  }

  function roundUpToTwoDecimals(number) {
    const roundedNumber = Math.ceil(number * 100) / 100;
    return roundedNumber.toFixed(2);
  }

  async function proceedStake(referralCode) {
    if (+depositAmount < 5000 && referralCodeChecked) {
      toast.error("Stake amount must be at least 5000", {
        autoClose: false,
      });
      return;
    }

    setWatchReferral(false);
    stake(
      roundUpToTwoDecimals(+depositAmount / rates?.atr?.usd),
      async () => {
        const buyAmount = Number(depositAmount);

        if (buyAmount > 500) {
          await axios
            .post("api/referral/register_referral", {
              referral_address: referralCode,
              user_address: account,
              side: "auto",
            })
            .then((res) => {
              handleAfterStake();
            })
            .catch((err) => {
              if (err?.response?.data) {
                toast.error(err?.response?.data, {
                  autoClose: false,
                });

                setStakingLoading(false);
                return;
              }
              setStakingLoading(false);
              toast.error("something went wrong", {
                autoClose: false,
              });
            });
        } else {
          handleAfterStake();
        }
      },
      () => {
        setWatchReferral(true);
        setStakingLoading(false);
        toast.error("Staking failed, please try again.", {
          autoClose: false,
        });
      }
    );
  }

  async function handleAutomaticReferral() {
    try {
      const parts = referralState?.value?.split("_");
      const firstPart = parts[0];
      setReferralState((prev) => ({
        ...prev,
        value: firstPart,
      }));
      setReferralCodeAlreadyUsed(false);
    } catch (e) {
      toast.error(e?.response?.data ?? "something went wrong", {
        autoClose: false,
      });
    }
  }

  async function handleMetamaskSignin() {
    dispatch({
      type: "SET_METAMASK_CONNECT_LOADING",
      payload: true,
    });

    dispatch({
      type: "SET_ATTEMPT_SIGN",
      payload: {},
    });
  }

  async function handleWalletConnectSignin() {
    dispatch({
      type: "SET_WALLETCONNECT_LOADING",
      payload: true,
    });
    dispatch({
      type: "SET_ATTEMPT_SIGN",
      payload: {},
    });
  }

  useEffect(() => {
    if (!referralState.value) {
      setCheckReferralCodeState((prev) => ({
        ...prev,
        loading: false,
        status: "warning",
        message:
          "Referral code is required, please check referral code before staking",
      }));
      setReferralCodeChecked(false);
      return;
    }
    if (watchReferral) {
      setCheckReferralCodeState((prev) => ({
        ...prev,
        loading: true,
      }));
      axios
        .post("/api/referral/check_referral_available", {
          referral_address: referralState.value,
          user_address: account,
        })
        .then((res) => {
          setTimeout(() => {
            setCheckReferralCodeState((prev) => ({
              ...prev,
              loading: false,
              status: "success",
              message: "This referral code is available.",
            }));
            setReferralCodeChecked(true);
          }, 500);
        })
        .catch((e) => {
          let message =
            "Referral code is required, please check referral code before staking";

          if (e?.response?.data?.message === "no space") {
            setReferralCodeAlreadyUsed(true);
            return;
          }

          if (e?.response?.data === "Referral code incorrect") {
            setTimeout(() => {
              setCheckReferralCodeState((prev) => ({
                ...prev,
                loading: false,
                status: "warning",
                message: e?.response?.data,
              }));
              setReferralCodeChecked(false);
            }, 500);

            return;
          }

          setTimeout(() => {
            setCheckReferralCodeState((prev) => ({
              ...prev,
              loading: false,
              status: "warning",
              message: message,
            }));
            setReferralCodeChecked(false);
          }, 500);
        });
    }
  }, [referralState.value, mainAccount, account, watchReferral]);

  async function handleCreateCharge(token, rpc, amount, amountUSD) {
    try {
      const foundToken = methods.find((method) => method.title === token);
      const foundRPC = rpcs?.find((item) => item.id === rpc);

      let isNative = false;

      if (
        token === "ETH" ||
        (token === "BNB" && foundRPC.id === "BSC") ||
        (token === "BNB" && foundRPC.id === "BSC Testnet")
      ) {
        isNative = true;
      }

      let tokenAddress;

      if (!isNative) {
        tokenAddress = foundToken?.ethereumAddress;
      }

      setCreateChargeLoading(true);
      const { data } = await axios.post(
        "/api/transactions/create_exchange_transaction",
        {
          rpc1: foundRPC?.rpc1,
          rpc2: foundRPC?.rpc2,
          tokenAddress: tokenAddress,
          amount,
          decimals: 18,
          isNative,
          transactionFee,
          rates,
          type: "registration",
        }
      );

      setCreateChargeLoading(false);
      setDisableCancel(true);
      dispatch({
        type: "SET_EXCHANGE_DETAILS",
        payload: {
          exchangeId: data?.data?.exchangeId,
          address: data?.data?.address,
          amount,
          amountUSD,
        },
      });
    } catch (e) {
      toast.error(e?.response?.data?.message ?? "Something went wrong!", {
        autoClose: false,
      });
    }
  }

  async function handleCancelPayment() {
    try {
      await axios
        .post("/api/transactions/cancel_exchange", {
          exchangeId: exchangeDetails?.exchangeId,
          address: exchangeDetails?.address,
        })
        .then((res) => {
          let status = res?.data;

          if (status.success) {
            dispatch({
              type: "SET_EXCHANGE_DETAILS",
              payload: {},
            });

            dispatch({
              type: "SET_TRANSACTION_STATUS",
              payload: "canceled",
            });
          }
        });
    } catch (e) {
      toast.error(e?.response?.data?.message ?? "Something went wrong!", {
        autoClose: false,
      });
    }
  }

  useEffect(() => {
    if (exchangeDetails.address) {
      QRCode.toDataURL(exchangeDetails.address)
        .then((url) => {
          setQrCodeUrl(url);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [exchangeDetails?.address]);

  useEffect(() => {
    if (exchangeDetails?.exchangeId) {
      socket.emit("joinExchangeRoom", exchangeDetails?.exchangeId);
    }

    const handleExchangeStatus = (data) => {
      dispatch({
        type: "SET_TRANSACTION_STATUS",
        payload: data?.status,
      });

      if (transactionStatus === "pending") {
        setDisableCancel(true);
      } else if (transactionStatus !== "") {
        setDisableCancel(false);
        setShowSuccess(true);

        dispatch({
          type: "SET_TRANSACTION_STATUS",
          payload: "",
        });

        dispatch({
          type: "SET_EXCHANGE_DETAILS",
          payload: {},
        });
      }
    };

    socket.on("exchange_status", handleExchangeStatus);

    return () => {
      socket.off("exchange_status", handleExchangeStatus);
    };
  }, [exchangeDetails, transactionStatus, socket]);
  // wc
  useEffect(() => {
    if (
      tiers &&
      depositAmount &&
      (library || providerType === "walletConnect")
    ) {
      function findTierId(amount, tiers) {
        for (const tier of tiers) {
          let minStakeAmt = Web3.utils.fromWei(tier?.minStakeAmt, "ether");
          let maxStakeAmt = Web3.utils.fromWei(tier?.maxStakeAmt, "ether");

          if (
            amount / Number(rates?.["atr"]?.usd) >= Number(minStakeAmt) &&
            amount / Number(rates?.["atr"]?.usd) <= Number(maxStakeAmt)
          ) {
            return tier?.tierId;
          }
        }

        return "0";
      }

      let result = findTierId(parseInt(depositAmount), tiers);
      let apy = tiers?.filter((item) => item.tierId === result);
      let dividor = 1;

      if (timeperiod === 5) {
        dividor = 2;
      } else {
        dividor = 3;
      }

      let apyBonus = apy[0]?.bonuses[timeperiod].bonus;

      setApyPercent(parseFloat(apyBonus / 10) / dividor);
    }
  }, [depositAmount, tiers, timeperiod]);

  const handleFinish = () => {
    setFinishLoading(true);

    axios
      .post("/api/accounts/handle-step", {
        active: true,
        address: account,
        step: 6,
      })
      .then((res) => {
        setStep(6);
        let sendObj = {
          dashboard: "true",
          staking: "true",
        };

        if (
          res?.data?.account?.tier?.value !== "Novice Navigator" &&
          res?.data?.account?.tier?.value
        ) {
          sendObj.referral = "true";
          sendObj.referralAdmin = "true";
        } else {
          sendObj.referral = "false";
          sendObj.referralAdmin = "false";
        }

        if (
          res.data.emailSent &&
          res.data.emailSent.message &&
          res.data.emailSent.message === "Email not sent"
        ) {
          toast.error(res.data.emailSent.message, {
            autoClose: false,
          });
        }

        axios
          .post("/api/accounts/manage_extensions", {
            address: account,
            extensions: sendObj,
            setup: true,
          })
          .then((res) => {
            setFinishLoading(false);
            setTimeout(() => {
              navigate("/dashboard");
            }, 1000);
            if (res?.data?.account) {
              dispatch({
                type: "UPDATE_ACTIVE_EXTENSIONS",
                payload: res.data.account.extensions,
              });
            }
            // ...
            updateState();
          })
          .catch((e) => {
            setFinishLoading(false);
            toast.error(e?.response?.data?.message ?? "Something went wrong!", {
              autoClose: false,
            });
          });
      })
      .catch((err) => {
        setFinishLoading(false);
        toast.error(err?.response?.data?.message ?? "Something went wrong!", {
          autoClose: false,
        });
      });
  };

  return (
    <>
      <LandingSteps
        disableCancel={disableCancel}
        activeRange={activeRange}
        setActiveRange={setActiveRange}
        account={account}
        translates={translates}
        amountError={amountError}
        handleMetamaskConnect={async () => {
          await connectMetaMask("metaMask", injected, handleMetamaskSignin);
        }}
        handleWalletConnect={() => {
          dispatch({
            type: "UPDATE_STATE",
            isConnected: true,
            providerType: "walletConnect",
          });

          handleWalletConnectSignin();

          //open();
          connect({ connector: connectors[1], chainId: 56 })
        }}
        connectionLoading={appState}
        step={step}
        setStep={setStep}
        initialLoading={false}
        apyPercent={apyPercent}
        methods={methods}
        rpcs={rpcs}
        paymentTypes={paymentTypes}
        handleRegistration={handleRegistration}
        registrationState={registrationState}
        setRegistrationState={setRegistrationState}
        handleCoindbasePayment={(amount) => handleCoindbasePayment(amount)}
        formData={formData}
        setFormData={setFormData}
        resendEmail={resendEmail}
        disconnect={() => logout()}
        closeLandingSteps={() => setInitialRegister(false)}
        handlePurchaseEvent={handleCreateCharge}
        exchangeRate={Number(rates?.["atr"]?.usd)}
        rates={rates}
        tranasctionFee={transactionFee}
        timeperiod={timeperiod}
        timeperiodDate={timeperiodDate}
        handleTimePeriod={handleTimePeriod}
        handleTimeperiodDate={handleTimeperiodDate}
        durationOptions={durationOptions}
        buttonLabel={
          stakingLoading ? "Loading..." : isAllowance ? "Enable" : "Stake"
        }
        handleSubmit={() => handleDepositSubmit()}
        inputs={inputs}
        stakingLoading={stakingLoading}
        approveResonse={approveResonse}
        isAllowance={isAllowance}
        tokenBalance={tokenBalance}
        depositAmount={depositAmount}
        coinbaseLoading={coinbaseLoading}
        referralState={referralState}
        setReferralState={setReferralState}
        amountProgressOnchange={amountProgressOnchange}
        setAmountProgressValue={(value) => {
          handleDepositAmount(value);
        }}
        amountProgressValue={depositAmount}
        referralCodeChecked={referralCodeChecked}
        checkReferralCodeState={checkReferralCodeState}
        finishLoading={finishLoading}
        handleFinish={handleFinish}
        qrcode={qrCodeUrl}
        exchangeDetails={exchangeDetails}
        createChargeLoading={createChargeLoading}
        handleCancelPayment={handleCancelPayment}
      />
      {referralCodeAlreadyUsed && (
        <Popup
          popUpElement={
            <div className="confirm-list">
              <p>
                Are you ready to finalize your registration for the specified
                placement?
              </p>
              <Button
                element={"button"}
                size={"btn-lg"}
                type={"btn-primary"}
                label={"Confirm”"}
                active={true}
                customStyles={{
                  width: "100%",
                }}
                onClick={() => {
                  handleAutomaticReferral();
                }}
              />
            </div>
          }
          label={"Referral code is already used"}
          handlePopUpClose={() => setReferralCodeAlreadyUsed(false)}
        />
      )}
    </>
  );
};

export default LandingRegistration;
