import { readContracts } from "wagmi/actions";

import { decryptEnv } from "./decryptEnv";
import { config } from "./wagmiConfig";
import stackAbi from "../abi/stack.json";

const contractAddress = decryptEnv(
  process.env.REACT_APP_STAKING_CONTRACT_ADDRESS
);

export const getTiers = async () => {
    let results = [];

    const contract = {
        address: contractAddress,
        abi: stackAbi,
    };

    const tiers = await readContracts(config, {
        contracts: [
        {
            ...contract,
            functionName: "tInfo",
            args: [0],
        },
        {
            ...contract,
            functionName: "tInfo",
            args: [1],
        },
        {
            ...contract,
            functionName: "tInfo",
            args: [2],
        },
        {
            ...contract,
            functionName: "tInfo",
            args: [3],
        },
        {
            ...contract,
            functionName: "tInfo",
            args: [4],
        },
        {
            ...contract,
            functionName: "tInfo",
            args: [5],
        },
        {
            ...contract,
            functionName: "tInfo",
            args: [6],
        },
        ],
    });

    const bonusData0 = await readContracts(config, {
        contracts: [
        {
            ...contract,
            functionName: "tierBonuses",
            args: [1, 0],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [1, 1],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [1, 2],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [1, 3],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [1, 4],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [1, 5],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [1, 6],
        },
        ],
    });

    const bonusData1 = await readContracts(config, {
        contracts: [
        {
            ...contract,
            functionName: "tierBonuses",
            args: [2, 0],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [2, 1],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [2, 2],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [2, 3],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [2, 4],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [2, 5],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [2, 6],
        },
        ],
    });

    const bonusData2 = await readContracts(config, {
        contracts: [
        {
            ...contract,
            functionName: "tierBonuses",
            args: [3, 0],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [3, 1],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [3, 2],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [3, 3],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [3, 4],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [3, 5],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [3, 6],
        },
        ],
    });

    const bonusData3 = await readContracts(config, {
        contracts: [
        {
            ...contract,
            functionName: "tierBonuses",
            args: [4, 0],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [4, 1],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [4, 2],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [4, 3],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [4, 4],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [4, 5],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [4, 6],
        },
        ],
    });

    const bonusData4 = await readContracts(config, {
        contracts: [
        {
            ...contract,
            functionName: "tierBonuses",
            args: [5, 0],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [5, 1],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [5, 2],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [5, 3],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [5, 4],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [5, 5],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [5, 6],
        },
        ],
    });

    const bonusData5 = await readContracts(config, {
        contracts: [
        {
            ...contract,
            functionName: "tierBonuses",
            args: [6, 0],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [6, 1],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [6, 2],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [6, 3],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [6, 4],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [6, 5],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [6, 6],
        },
        ],
    });

    const bonusData6 = await readContracts(config, {
        contracts: [
        {
            ...contract,
            functionName: "tierBonuses",
            args: [7, 0],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [7, 1],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [7, 2],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [7, 3],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [7, 4],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [7, 5],
        },
        {
            ...contract,
            functionName: "tierBonuses",
            args: [7, 6],
        },
        ],
    });

    if (tiers && bonusData0 && bonusData1 && bonusData2 && bonusData3 && bonusData4 && bonusData5 && bonusData6) {
        for (let i = 0; i < tiers.length; i++) {
            let tier = tiers[i];
            
            if (tier.result) {
                let minStakeAmt = tier.result[0].toString();
                let maxStakeAmt = tier.result[1].toString();
                let tierId = tier.result[2].toString();
                let convertedBonus = [];
    
                for (let j = 0; j < bonusData0.length; j++) {
                    if (bonusData0.length > 0 && i === 0) {
                        let bonus = {
                            tierId: bonusData0[j].result[0].toString(),
                            durationId: bonusData0[j].result[1].toString(),
                            bonus: bonusData0[j].result[2].toString(),
                        };
            
                        convertedBonus.push(bonus);
                    }
        
                    if (bonusData1.length > 0 && i === 1) {
                        let bonus = {
                            tierId: bonusData1[j].result[0].toString(),
                            durationId: bonusData1[j].result[1].toString(),
                            bonus: bonusData1[j].result[2].toString(),
                        };
            
                        convertedBonus.push(bonus);
                    }
        
                    if (bonusData2.length > 0 && i === 2) {
                        let bonus = {
                            tierId: bonusData2[j].result[0].toString(),
                            durationId: bonusData2[j].result[1].toString(),
                            bonus: bonusData2[j].result[2].toString(),
                        };
            
                        convertedBonus.push(bonus);
                    }
        
                    if (bonusData3.length > 0 && i === 3) {
                        let bonus = {
                            tierId: bonusData3[j].result[0].toString(),
                            durationId: bonusData3[j].result[1].toString(),
                            bonus: bonusData3[j].result[2].toString(),
                        };
                        
                        convertedBonus.push(bonus);
                    }
        
                    if (bonusData4.length > 0 && i === 4) {
                        let bonus = {
                            tierId: bonusData4[j].result[0].toString(),
                            durationId: bonusData4[j].result[1].toString(),
                            bonus: bonusData4[j].result[2].toString(),
                        };
            
                        convertedBonus.push(bonus);
                    }

                    if (bonusData5.length > 0 && i === 5) {
                        let bonus = {
                            tierId: bonusData5[j].result[0].toString(),
                            durationId: bonusData5[j].result[1].toString(),
                            bonus: bonusData5[j].result[2].toString(),
                        };
            
                        convertedBonus.push(bonus);
                    }

                    if (bonusData6.length > 0 && i === 6) {
                        let bonus = {
                            tierId: bonusData6[j].result[0].toString(),
                            durationId: bonusData6[j].result[1].toString(),
                            bonus: bonusData6[j].result[2].toString(),
                        };
            
                        convertedBonus.push(bonus);
                    }
                }

                results.push({
                    minStakeAmt,
                    maxStakeAmt,
                    tierId,
                    bonuses: convertedBonus,
                });
            }
        }

        return results;
    } 
};
