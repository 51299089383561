import React from "react";

const UsdtLogo = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      d="M16 1C24.56 1 31.4999 7.93997 31.4999 16.5C31.4999 25.06 24.5596 31.9999 16 31.9999C7.44024 31.9999 0.5 25.0618 0.5 16.5C0.5 7.93807 7.43899 1 16 1Z"
      fill="url(#paint0_linear_40_8913)"
      stroke="url(#paint1_linear_40_8913)"
    />
    <path
      d="M14.5735 15.5369V13.5411H10.0094V10.5H22.4376V13.5411H17.873V15.5353C21.5827 15.7057 24.3721 16.4404 24.3721 17.3206C24.3721 18.2007 21.5814 18.9354 17.873 19.1069V25.5H14.5724V19.1064C10.8694 18.9354 8.08566 18.2013 8.08566 17.3219C8.08566 16.4426 10.8694 15.7084 14.5724 15.5375M14.5724 18.5648V18.5632C14.6655 18.5691 15.144 18.5979 16.2093 18.5979C17.061 18.5979 17.6602 18.5737 17.8714 18.5627V18.5654C21.1488 18.4202 23.5952 17.8495 23.5952 17.1665C23.5952 16.4836 21.1485 15.9137 17.8714 15.7682V17.9968C17.6567 18.0115 17.0427 18.0478 16.1953 18.0478C15.1775 18.0478 14.6658 18.0054 14.5719 17.9968V15.7682C11.3009 15.914 8.86008 16.4852 8.86008 17.1657C8.86008 17.8462 11.3019 18.4178 14.5719 18.5635"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_40_8913"
        x1="0.36923"
        y1="2.12319"
        x2="33.9011"
        y2="4.39118"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.1" />
        <stop offset="1" stopColor="white" stopOpacity="0.02" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_40_8913"
        x1="6.95383"
        y1="0.499999"
        x2="24.2794"
        y2="14.2938"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="white" stopOpacity="0.05" />
      </linearGradient>
    </defs>
  </svg>
  );
};

export default UsdtLogo;
