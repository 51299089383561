import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../UI/table/Table";
import { Button } from "../UI/button/Button";

import { useMobileWidth } from "../../hooks/useMobileWidth";
import translates from "../../translates.json";

import Favourites from "../../assets/svg/Favourites";
import Signals from "../../assets/svg/Signals";
import InfoCircleIcon from "../../assets/svg/InfoCircle";

const TradeModule = () => {
  let header = [
    {
      name: "Symbol",
      mobileWidth: 25,
      width: 10,
      id: 0,
      height: "40px",
    },
    {
      name: "Sell",
      mobileWidth: 25,
      width: 10,
      id: 1,
      height: "40px",
    },
    {
      name: "Signal",
      mobileWidth: 25,
      width: 10,
      id: 2,
      height: "40px",
    },
    {
      name: "",
      mobileWidth: 25,
      width: 10,
      id: 3,
      height: "40px",
    },
    {
      name: "",
      mobileWidth: 25,
      width: 10,
      id: 4,
      height: "40px",
    },
    {
      name: "Buy",
      mobileWidth: 25,
      width: 10,
      id: 5,
      height: "40px",
    },
    {
      name: "Charge, %",
      mobileWidth: 25,
      width: 10,
      id: 6,
      height: "40px",
    },
    {
      name: "",
      mobileWidth: 25,
      width: 10,
      id: 7,
      height: "40px",
    },
    {
      name: "",
      mobileWidth: 25,
      width: 10,
      id: 8,
      height: "40px",
    },
  ];

  const [mobileExpand, setMobileExpand] = useState(null);
  const [transactionHeader, setTransactionHeader] = useState(header);

  const tradeData = useSelector((state) => state.trade.tradeData);

  const { width } = useMobileWidth();
  let mobile = width <= 1200;
  const dispatch = useDispatch();

  const handleSignal = (id) => {
    console.log(id);
  };

  const handleSellBuy = (symbol) => {
    console.log(symbol);
  };

  const handleFavourites = (id, status) => {
    dispatch({
      type: "SET_TRADE_DATA",
      payload: {
        _id: id,
        favourites: !status,
      },
    });
  };

  let mobileExpandFunc = (id) => {
    if (width <= 1200) {
      if (id !== mobileExpand) {
        setMobileExpand(id);
      } else {
        setMobileExpand(null);
      }
    }
  };

  useEffect(() => {
    if (mobile) {
      let header = [
        {
          name: "Symbol",
          mobileWidth: 25,
          width: 10,
          id: 0,
          height: "40px",
        },
        {
          name: "Sell",
          mobileWidth: 25,
          width: 10,
          id: 1,
          height: "40px",
        },
        {
          name: "Buy",
          mobileWidth: 25,
          width: 10,
          id: 5,
          height: "40px",
        },
        {
          name: "Charge, %",
          mobileWidth: 25,
          width: 10,
          id: 6,
          height: "40px",
        },
      ];

      setTransactionHeader(header);
    } else {
      let header = [
        {
          name: "Symbol",
          mobileWidth: 25,
          width: 10,
          id: 0,
          height: "40px",
        },
        {
          name: "Sell",
          mobileWidth: 25,
          width: 10,
          id: 1,
          height: "40px",
        },
        {
          name: "Signal",
          mobileWidth: 25,
          width: 10,
          id: 2,
          height: "40px",
        },
        {
          name: "",
          mobileWidth: 25,
          width: 10,
          id: 3,
          height: "40px",
        },
        {
          name: "",
          mobileWidth: 25,
          width: 10,
          id: 4,
          height: "40px",
        },
        {
          name: "Buy",
          mobileWidth: 25,
          width: 10,
          id: 5,
          height: "40px",
        },
        {
          name: "Charge, %",
          mobileWidth: 25,
          width: 10,
          id: 6,
          height: "40px",
        },
        {
          name: "",
          mobileWidth: 25,
          width: 10,
          id: 7,
          height: "40px",
        },
        {
          name: "",
          mobileWidth: 25,
          width: 10,
          id: 8,
          height: "40px",
        },
      ];

      setTransactionHeader(header);
    }
  }, [mobile]);

  const transactionsTableEmpty = {
    label: "No data",
    icon: <InfoCircleIcon />,
  };

  let tableData = tradeData?.map((item, index) => {
    let symbol = item?.symbol.toUpperCase();
    let sell = item?.sell;
    let sellRise = item?.sellRise;
    let buy = item?.buy;
    let buyRise = item?.buyRise;
    let charge = item?.charge;
    let chargeRise = item?.chargeRise;
    let favourites = item?.favourites;
    let expandBtnStyle = { height: "40px" };

    if (!mobile) expandBtnStyle = { display: "none" };

    return (
      <div
        className={`table-parent ${
          mobileExpand == item._id ? "active" : ""
        } dashboard-table-parent`}
        key={index}
      >
        <div className="table">
          <div
            className={`td col dashboard-td`}
            style={{
              width: mobile ? "25%" : "10%",
            }}
          >
            <span>{symbol}</span>
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              color: sellRise
                ? "rgba(103, 190, 122, 0.6)"
                : "rgba(232, 116, 80, 0.6)",
              width: mobile ? "25%" : "10%",
            }}
          >
            {sell}
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              display: mobile ? "none" : "flex",
              width: mobile ? "25%" : "10%",
            }}
          >
            <span
              onClick={() => handleSignal(item?._id)}
              style={{ width: "24px", height: "24px" }}
            >
              <Signals color="#c38c5c" />
            </span>
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              display: mobile ? "none" : "flex",
              width: mobile ? "25%" : "10%",
            }}
          >
            <Button
              element={"button"}
              size={"btn-lg"}
              type={"btn-secondary"}
              label={"Sell"}
              active={true}
              customStyles={{
                height: "30px",
              }}
              onClick={() => {
                dispatch({
                  type: "SET_SIDE_BAR",
                  payload: {
                    sideBarOpen: true,
                    sideBar: "trade",
                    type: "sell",
                  },
                });
              }}
            />
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              display: mobile ? "none" : "flex",
              width: mobile ? "25%" : "10%",
            }}
          >
            <Button
              element={"button"}
              size={"btn-lg"}
              type={"btn-secondary"}
              label={"Buy"}
              active={true}
              customStyles={{
                height: "30px",
              }}
              onClick={() => {
                dispatch({
                  type: "SET_SIDE_BAR",
                  payload: {
                    sideBarOpen: true,
                    sideBar: "trade",
                    type: "buy",
                  },
                });
              }}
            />
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              color: buyRise
                ? "rgba(103, 190, 122, 0.6)"
                : "rgba(232, 116, 80, 0.6)",
              width: mobile ? "25%" : "10%",
            }}
          >
            {buy}
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              color: chargeRise
                ? "rgba(103, 190, 122, 0.6)"
                : "rgba(232, 116, 80, 0.6)",
              width: mobile ? "25%" : "10%",
            }}
          >
            {charge}
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              display: mobile ? "none" : "flex",
              width: mobile ? "25%" : "10%",
            }}
          >
            <span
              onClick={() => handleSellBuy(symbol)}
              style={{ width: "28px", height: "28px" }}
            >
              <InfoCircleIcon color="#c38c5c" width="100%" height="100%" />
            </span>
          </div>
          <div
            className={`td dashboard-td`}
            style={{
              display: mobile ? "none" : "flex",
              width: mobile ? "25%" : "10%",
            }}
          >
            <span
              style={{
                width: "24px",
                height: "24px",
              }}
              onClick={() => handleFavourites(item._id, favourites)}
            >
              <Favourites
                favourites={favourites}
                color="#c38c5c"
                width="100%"
                height="100%"
              />
            </span>
          </div>
        </div>
        <div
          className="icon-place"
          style={expandBtnStyle}
          onClick={mobile ? () => mobileExpandFunc(item._id) : null}
        >
          <svg
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.299 1.33325L6.47141 5.16089C6.01937 5.61293 5.27968 5.61293 4.82764 5.16089L1 1.33325"
              stroke="white"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="table-mobile">
          <div
            className="table-mobile-content"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className={`td dashboard-td`}
              style={{
                width: "25%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                onClick={() => handleSignal(item?._id)}
                style={{ width: "24px", height: "24px" }}
              >
                <Signals color="#c38c5c" />
              </span>
            </div>
            <div
              className={`td dashboard-td`}
              style={{
                width: "25%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                element={"button"}
                size={"btn-lg"}
                type={"btn-secondary"}
                label={"Sell"}
                active={true}
                customStyles={{
                  height: "30px",
                }}
                onClick={() => {
                  dispatch({
                    type: "SET_SIDE_BAR",
                    payload: {
                      sideBarOpen: true,
                      sideBar: "trade",
                      type: "sell",
                    },
                  });
                }}
              />
            </div>
            <div
              className={`td dashboard-td`}
              style={{
                width: "25%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                element={"button"}
                size={"btn-lg"}
                type={"btn-secondary"}
                label={"Buy"}
                active={true}
                customStyles={{
                  height: "30px",
                }}
                onClick={() => {
                  dispatch({
                    type: "SET_SIDE_BAR",
                    payload: {
                      sideBarOpen: true,
                      sideBar: "trade",
                      type: "buy",
                    },
                  });
                }}
              />
            </div>
            <div
              className={`td dashboard-td`}
              style={{
                width: "25%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                onClick={() => handleSellBuy(symbol)}
                style={{ width: "28px", height: "28px" }}
              >
                <InfoCircleIcon color="#c38c5c" width="100%" height="100%" />
              </span>
            </div>
            <div
              className={`td dashboard-td`}
              style={{
                width: "25%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  width: "24px",
                  height: "24px",
                }}
                onClick={() => handleFavourites(item._id, favourites)}
              >
                <Favourites
                  favourites={favourites}
                  color="#c38c5c"
                  width="100%"
                  height="100%"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <Table
      tableData={tableData}
      tableFooter={null}
      tableHead={transactionHeader}
      customStyles={{
        border: "1px solid rgba(255, 255, 255, 0.1)",
        borderRadius: "20px",
        background: "rgba(255, 255, 255, 0.01)",
        backdropFilter: "blur(5px)",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
      }}
      customHeadStyles={{
        height: "81px",
        alignItems: "center",
      }}
      tableEmptyData={transactionsTableEmpty}
      tableEmpty={transactionsTableEmpty}
      translates={translates}
      // loading={loading}
    />
  );
};

export default TradeModule;
