export function formatNumber(number) {
  const parts = number?.toString()?.split(".");

  if (parts?.length === 2) {
    const decimalPart = parts[1]?.substring(0, 4); // Get up to four decimal places
    return `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${decimalPart}`;
  } else if (typeof number === "number") {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "";
  }
}
