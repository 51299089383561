import React, { useState, useEffect } from "react";
import { TopUpDashboard } from "./TopUpDashboard";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import QRCode from "qrcode";
import { socket } from "../../api/socket";
import { methods, rpcs } from "../../dummyComponents/topUp";
import { useConnectMeta } from "../../hooks/use-connect";

import axios from "../../api/axios";

const TopUp = ({ translates }) => {
  const [disableCancel, setDisableCancel] = useState(false);
  // Transaction fee should be converted from usd to aone
  const [transactionFee] = useState(1);
  const [active, setActive] = useState(false);
  const [closePopup, setClosePopup] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [createChargeLoading, setCreateChargeLoading] = useState(false);
  const { account } = useConnectMeta();
  const dispatch = useDispatch();

  const rates = useSelector((state) => state.appState.rates);
  const exchangeDetails = useSelector((state) => state.connect.exchangeDetails);
  const transactionStatus = useSelector(
    (state) => state.connect.transactionStatus
  );

  async function handleCreateCharge(token, rpc, amount, amountUSD) {
    try {
      const foundToken = methods.find((method) => method.title === token);
      const foundRPC = rpcs?.find((item) => item.id === rpc);

      let isNative = false;

      if (
        token === "ETH" ||
        (token === "BNB" && foundRPC.id === "BSC") ||
        (token === "BNB" && foundRPC.id === "BSC Testnet")
      ) {
        isNative = true;
      }

      let tokenAddress;

      if (!isNative) {
        tokenAddress = foundToken?.ethereumAddress;
      }

      setCreateChargeLoading(true);

      const { data } = await axios.post(
        "/api/transactions/create_exchange_transaction",
        {
          address: account,
          rpc1: foundRPC?.rpc1,
          rpc2: foundRPC?.rpc2,
          tokenAddress: tokenAddress,
          amount,
          decimals: 18,
          isNative,
          transactionFee,
          rates,
          type: "topUp",
        }
      );

      setCreateChargeLoading(false);
      setDisableCancel(true);
      dispatch({
        type: "SET_EXCHANGE_DETAILS",
        payload: {
          exchangeId: data?.data?.exchangeId,
          address: data?.data?.address,
          amount,
          amountUSD,
        },
      });
    } catch (e) {
      toast.error(e?.response?.data?.message ?? "Something went wrong!", {
        autoClose: false,
      });
      setTimeout(() => {
        setCreateChargeLoading(false);
      }, 500);
    }
  }

  const cancel_exchange = async () => {
    try {
      await axios
        .post("/api/transactions/cancel_exchange", {
          exchangeId: exchangeDetails?.exchangeId,
          address: exchangeDetails?.address,
        })
        .then((res) => {
          let status = res?.data;

          if (status.success) {
            dispatch({
              type: "SET_EXCHANGE_DETAILS",
              payload: {},
            });

            dispatch({
              type: "SET_TRANSACTION_STATUS",
              payload: "canceled",
            });

            setActive(true);

            setTimeout(() => {
              setActive(false);
            }, 2000);
          }
        });
    } catch (e) {
      toast.error(e?.response?.data?.message ?? "Something went wrong!", {
        autoClose: false,
      });
    }
  };

  useEffect(() => {
    if (exchangeDetails.address) {
      QRCode.toDataURL(exchangeDetails.address)
        .then((url) => {
          setQrCodeUrl(url);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [exchangeDetails?.address]);

  useEffect(() => {
    if (exchangeDetails?.exchangeId) {
      socket.emit("joinExchangeRoom", exchangeDetails?.exchangeId);
    }

    const handleExchangeStatus = (data) => {
      dispatch({
        type: "SET_TRANSACTION_STATUS",
        payload: data?.status,
      });

      if (transactionStatus === "pending") {
        setDisableCancel(true);
      } else if (transactionStatus !== "") {
        setDisableCancel(false);
        setActive(true);
        setClosePopup(true);

        dispatch({
          type: "SET_EXCHANGE_DETAILS",
          payload: {},
        });

        setTimeout(() => {
          setActive(false);
        }, 2000);

        setTimeout(() => {
          dispatch({
            type: "SET_TRANSACTION_STATUS",
            payload: "",
          });
        }, 5000);
      }
    };

    socket.on("exchange_status", handleExchangeStatus);

    return () => {
      socket.off("exchange_status", handleExchangeStatus);
    };
  }, [exchangeDetails, transactionStatus, socket]);

  return (
    <TopUpDashboard
      disableCancel={disableCancel}
      handlePurchaseEvent={handleCreateCharge}
      exchangeRate={rates?.["atr"]?.usd}
      tranasctionFee={transactionFee}
      methods={methods}
      rpcs={rpcs}
      rates={rates}
      exchangeDetails={exchangeDetails}
      createChargeLoading={createChargeLoading}
      qrcode={qrCodeUrl}
      translates={translates}
      cancel_exchange={cancel_exchange}
      setExchangeDetails={() => setClosePopup(true)}
      transactionStatus={transactionStatus}
      active={active}
      closePopup={closePopup}
    />
  );
};

export default TopUp;
