import React, { useState, useEffect, useRef } from "react";

import { useMobileWidth } from "../../../hooks/useMobileWidth";

import { NavbarHelper } from "./navbar/NavbarHelper";
import { Button } from "../../UI/button/Button";
import { TradeBar } from "../../UI/tradeBar/TradeBar";

import Menu from "../../../assets/svg/Menu";
import AoneLogoSvg from "../../../assets/svg/AoneLogoSvg";

import "./Header.css";

export const Header = ({
  isSigned,
  modules,
  account,
  sideBar,
  sideBarOpen,
  handleConnect,
  handleNotifications,
  title,
  logoSvg,
  verified,
  amount,
  onLogoClick,
  initialRegister,
  setInitialRegister,
  loginWithEmail,
  loggedWithEmail,
  showSignIn,
  A1Price,
  tradePriceData,
  handleShowBalance,
  showBalance,
  setShowBalance,
  location,
  handleDeposit,
  handleWithdraw,
  handleTransfer,
}) => {
  const [navbarActive, setNavbarActive] = useState(false);
  const [animate, setAnimate] = useState(false);
  const { width } = useMobileWidth();

  const headerRef = useRef(null);

  useEffect(() => {
    setAnimate(true);

    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        setShowBalance(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowBalance]);

  let mobile = width <= 970;

  return (
    <div
      ref={headerRef}
      className={`header ${mobile && navbarActive ? "header-active" : ""} ${
        animate ? "animate" : ""
      }`}
    >
      <div className="modulesWrapper">
        <div className="logoWrapper" onClick={onLogoClick}>
          {logoSvg}
          <h3>{title}</h3>
        </div>
        {!mobile && (
          <NavbarHelper
            type={"navbar"}
            modules={modules}
            handleDeposit={handleDeposit}
            handleWithdraw={handleWithdraw}
            handleTransfer={handleTransfer}
            isSigned={isSigned}
          />
        )}
        <div className="a1-price-and-mask">
          {location.pathname === "/trade" && !mobile ? (
            <>
              <TradeBar
                tradePriceData={tradePriceData}
                showBalance={showBalance}
                setShowBalance={setShowBalance}
              />
              <div
                onClick={() => handleShowBalance()}
                className="tabBarIcon"
              ></div>
            </>
          ) : null}
          <div className="a1-price">
            <AoneLogoSvg />
            AONE Price: {A1Price?.toFixed(2)} $
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {mobile && (
            <NavbarHelper
              type={"notification"}
              onClick={handleNotifications}
              sideBar={sideBar}
              sideBarOpen={sideBarOpen}
              modules={modules}
              handleDeposit={handleDeposit}
              handleWithdraw={handleWithdraw}
              handleTransfer={handleTransfer}
              isSigned={isSigned}
            />
          )}
          {mobile && (
            <NavbarHelper
              type={"connect"}
              onClick={handleConnect}
              account={account}
              verified={verified}
              initialRegister={initialRegister}
              setInitialRegister={setInitialRegister}
              loginWithEmail={loginWithEmail}
              loggedWithEmail={loggedWithEmail}
              width={width}
              isSigned={isSigned}
            />
          )}
          {mobile && (
            <div
              className={`navbar-menu`}
              onClick={() => setNavbarActive((prev) => !prev)}
            >
              <p className={`${navbarActive ? "active" : ""} font-12`}>
                <span className="burgerCloseButtonText">Close</span>
              </p>
              <Menu active={navbarActive} />
            </div>
          )}
        </div>
      </div>
      <div className={`right ${navbarActive ? "right-active" : ""}`}>
        {!mobile && (
          <NavbarHelper
            type={"notification"}
            onClick={handleNotifications}
            sideBar={sideBar}
            sideBarOpen={sideBarOpen}
            modules={modules}
            isSigned={isSigned}
          />
        )}
        {!mobile && (
          <NavbarHelper
            type={"connect"}
            onClick={handleConnect}
            account={account}
            verified={verified}
            initialRegister={initialRegister}
            setInitialRegister={setInitialRegister}
            loginWithEmail={loginWithEmail}
            loggedWithEmail={loggedWithEmail}
            isSigned={isSigned}
          />
        )}
        {mobile && (
          <NavbarHelper type={"navbar"} modules={modules} isSigned={isSigned} />
        )}
        {mobile && (
          <Button
            label={"Become Elite Member"}
            status={"warning"}
            element={"help-button"}
            icon={false}
            onClick={() => console.log()}
            customStyles={{ width: "100%" }}
          />
        )}
      </div>
    </div>
  );
};
