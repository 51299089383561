import React from "react";

const LogoSvg = () => {
  return (
    <svg
      width="61"
      height="62"
      viewBox="0 0 61 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.0358 6.91431L0.497925 53.3785H7.84852H14.7808H26.4939L29.3027 43.488H24.1633L48.8744 17.3426L38.8346 53.3785H49.3226L60.4979 6.91431H46.0358Z"
        fill="white"
      ></path>
    </svg>
  );
};

export default LogoSvg;
