import React from "react";

const Account = ({ className, type, ...props }) => {
  let element = null;

  if (type === "atar") {
    return (element = (
      <svg
        width="34"
        height="34"
        viewBox="0 0 61 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        {...props}
      >
        <path
          d="M46.0358 6.91431L0.497925 53.3785H7.84852H14.7808H26.4939L29.3027 43.488H24.1633L48.8744 17.3426L38.8346 53.3785H49.3226L60.4979 6.91431H46.0358Z"
          fill="white"
        />
      </svg>
    ));
  }

  if (type === "btc") {
    return (element = (
      <svg
        className={className}
        {...props}
        width="34"
        height="36"
        viewBox="0 0 34 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.9349 14.6869C30.4628 11.0751 27.7123 9.13038 23.9478 7.82461L25.1702 2.9349L22.1975 2.18478L21.0028 6.90779C20.2249 6.69942 19.4192 6.51883 18.6136 6.33825L19.8082 1.53189L16.8355 0.795654L15.613 5.68536L13.7238 5.24084L9.55648 4.199L8.80636 7.38009C8.80636 7.38009 11.0151 7.89406 10.9734 7.92184C11.3801 7.98064 11.7485 8.19389 12.002 8.51727C12.2555 8.84064 12.3747 9.24928 12.3347 9.65825L10.9456 15.2147C11.0512 15.2339 11.1539 15.2666 11.2512 15.312L10.9456 15.2564L9.01473 23.0216C8.97063 23.1597 8.8996 23.2877 8.80574 23.3982C8.71188 23.5088 8.59705 23.5996 8.46788 23.6655C8.33872 23.7313 8.19778 23.771 8.05321 23.782C7.90864 23.7931 7.7633 23.7755 7.62561 23.73L5.45858 23.1883L4 26.6611L7.87565 27.6196L10.001 28.1752L8.76468 33.1205L11.7513 33.8706L12.9737 28.967C13.7794 29.1893 14.5712 29.3977 15.3491 29.5782L14.1267 34.4679L17.1133 35.2042L18.3496 30.2589C23.4338 31.2174 27.2539 30.8423 28.8653 26.2305C30.1571 22.5354 28.8653 20.3962 26.087 19.007C27.1135 18.8088 28.0463 18.2785 28.7417 17.4978C29.437 16.7171 29.8563 15.7294 29.9349 14.6869ZM23.1143 24.2301C22.1975 27.9391 15.9603 25.9387 13.9461 25.4387L15.5714 18.8542C17.5995 19.3265 24.0728 20.3267 23.1143 24.1746V24.2301ZM24.045 14.6313C23.1977 17.993 18.0162 16.2844 16.3215 15.8676L17.8079 9.86661C19.4887 10.2834 24.9202 11.0613 24.045 14.5757V14.6313Z"
          fill="white"
        />
      </svg>
    ));
  }

  if (type === "bnb") {
    return (element = (
      <svg
        className={className}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
      >
        <path
          d="M17 34C7.61069 34 0 26.3893 0 17C0 7.61069 7.61069 0 17 0C26.3893 0 34 7.61069 34 17C34 26.3893 26.3893 34 17 34ZM12.8733 15.3043L17 11.1775L21.1289 15.3064L23.5301 12.9051L17 6.375L10.472 12.903L12.8733 15.3043ZM6.375 17L8.77625 19.4013L11.1775 17L8.77625 14.5987L6.375 17ZM12.8733 18.6958L10.4688 21.0938L10.472 21.097L17 27.625L23.5301 21.0949L21.1289 18.6926L17 22.8225L12.8733 18.6958ZM22.8225 17L25.2237 19.4013L27.625 17L25.2237 14.5987L22.8225 17ZM19.4352 16.9979L17 14.5626L15.1991 16.3625L14.9919 16.5697L14.5658 16.9968L14.5616 17L14.5658 17.0032L16.9989 19.4374L19.4363 17.0011L19.4374 17L19.4352 16.9979Z"
          fill="#ffffff"
        />
      </svg>
    ));
  }

  if (type === "usdt") {
    return (element = (
      <svg
        className={className}
        {...props}
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 17.0001C0 7.61162 7.61161 0 17.0001 0C26.3896 0 34.0002 7.60958 34.0002 17.0001C34.0002 26.3906 26.3882 34.0002 17.0001 34.0002C7.61195 34.0002 0 26.3885 0 17.0001ZM14.902 12.2063V14.7352L14.9007 14.7359C10.2087 14.9525 6.68149 15.8827 6.68149 16.9969C6.68149 18.1111 10.2087 19.0413 14.9007 19.2579V27.3591H19.0827V19.2586C23.7815 19.0413 27.3175 18.1104 27.3175 16.9952C27.3175 15.88 23.7832 14.9491 19.0827 14.7332V12.2063H24.8665V8.35303H9.11896V12.2063H14.902ZM14.9007 18.5697V18.5718L14.9 18.5701C10.7567 18.3855 7.66273 17.6613 7.66273 16.799C7.66273 15.9368 10.7554 15.2129 14.9 15.0283V17.852C15.019 17.8629 15.6674 17.9166 16.957 17.9166C18.0307 17.9166 18.8087 17.8707 19.0807 17.852V15.0283C23.2331 15.2126 26.3332 15.9347 26.3332 16.8C26.3332 17.6653 23.2334 18.3885 19.0807 18.5725V18.5691C18.8131 18.583 18.0539 18.6136 16.9747 18.6136C15.6249 18.6136 15.0187 18.5772 14.9007 18.5697Z"
          fill="white"
        />
      </svg>
    ));
  }

  if (type === "eth") {
    return (element = (
      <svg
        className={className}
        {...props}
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.998 1V12.8288L26.9959 17.2963L16.998 1Z"
          fill="white"
          fillOpacity="0.6"
        />
        <path d="M16.9972 1L6.99805 17.2963L16.9972 12.8288V1Z" fill="white" />
        <path
          d="M16.998 24.9627V33.0001L27.0025 19.1589L16.998 24.9627Z"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M16.9972 33.0001V24.9613L6.99805 19.1589L16.9972 33.0001Z"
          fill="white"
        />
        <path
          d="M16.998 23.1009L26.9959 17.2959L16.998 12.8311V23.1009Z"
          fill="white"
          fillOpacity="0.4"
        />
        <path
          d="M6.99805 17.2959L16.9972 23.1009V12.8311L6.99805 17.2959Z"
          fill="white"
          fillOpacity="0.6"
        />
      </svg>
    ));
  }

  if (type === "gold") {
    return (element = (
      <svg
        className={className}
        {...props}
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34ZM10 16.9765C10 18.282 10.2919 19.4714 10.8756 20.5447C11.4593 21.6117 12.2784 22.456 13.3329 23.0773C14.3874 23.6924 15.5705 24 16.8823 24C17.6669 24 18.4169 23.8525 19.1325 23.5575C19.848 23.2562 20.4725 22.8106 21.0061 22.2206V23.7458H23.5104V16.2609H16.5151V18.5017H21.0061C20.9433 18.9097 20.8146 19.2988 20.62 19.6691C20.4317 20.0332 20.1807 20.3658 19.8668 20.6671C19.553 20.9621 19.1576 21.2006 18.6806 21.3826C18.2035 21.5584 17.6794 21.6463 17.1083 21.6463C16.4932 21.6463 15.9126 21.5333 15.3665 21.3073C14.8204 21.0814 14.3466 20.7675 13.9449 20.3658C13.5432 19.9641 13.223 19.4714 12.9845 18.8877C12.7523 18.2977 12.6362 17.6606 12.6362 16.9765C12.6362 16.286 12.7523 15.649 12.9845 15.0652C13.223 14.4815 13.54 13.9951 13.9354 13.6059C14.3371 13.2168 14.8016 12.9155 15.3288 12.7021C15.8624 12.4824 16.4241 12.3726 17.0141 12.3726C17.8175 12.3726 18.5676 12.5546 19.2643 12.9186C19.961 13.2827 20.529 13.7754 20.9684 14.3968L22.9926 12.9092C22.3022 12.0054 21.4486 11.2961 20.4317 10.7814C19.4149 10.2605 18.2883 10 17.0518 10C15.7274 10 14.5254 10.3044 13.4459 10.9132C12.3726 11.5158 11.5284 12.3506 10.9132 13.4176C10.3044 14.4846 10 15.6709 10 16.9765Z"
          fill="white"
        />
      </svg>
    ));
  }

  if (type === "trx") {
    return (element = (
      <svg
        className={className}
        {...props}
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34ZM25.3625 11.7973C25.8471 12.2448 26.3117 12.6737 26.7127 13.0273C26.8152 13.1154 26.904 13.2307 26.9519 13.3527C26.9997 13.4747 27.0134 13.6036 26.9861 13.7324C26.8904 13.8951 26.781 14.051 26.658 14.1934C25.6942 15.3595 16.1728 26.3288 16.1728 26.3288C16.1728 26.3288 15.6397 27 15.5166 27H15.462C15.2337 26.98 15.111 26.6265 15.0419 26.4277L15.0382 26.417C14.5386 25.11 13.1104 21.862 11.5728 18.365C9.74437 14.2067 7.76112 9.69625 7 7.6782V7.64431V7.42058L7.01367 7.3799C7.04784 7.27821 7.10936 7.19007 7.18455 7.1155L7.24606 7.06804C7.30075 7.03414 7.3691 7.00703 7.43745 7.00025C7.65719 6.97534 18.194 8.84376 21.9857 9.51611C22.322 9.57574 22.6052 9.62596 22.8234 9.66462C22.9533 9.71208 23.0763 9.77987 23.1857 9.86123L23.2541 9.90191C23.9102 10.4563 24.6576 11.1464 25.3625 11.7973ZM22.6662 11.1087L18.6061 14.3086L25.0517 13.1968L22.6662 11.1087ZM10.0348 8.64768L17.0272 14.0985L21.3539 10.6341L10.0348 8.64768ZM24.8125 14.4239L17.656 15.5222L16.6308 23.8L24.8125 14.4239ZM16.4462 15.129L9.08473 9.19683L15.3799 23.8068L16.4462 15.129Z"
          fill="white"
        />
      </svg>
    ));
  }

  return element;
};

export default Account;
