import { NoHistoryIcon } from "../assets/svg";

export const transactionHeader = [
  {
    name: "Hash",
    width: 20,
    id: 0,
    height: "40px",
  },
  {
    name: "From",
    width: 20,
    id: 1,
    height: "40px",
  },
  {
    name: "to",
    width: 20,
    id: 2,
    height: "40px",
  },
  {
    name: "Type",
    width: 10,
    mobileWidth: 50,
    id: 3,
    height: "40px",
  },
  {
    name: "Amount",
    width: 10,
    mobileWidth: 50,
    id: 4,
    height: "40px",
  },
  {
    name: "Time",
    width: 10,
    id: 5,
    height: "40px",
  },
  {
    name: "Status",
    width: 10,
    id: 6,
    height: "40px",
  },
];

export const referralRebatesTableEmpty = {
  label: "No Referral Rebates History",
  icon: <NoHistoryIcon />,
};

export const transactionsTableEmpty = {
  label: "No Transaction History",
  icon: <NoHistoryIcon />,
};

export const cardImgs = {
  atar: "/img/dashboard/atar.png",
  btc: "/img/dashboard/btc.png",
  bnb: "/img/dashboard/bnb.png",
  eth: "/img/dashboard/eth.png",
  usdt: "/img/dashboard/usdt.png",
  gold: "/img/dashboard/gold.png",
  trx: "/img/dashboard/trx.png",
};

export const referralHistoryTypeUni = [
  {
    name: "User Address",
    width: 15,
    mobileWidth: 40,
    id: 0,
  },
  {
    name: "User Level",
    width: 15,
    id: 1,
  },
  {
    name: "Amount",
    width: 15,
    mobileWidth: 50,
    id: 2,
  },
  {
    name: "Status",
    width: 15,
    id: 3,
  },
  {
    name: "Date",
    width: 15,
    id: 4,
  },
];

export const referralHistoryTypeBinary = [
  {
    name: "Amount",
    width: 15,
    mobileWidth: 45,
    id: 0,
  },
  {
    name: "Position",
    width: 15,
    mobileWidth: 50,
    id: 1,
  },
  {
    name: "Status",
    width: 15,
    id: 2,
  },
  {
    name: "Date",
    width: 15,
    id: 3,
  },
];
