import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useConnectMeta } from "../hooks/use-connect";
import { useApp } from "../hooks/use-app";
import translates from "../translates.json";
import { axiosUtility, axiosMultyUtility } from "../utils/axiosUtility";
import { Dashboard as DashboardUI } from "./Dashboard/Dashboard";
import {
  transactionHeader,
  referralRebatesTableEmpty,
  transactionsTableEmpty,
  cardImgs,
} from "../dummyComponents/dashboard";
import useHandleSidebar from "../hooks/useHandleSidebar";

const Dashboard = () => {
  const [rebatesTableData, setRebatesTableData] = useState([]);
  const [transactionsData, setTransactionsData] = useState({});
  const [totalTransactions, setTotalTransactions] = useState({});
  const [referralLeftRight, setReferralLeftRight] = useState({});
  const [transactionsTableLoading, setTransactionsTableLoading] =
    useState(false);

  const extensions = useSelector((state) => state.extensions.activeExtensions);
  const accountsData = useSelector((state) => state.appState?.accountsData);
  const accountType = useSelector(
    (state) => state.appState?.dashboardAccountType
  );
  const userData = useSelector((state) => state.appState?.userData);
  const appState = useSelector((state) => state.appState);
  const dashboardTransactionsDataReload = useSelector(
    (state) => state.appState?.dashboardTransactionsDataReload
  );
  const { account } = useConnectMeta();
  const { updateState } = useApp();
  const dispatch = useDispatch();
  const prevDashboardTransactionsDataReload = useRef(
    dashboardTransactionsDataReload
  );

  const handleSidebarOpen = useHandleSidebar();

  const setAccountType = (type) => {
    dispatch({
      type: "SET_DASHBOARD_ACCOUNT_TYPE",
      payload: type,
    });
  };

  useEffect(() => {
    if (
      prevDashboardTransactionsDataReload.current !==
      dashboardTransactionsDataReload
    ) {
      setTransactionsTableLoading(true);

      const getResponse = async () => {
        let response = await axiosUtility(
          "/api/transactions/get_transactions_of_user",
          {
            address: account?.toLowerCase(),
            limit: 10,
            page: 1,
          },
          "POST"
        );

        setTransactionsData(response?.data);
        setTotalTransactions({
          total_transaction: response?.data?.total_transaction,
          received: response?.data?.amounts_to_from?.[0]?.toSum || 0,
          spent: response?.data?.amounts_to_from?.[0]?.fromSum || 0,
        });
        setTransactionsTableLoading(false);
      };

      getResponse();

      prevDashboardTransactionsDataReload.current =
        dashboardTransactionsDataReload;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardTransactionsDataReload, account]);

  useEffect(() => {
    if (appState?.accountSigned) {
      const getResponse = async () => {
        let params = [
          {
            type: "POST",
            url: "/api/referral/binary_comission_count_user",
            body: {
              address: userData?.address?.toLowerCase(),
            },
          },
          {
            type: "POST",
            url: "/api/referral/uni_comission_count_user",
            body: {
              address: userData?.address?.toLowerCase(),
            },
          },
          {
            type: "POST",
            url: "/api/transactions/get_transactions_of_user",
            body: {
              address: account?.toLowerCase(),
              limit: 10,
              page: 1,
            },
          },
        ];

        let responses = await axiosMultyUtility(params);

        setReferralLeftRight({
          ...responses[0]?.data?.results,
          uni: responses[1]?.data?.results,
        });
        setTransactionsData(responses[2]?.data);
        setTotalTransactions({
          total_transaction: responses[2]?.data?.total_transaction,
          received: responses[2]?.data?.amounts_to_from?.[0]?.toSum || 0,
          spent: responses[2]?.data?.amounts_to_from?.[0]?.fromSum || 0,
        });
      };

      getResponse();
      updateState();
    }
    // eslint-disable-next-line
  }, [appState?.accountSigned, userData?.address, account?.toLowerCase()]);

  return (
    <DashboardUI
      accountAddress={account.toLowerCase()}
      translates={translates}
      accountType={accountType}
      setAccountType={setAccountType}
      transactionsData={transactionsData}
      transactionHeader={transactionHeader}
      rebatesTableData={rebatesTableData}
      totalTransactions={totalTransactions}
      referralHistoryTableEmpty={referralRebatesTableEmpty}
      transactionsTableEmpty={transactionsTableEmpty}
      transactionsTableLoading={transactionsTableLoading}
      accountsData={accountsData}
      cardImgs={cardImgs}
      handleExchange={(a, b) => handleSidebarOpen("exchange", b)}
      handleDeposit={() => handleSidebarOpen("deposit")}
      handleWithdraw={(a, b) => handleSidebarOpen("withdraw", b)}
      handleTransfer={(a, b) => handleSidebarOpen("transfer", b)}
      handleStake={(a, b) => handleSidebarOpen("stake", b)}
      tier={userData?.tier?.value}
      extensions={extensions}
      stakedTotal={userData?.stakedTotal}
      referralTotal={referralLeftRight}
      transactionLink={process.env.REACT_APP_TRANSACTION_LINK}
    />
  );
};

export default React.memo(Dashboard);
