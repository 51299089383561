import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import BigNumber from "bignumber.js";
import { config } from "../utils/wagmiConfig";
import {
  writeContract,
  readContract
} from "wagmi/actions";
import { toast } from "react-toastify";
import Web3 from "web3";

import STACK_ABI from "../abi/stack.json";
import WBNB from "../abi/WBNB.json";
import { useConnectMeta } from "./use-connect";

export const useStake = ({ Router, tokenAddress }) => {
  const web3 = new Web3(process.env.REACT_APP_WEB3_PROVIDER_URL);
  const providerType = useSelector((state) => state.connect.providerType);
  const isConnected = useSelector((state) => state.connect.isConnected);
  const addressFromEmail = useSelector((state) => state.connect.address);
  const lastConnectionType = useSelector((state) => state.connect.lastConnectionType);
  const { depositAmount, timeperiod, timeperiodDate } = useSelector(
    (state) => state.stake
  );

  const dispatch = useDispatch();
  const { account, library } = useConnectMeta();

  useEffect(() => {
    if (library) {
      checkAllowance();
      getStackerInfo(0, 15);
    }
  }, [library]);

  const notify = (isError, msg) => {
    if (isError) {
      console.log("error", msg);
    } else {
      console.log("success", msg);
    }
  };

  const checkAllowance = async () => {
    if (lastConnectionType === "web3") {
      try {
        if (providerType === "walletConnect") {
          const decimals = await readContract(config, {
            address: tokenAddress,
            abi: WBNB,
            functionName: "decimals",
            args: [],
          });
          
          const getBalance = await readContract(config, {
            address: tokenAddress,
            abi: WBNB,
            functionName: "balanceOf",
            args: [
              account ? account : "0xE9A2A87941c9AebDab33C55611cB3c28aa83736c",
            ],
          });
  
          let balance = new BigNumber(getBalance);
          var pow = Math.pow(10, Number(decimals));
  
          let balanceInEth = balance.div(pow);
          balanceInEth = balanceInEth.toString();
  
          dispatch({
            type: "UPDATE_STAKE_STATE",
            payload: {
              balanceInEth,
            },
          });
  
          const allowanceBigInt = await readContract(config, {
            address: tokenAddress,
            abi: WBNB,
            functionName: "allowance",
            args: [
              account ? account : "0xE9A2A87941c9AebDab33C55611cB3c28aa83736c",
              Router,
            ],
          });
  
          let allowanceBalance = new BigNumber(allowanceBigInt);
          let allowance = allowanceBalance / pow;
          const depositNumber = Number(depositAmount);
  
          // if (allowance < 1 || (depositNumber > 0 && allowance < depositNumber)) {
          if (depositNumber > 0 && allowance < depositNumber) {
            dispatch({
              type: "UPDATE_STAKE_STATE",
              payload: {
                isAllowance: true,
              },
            });
          } else if (allowance > 1e30) {
            dispatch({
              type: "UPDATE_STAKE_STATE",
              payload: {
                isAllowance: true,
              },
            });
          } else {
            dispatch({
              type: "UPDATE_STAKE_STATE",
              payload: {
                isAllowance: false,
              },
            });
          }
        }
  
        if (providerType === "metaMask" && library) {
          let tokenContract = new library.eth.Contract(WBNB, tokenAddress);
          let decimals = await tokenContract.methods.decimals().call();
          let getBalance = await tokenContract.methods.balanceOf(account).call();
  
          let balance = new BigNumber(getBalance);
          let pow = new BigNumber(10).pow(decimals);
  
          let balanceInEth = balance.div(pow);
          balanceInEth = balanceInEth.toString();
  
          dispatch({
            type: "UPDATE_STAKE_STATE",
            payload: {
              balance: balanceInEth,
            },
          });
  
          let bigIntAllowanceBalance = await tokenContract.methods
            .allowance(account, Router)
            .call();
          let allowanceBalance = new BigNumber(bigIntAllowanceBalance);
          let allowance = allowanceBalance / pow;
          const depositNumber = Number(depositAmount);
  
          if (allowance < 1 || (depositNumber > 0 && allowance < depositNumber)) {
            dispatch({
              type: "UPDATE_STAKE_STATE",
              payload: {
                isAllowance: true,
              },
            });
          } else if (allowance > 1e30) {
            dispatch({
              type: "UPDATE_STAKE_STATE",
              payload: {
                isAllowance: true,
              },
            });
          } else {
            dispatch({
              type: "UPDATE_STAKE_STATE",
              payload: {
                isAllowance: false,
              },
            });
          }
        }
      } catch (err) {
        console.log("isAlowance error", err);
      }
    }
  };

  const approve = async (callback, errCallback, amountToApprove) => {
    if (lastConnectionType === "web3") {
      try {
        if (providerType === "walletConnect") {
          // if (isNativeToken) {
          //   console.error("Approval is not applicable for native tokens like BNB.");
          //   return;
          // }

          // Convert amountToApprove to string, default to "100" if undefined
          let amountToApproveString = amountToApprove ? amountToApprove.toString() : "100";
        
          // Ensure amountToApprove is a valid number
          if (isNaN(amountToApproveString)) {
            throw new Error("Invalid amount to approve");
          }

          const decimals = await readContract(config, {
            address: tokenAddress,
            abi: WBNB,
            functionName: "decimals",
            args: [],
          });

          let contract = new web3.eth.Contract(WBNB, tokenAddress);
  
          let pow = new BigNumber(10).pow(decimals);
          // Calculate the amount in the smallest unit using BigNumber
          let amountIn = new BigNumber(amountToApproveString).multipliedBy(pow);
          // Convert the amount to a string without scientific notation
          let amountInString = amountIn.toFixed();
  
          const gasLimit = await contract.methods
            .approve(Router, amountInString)
            .estimateGas({ from: account }) * 1.2;
          const gasPrice = await web3.eth.getGasPrice();

          const hash = await writeContract(config, {
            abi: WBNB,
            address: tokenAddress,
            functionName: "approve",
            args: [Router, amountInString],
            gas: Math.ceil(gasLimit),
            gasPrice: gasPrice,
          });

          if (hash) {
            if (callback) callback();
            dispatch({
              type: "UPDATE_STAKE_STATE",
              payload: {
                isAllowance: false,
              },
            });
          }
        }
  
        if (providerType === "metaMask") {
          let contract = new library.eth.Contract(WBNB, tokenAddress);
          let some = amountToApprove ? amountToApprove?.toString() : "100";
          let amountIn;
          amountIn = library.utils.toWei(some, "ether");
          amountIn = amountIn.toLocaleString("fullwide", { useGrouping: false });
  
          const gasLimit = await contract.methods
            .approve(Router, amountIn?.toString())
            .estimateGas({ from: account });
          const gasPrice = await library.eth.getGasPrice();
  
          await contract.methods
            .approve(Router, amountIn?.toString())
            .send({
              from: account,
              gas: gasLimit,
              gasPrice,
            })
            .then(() => {
              if (callback) callback();
              dispatch({
                type: "UPDATE_STAKE_STATE",
                payload: {
                  isAllowance: false,
                },
              });
            });
        }
      } catch (err) {
        if (errCallback) errCallback(err);
        notify(true, err.message);
      }
    }
  };

  const stake = async (amount, callback, errCallback) => {
    if (lastConnectionType === "web3") {
      if (isNaN(parseFloat(amount)) || parseFloat(amount) <= 0) {
        notify(true, "Error! please enter amount");
        return;
      }
  
      await checkAllowance();

      try {
        if (providerType === "walletConnect") {
          let contract = new web3.eth.Contract(STACK_ABI, Router);
  
          const decimals = await readContract(config, {
            address: tokenAddress,
            abi: WBNB,
            functionName: "decimals",
            args: [],
          });
  
          let pow = Math.pow(10, Number(decimals));
          let amountIn = new BigNumber(amount).times(pow);
          const finalAmount = amountIn.toFixed();
  
          const gasLimit = await contract.methods
            .stake(timeperiod, finalAmount)
            .estimateGas({ from: account });
          const gasPrice = await web3.eth.getGasPrice();
          
          const hash = await writeContract(config, {
            address: Router,
            abi: STACK_ABI,
            functionName: "stake",
            args: [timeperiod, finalAmount],
            gas: gasLimit,
            gasPrice: gasPrice,
          });
  
          if (hash) {
            getStackerInfo();
            dispatch({
              type: "UPDATE_STAKE_STATE",
              payload: {
                depositAmount: depositAmount,
                timeperiodDate: timeperiodDate,
                timeperiod: timeperiod,
              },
            });
            if (callback) callback();
            toast.success(`Successfully stake ${hash}`, {
              autoClose: false,
              onClick: function () {
                window.open(`https://bscscan.com/tx/${hash}`, "_blank");
              },
            });
            notify(false, "Staking process complete.");
          }
        }
  
        if (providerType === "metaMask") {
          let tokenContract = new library.eth.Contract(WBNB, tokenAddress);
          const decimals = await tokenContract.methods.decimals().call();
  
          let contract = new library.eth.Contract(STACK_ABI, Router);
  
          let pow = 10 ** decimals;
          let amountIn = amount * pow;
          const finalAmount = amountIn.toLocaleString("fullwide", {
            useGrouping: false,
          });
  
          const gasLimit = await contract.methods
            .stake(timeperiod, finalAmount)
            .estimateGas({ from: account });
          const gasPrice = await library.eth.getGasPrice();
  
          await contract.methods
            .stake(timeperiod, finalAmount)
            .send({
              from: account,
              gas: gasLimit,
              gasPrice,
            })
            .then((resept) => {
              getStackerInfo();
              dispatch({
                type: "UPDATE_STAKE_STATE",
                payload: {
                  depositAmount: depositAmount,
                  timeperiodDate: timeperiodDate,
                  timeperiod: timeperiod,
                },
              });
              if (callback) callback();
              toast.success(`Successfully stake ${resept.transactionHash}`, {
                autoClose: false,
                onClick: function () {
                  window.open(
                    `https://testnet.bscscan.com/tx/${resept.transactionHash}`,
                    "_blank"
                  );
                },
              });
              notify(false, "Staking process complete.");
            });
        }
      } catch (err) {
        console.log(err);
        if (errCallback) errCallback(err);
        notify(true, err.message);
      }
    } else {
      toast.error("Staking is not possible when logged in with email and password.", {
        autoClose: false,
      });
    }
  };

  const unstake = async (index, callback, errCallback) => {
    if (lastConnectionType === "web3") {
      try {
        if (providerType === "walletConnect") {
          let contract = new web3.eth.Contract(STACK_ABI, Router);
  
          const gasLimit = await contract.methods
            .unstake(index.toString())
            .estimateGas({ from: account });
          const gasPrice = await web3.eth.getGasPrice();

          const hash = await writeContract(config, {
            address: Router,
            abi: STACK_ABI,
            functionName: "unstake",
            args: [index.toString()],
            gas: gasLimit,
            gasPrice: gasPrice,
          });
  
          if (hash) {
            if (callback) callback(index);
            getStackerInfo();
            toast.success(`successfully unstake ${hash}`, {
              autoClose: false,
              onClick: function () {
                window.open(`https://testnet.bscscan.com/tx/${hash}`, "_blank");
              },
            });
            notify(false, `successfully unstake ${hash}`);
          }
        }
  
        if (providerType === "metaMask" && library) {
          let contract = new library.eth.Contract(STACK_ABI, Router);
  
          const gasLimit = await contract.methods
            .unstake(index.toString())
            .estimateGas({ from: account });
          const gasPrice = await library.eth.getGasPrice();
  
          await contract.methods
            .unstake(index.toString())
            .send({
              from: account,
              gas: gasLimit,
              gasPrice,
            })
            .then((resept) => {
              if (callback) callback(index);
              getStackerInfo();
              toast.success(`successfully unstake ${resept.transactionHash}`, {
                autoClose: false,
                onClick: function () {
                  window.open(
                    `https://testnet.bscscan.com/tx/${resept.transactionHash}`,
                    "_blank"
                  );
                },
              });
              notify(false, `successfully unstake ${resept.transactionHash}`);
            });
        }
      } catch (err) {
        if (errCallback) errCallback(err);
        notify(true, "unstake fail");
      }
    }
  };

  const harvest = async (index, callback, errCallback) => {
    if (lastConnectionType === "web3") {
      try {
        if (providerType === "walletConnect") {
          let contract = new web3.eth.Contract(STACK_ABI, Router);
  
          const gasLimit = await contract.methods
            .harvest(index.toString())
            .estimateGas({ from: account });
          const gasPrice = await web3.eth.getGasPrice();

          const hash = await writeContract(config, {
            address: Router,
            abi: STACK_ABI,
            functionName: "harvest",
            args: [index.toString()],
            gas: gasLimit,
            gasPrice: gasPrice,
          })
  
          if (hash) {
            if (callback) callback(index);
            getStackerInfo();
            checkAllowance();
            toast.success(`Reward successfully harvested ${hash}`, {
              autoClose: false,
              onClick: function () {
                window.open(`https://testnet.bscscan.com/tx/${hash}`, "_blank");
              },
            });
            notify(false, "Reward successfully harvested");
          }
        }
  
        if (providerType === "metaMask") {
          let contract = new library.eth.Contract(STACK_ABI, Router);
  
          const gasLimit = await contract.methods
            .harvest(index.toString())
            .estimateGas({ from: account });
          const gasPrice = await library.eth.getGasPrice();
  
          await contract.methods
            .harvest(index.toString())
            .send({
              from: account,
              gas: gasLimit,
              gasPrice,
            })
            .then((resept) => {
              if (callback) callback(resept);
              getStackerInfo();
              checkAllowance();
              toast.success(
                `Reward successfully harvested ${resept.transactionHash}`,
                {
                  autoClose: false,
                  onClick: function () {
                    window.open(
                      `https://testnet.bscscan.com/tx/${resept.transactionHash}`,
                      "_blank"
                    );
                  },
                }
              );
              notify(false, "Reward successfully harvested");
            });
        }
      } catch (err) {
        if (errCallback) errCallback(err);
        notify(true, err.message);
      }
    }
  };
  
  const getStackerInfo = async (startIndex, count) => {
    dispatch({
      type: "UPDATE_STAKE_STATE",
      payload: {
        loading: true,
      },
    });
    
    try {
      if (providerType === "walletConnect") {
        const decimals = await readContract(config, {
          address: tokenAddress,
          abi: WBNB,
          functionName: "decimals",
          args: [],
        });

        let getBalance = await readContract(config, {
          address: tokenAddress,
          abi: WBNB,
          functionName: "balanceOf",
          args: [account ? account.toString() : addressFromEmail],
        });

        let totalStakedToken = await readContract(config, {
          address: Router,
          abi: STACK_ABI,
          functionName: "totalStakedAmt",
          args: [],
        });

        let totalStakers = await readContract(config, {
          address: Router,
          abi: STACK_ABI,
          functionName: "totalStakers",
          args: [],
        });

        let realtimeReward = await readContract(config, {
          address: Router,
          abi: STACK_ABI,
          functionName: "realtimeReward",
          args: [account ? account.toString() : addressFromEmail],
        });

        let Stakers = await readContract(config, {
          address: Router,
          abi: STACK_ABI,
          functionName: "Stakers",
          args: [account ? account.toString() : addressFromEmail],
        });

        let pow = Math.pow(10, Number(decimals));
        let balance = new BigNumber(getBalance);
        let balanceInEth = balance.div(pow);
        balanceInEth = balanceInEth.toString();

        dispatch({
          type: "UPDATE_STAKE_STATE",
          payload: {
            balance: balanceInEth,
          },
        });

        let totalStakedTokenUser = Number(Stakers[0]) / pow;

        let totalUnstakedTokenUser = Number(Stakers[2]) / pow;
        let currentStaked = Number(Stakers[1]) / pow;
        totalStakedToken = Number(totalStakedToken) / pow;

        Stakers.totalStakedTokenUser = totalStakedTokenUser;
        Stakers.totalUnstakedTokenUser = totalUnstakedTokenUser;
        Stakers.currentStaked = currentStaked;
        Stakers.realtimeReward = Number(realtimeReward) / pow;
        Stakers.totalClaimedRewardTokenUser = Number(Stakers[3]) / pow;

        const stakersRecord = [];
        const endIndex = Math.min(startIndex + count, parseInt(Stakers[4]));

        const recordsPromises = [];
        for (let i = startIndex; i < endIndex; i++) {
          const stakersRecord = await readContract(config, {
            address: Router,
            abi: STACK_ABI,
            functionName: "stakersRecord",
            args: [account ? account.toString() : addressFromEmail, i],
          });
          const realtimeRewardPerBlock = await readContract(config, {
            address: Router,
            abi: STACK_ABI,
            functionName: "realtimeRewardPerBlock",
            args: [account ? account : addressFromEmail, i],
          });

          recordsPromises.push(stakersRecord);
          recordsPromises.push(realtimeRewardPerBlock);
        }

        const recordsResults = recordsPromises;

        for (let i = 0; i < recordsResults.length; i += 2) {
          let stakersRecordData = [];
          let realtimeRewardPerBlock = recordsResults[i + 1];

          stakersRecordData.amount = Number(recordsResults[i][2]) / pow;
          stakersRecordData.reward = Number(recordsResults[i][3]) / pow;
          stakersRecordData.lastharvesttime = Number(recordsResults[i][4]);
          stakersRecordData.remainingreward =
            Number(recordsResults[i][5]) / pow;
          stakersRecordData.harvestreward = Number(recordsResults[i][6]) / pow;
          stakersRecordData.persecondreward =
            Number(recordsResults[i][7]) / pow;
          stakersRecordData.withdrawan = recordsResults[i][8];
          stakersRecordData.unstaked = recordsResults[i][9];
          stakersRecordData.realtimeRewardPerBlock =
            Number(realtimeRewardPerBlock[0]) / pow;
          stakersRecordData.unstaketime = moment
            .unix(Number(recordsResults[i][0]))
            .format("DD/MM/YYYY h:mm A");
          stakersRecordData.staketime = moment
            .unix(Number(recordsResults[i][1]))
            .format("DD/MM/YYYY h:mm A");
          stakersRecord.push(stakersRecordData);
        }

        const hasMoreData = endIndex < parseInt(Stakers[4]);

        dispatch({
          type: "UPDATE_STAKE_STATE",
          payload: {
            stakersInfo: Stakers,
            stackContractInfo: {
              totalStakers: Number(totalStakers),
              totalStakedToken: totalStakedToken,
            },
            hasMoreData,
            loading: false,
          },
        });
        dispatch({
          type: "UPDATE_STAKERS_RECORD",
          payload: {
            stakersRecord,
          },
        });
      }

      if ((providerType === "metaMask" || lastConnectionType === "email") && library) {
        const tokenContract = new library.eth.Contract(WBNB, tokenAddress);
        const contract = new library.eth.Contract(STACK_ABI, Router);

        let [
          decimals,
          getBalance,
          totalStakedToken,
          totalStakers,
          realtimeReward,
          Stakers,
        ] = await Promise.all([
          tokenContract.methods.decimals().call(),
          tokenContract.methods.balanceOf(account ? account.toString() : addressFromEmail).call(),
          contract.methods.totalStakedAmt.call().call(),
          contract.methods.totalStakers.call().call(),
          contract.methods.realtimeReward(account ? account.toString() : addressFromEmail).call(),
          contract.methods.Stakers(account ? account.toString() : addressFromEmail).call(),
        ]);

        const pow = 10 ** decimals;
        const balanceInEth = getBalance / pow;

        dispatch({
          type: "UPDATE_STAKE_STATE",
          payload: {
            balance: balanceInEth,
          },
        });

        let totalStakedTokenUser = Number(Stakers.totalStakedAmtUser) / pow;
        let totalUnstakedTokenUser = Number(Stakers.totalUnstakedAmtUser) / pow;
        let currentStaked = totalStakedTokenUser - totalUnstakedTokenUser;
        totalStakedToken = Number(totalStakedToken) / pow;

        Stakers.totalStakedTokenUser = totalStakedTokenUser;
        Stakers.totalUnstakedTokenUser = totalUnstakedTokenUser;
        Stakers.currentStaked = currentStaked;
        Stakers.realtimeReward = Number(realtimeReward) / pow;
        Stakers.totalClaimedRewardTokenUser =
          Stakers.totalClaimedRewardTokenUser / pow;
        const stakersRecord = [];
        const endIndex = Math.min(
          startIndex + count,
          parseInt(Stakers.stakeCount)
        );

        const recordsPromises = [];
        for (let i = startIndex; i < endIndex; i++) {
          recordsPromises.push(
            contract.methods.stakersRecord(account ? account.toString() : addressFromEmail, i).call()
          );
          recordsPromises.push(
            contract.methods
              .realtimeRewardPerBlock(account ? account.toString() : addressFromEmail, i.toString())
              .call()
          );
        }

        const recordsResults = await Promise.all(recordsPromises);

        for (let i = 0; i < recordsResults.length; i += 2) {
          let stakersRecordData = recordsResults[i];
          let realtimeRewardPerBlock = recordsResults[i + 1];

          stakersRecordData.realtimeRewardPerBlock =
            realtimeRewardPerBlock[0] / pow;
          stakersRecordData.amount = Number(stakersRecordData.amount) / pow;
          stakersRecordData.reward = Number(stakersRecordData.reward) / pow;
          stakersRecordData.lastharvesttime = Number(
            stakersRecordData.lastharvesttime
          );
          stakersRecordData.remainingreward =
            Number(stakersRecordData.remainingreward) / pow;
          stakersRecordData.harvestreward =
            Number(stakersRecordData.harvestreward) / pow;
          stakersRecordData.persecondreward =
            Number(stakersRecordData.persecondreward) / pow;
          // stakersRecordData.withdrawan = stakersRecordData.withdrawan;
          // stakersRecordData.unstaked = stakersRecordData.unstaked;

          stakersRecordData.unstaketime = moment
            .unix(stakersRecordData.unstaketime)
            .format("DD/MM/YYYY h:mm A");
          stakersRecordData.staketime = moment
            .unix(stakersRecordData.staketime)
            .format("DD/MM/YYYY h:mm A");
          stakersRecord.push(stakersRecordData);
        }

        const hasMoreData = endIndex < parseInt(Stakers.stakeCount);

        dispatch({
          type: "UPDATE_STAKE_STATE",
          payload: {
            stakersInfo: Stakers,
            stackContractInfo: {
              totalStakers,
              totalStakedToken,
            },
            hasMoreData,
            loading: false,
          },
        });
        dispatch({
          type: "UPDATE_STAKERS_RECORD",
          payload: {
            stakersRecord,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "UPDATE_STAKE_STATE",
        payload: {
          stakersInfo: {
            totalStakedTokenUser: 0,
            totalUnstakedTokenUser: 0,
            totalClaimedRewardTokenUser: 0,
            currentStaked: 0,
            realtimeReward: 0,
            stakeCount: 0,
            alreadyExists: false,
          },
          stakersRecord: [],
          setStackContractInfo: {
            totalStakers: 0,
            totalStakedToken: 0,
          },
          loading: false,
          balance: 0,
        },
      });

      console.error(err);
    }
  };

  const setMaxWithdrawal = async () => {
    if (providerType === "walletConnect") {
      const decimals = await readContract(config, {
        address: tokenAddress,
        abi: WBNB,
        functionName: "decimals",
        args: [],
      });

      const getBalance = await readContract(config, {
        address: tokenAddress,
        abi: WBNB,
        functionName: "balanceOf",
        args: [account.toString()],
      });
      const pow = Math.pow(10, Number(decimals));

      let balance = new BigNumber(getBalance);
      let balanceInEth = balance.div(pow);
      balanceInEth = balanceInEth.toString();

      dispatch({
        type: "UPDATE_STAKE_STATE",
        payload: {
          depositAmount: balanceInEth.toFixed(5),
        },
      });
    }

    if (providerType === "metaMask" && library) {
      let tokenContract = new library.eth.Contract(WBNB, tokenAddress);
      let decimals = await tokenContract.methods.decimals().call();
      let getBalance = await tokenContract.methods
        .balanceOf(account.toString())
        .call();
      let pow = 10 ** decimals;
      let balanceInEth = getBalance / pow;
      dispatch({
        type: "UPDATE_STAKE_STATE",
        payload: {
          depositAmount: balanceInEth.toFixed(5),
        },
      });
    }
  };

  const handleTimeperiodDate = (period) => {
    dispatch({
      type: "UPDATE_STAKE_STATE",
      payload: {
        timeperiodDate: moment()
          .add(period, "days")
          .format("DD/MM/YYYY h:mm A"),
      },
    });
  };

  const handleDepositAmount = (inputValue) => {
    let depositAmount;

    if (typeof inputValue === "number") {
      depositAmount = String(inputValue);
    } else if (typeof inputValue === "string") {
      if (inputValue === "") {
        depositAmount = "";
      } else {
        depositAmount = inputValue.replace(/^0+/, ""); // Remove leading zeros
      }
    }

    dispatch({
      type: "UPDATE_STAKE_STATE",
      payload: {
        depositAmount,
      },
    });
  };

  const handleTimePeriod = (timeperiod) => {
    dispatch({
      type: "UPDATE_STAKE_STATE",
      payload: {
        timeperiod,
      },
    });
  };

  const values = useMemo(
    () => ({
      checkAllowance,
      approve,
      stake,
      unstake,
      harvest,
      getStackerInfo,
      setMaxWithdrawal,
      handleTimeperiodDate,
      handleDepositAmount,
      handleTimePeriod,
      account,
    }),
    // eslint-disable-next-line
    [account, depositAmount, timeperiod]
  );

  return values;
};
