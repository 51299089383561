import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { useConnectMeta } from "../hooks/use-connect";

const Loan = ({helpSupportClick}) => {
  const [allLoanOffers, setAllLoanOffers] = useState([]);
  const [yourLending, setYourLending] = useState([]);
  const [yourBorrowing, setYourBorrowing] = useState([]);

  const { account } = useConnectMeta();

  const getLoanMarketOffers = () => {
    axios
      .get("/api/loan/loan-market-offers")
      .then((res) => {
        setAllLoanOffers(res.data?.result);
      })
      .catch((e) => console.log(e.message));
  };

  function getUserCreatedLoans() {
    axios
      .get(`/api/loan/user-created-loans?address=${account}`)
      .then((res) => {
        setYourLending(res.data?.result);
      })
      .catch((e) => console.log(e.message));
  }

  function getUserLoans() {
    axios
      .get(`/api/loan/user-loans?address=${account}`)
      .then((res) => {
        setYourBorrowing(res.data?.result);
      })
      .catch((e) => console.log(e.message));
  }

  function handleCreateNewLoanOffering(loan) {
    const mutatedLoan = { ...loan, lender: account };

    axios
      .post("/api/loan/create-loan", mutatedLoan)
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => console.log(e));
  }

  function handleTakeLoan(loanId) {
    const mutatedLoan = {
      id: loanId,
      borrower: account,
    };
    axios
      .post("/api/loan/take-loan", mutatedLoan)
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => console.log(e.message));
  }

  function handleRepayLoan(data) {
    data.borrower = account;
    axios
      .post("/api/loan/repay-loan", data)
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => console.log(e.message));
  }

  function handleMakeOffer(loan) {
    const data = { ...loan, borrower: account };

    console.log(data);
  }

  function handleDeleteLoanOffer(loanId) {
    const data = { id: loanId, lender: account };

    axios
      .post(`/api/loan/delete-loan-offer`, { data })
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => console.log(e.message));
  }

  function handleRescindOffer(loanId) {
    const data = { id: loanId, borrower: account, offerId: "not yet defined" };

    console.log(data);
  }

  useEffect(() => {
    getLoanMarketOffers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (account) {
      getUserCreatedLoans();
      getUserLoans();
    }
    // eslint-disable-next-line
  }, [account]);

  useEffect(() => {}, []);

  // return (
  //   <div style={{ paddingTop: "100px", display: "flex", gap: "100px" }}>
  //     <div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
  //       loans
  //       {loans.map((loan) => {
  //         return (
  //           <div
  //             key={loan._id}
  //             style={{
  //               display: "flex",
  //               gap: "10px",
  //               height: "44px",
  //               alignItems: "center",
  //             }}
  //           >
  //             <div>
  //               <p>amount</p>
  //               <p>{loan.amount}</p>
  //             </div>
  //             <div>
  //               <p>duration</p>
  //               <p>{loan.duration}</p>
  //             </div>
  //             <div>
  //               <p>lender</p>
  //               <p
  //                 style={{
  //                   maxWidth: "100px",
  //                   overflow: "hidden",
  //                   textOverflow: "ellipsis",
  //                 }}
  //               >
  //                 {loan.lender}
  //               </p>
  //             </div>
  //             <div>
  //               <p>borrower</p>
  //               <p
  //                 style={{
  //                   maxWidth: "100px",
  //                   overflow: "hidden",
  //                   textOverflow: "ellipsis",
  //                 }}
  //               >
  //                 {loan.borrower}
  //               </p>
  //             </div>
  //             <div>
  //               <p>status</p>
  //               <p>{loan.status}</p>
  //             </div>
  //             <button onClick={() => takeLoan(loan._id)}>take loan</button>
  //           </div>
  //         );
  //       })}
  //     </div>
  //     <div
  //       style={{ display: "flex", flexDirection: "column", width: "45%", gap: "40px" }}
  //     >
  //       <p>lone profile side</p>
  //       <div>
  //         <p>my active loans</p>
  //         userLoans
  //         {userLoans.map((loan) => {
  //           return (
  //             <div
  //               key={loan._id}
  //               style={{
  //                 display: "flex",
  //                 gap: "10px",
  //                 height: "44px",
  //                 alignItems: "center",
  //               }}
  //             >
  //               <div>
  //                 <p>amount</p>
  //                 <p>{loan.amount}</p>
  //               </div>
  //               <div>
  //                 <p>duration</p>
  //                 <p>{loan.duration}</p>
  //               </div>
  //               <div>
  //                 <p>lender</p>
  //                 <p
  //                   style={{
  //                     maxWidth: "100px",
  //                     overflow: "hidden",
  //                     textOverflow: "ellipsis",
  //                   }}
  //                 >
  //                   {loan.lender}
  //                 </p>
  //               </div>
  //               <div>
  //                 <p>borrower</p>
  //                 <p
  //                   style={{
  //                     maxWidth: "100px",
  //                     overflow: "hidden",
  //                     textOverflow: "ellipsis",
  //                   }}
  //                 >
  //                   {loan.borrower}
  //                 </p>
  //               </div>
  //               <div>
  //                 <p>status</p>
  //                 <p>{loan.status}</p>
  //               </div>
  //               <button onClick={() => repayLoan(loan._id)}>repay loan</button>
  //               <button onClick={() => defaultLoan(loan._id)}>default loan</button>
  //             </div>
  //           );
  //         })}
  //       </div>
  //       <div>
  //         <p>my created loan offers</p>
  //         userLoanOffers
  //         {userLoanOffers.map((loan) => {
  //           return (
  //             <div
  //               key={loan._id}
  //               style={{
  //                 display: "flex",
  //                 gap: "10px",
  //                 height: "44px",
  //                 alignItems: "center",
  //               }}
  //             >
  //               <div>
  //                 <p>amount</p>
  //                 <p>{loan.amount}</p>
  //               </div>
  //               <div>
  //                 <p>duration</p>
  //                 <p>{loan.duration}</p>
  //               </div>
  //               <div>
  //                 <p>lender</p>
  //                 <p
  //                   style={{
  //                     maxWidth: "100px",
  //                     overflow: "hidden",
  //                     textOverflow: "ellipsis",
  //                   }}
  //                 >
  //                   {loan.lender}
  //                 </p>
  //               </div>
  //               <div>
  //                 <p>borrower</p>
  //                 <p
  //                   style={{
  //                     maxWidth: "100px",
  //                     overflow: "hidden",
  //                     textOverflow: "ellipsis",
  //                   }}
  //                 >
  //                   {loan.borrower}
  //                 </p>
  //               </div>
  //               <div>
  //                 <p>status</p>
  //                 <p>{loan.status}</p>
  //               </div>
  //               <button onClick={() => deleteLoanOffer(loan._id)}>
  //                 delete loan offer
  //               </button>
  //             </div>
  //           );
  //         })}
  //         <button onClick={createLoan}>create new loan offer</button>
  //       </div>
  //       <div>
  //         <p>borrowers offers</p>
  //       </div>
  //     </div>
  //   </div>
  // );
  return (
    // <LoanTest
    //   account={account}
    //   allLoanOffers={allLoanOffers}
    //   yourLending={yourLending}
    //   yourBorrowing={yourBorrowing}
    //   createNewLoanOffering={handleCreateNewLoanOffering}
    //   handleDeleteLoanOffer={handleDeleteLoanOffer}
    //   handleTakeLoan={handleTakeLoan}
    //   handleRepayLoan={handleRepayLoan}
    //   makeOffer={handleMakeOffer}
    //   rescindOffer={handleRescindOffer}
    // />
    // <Body side="Side loan" main="Main loan" />
    <div>loan</div>
  );
};

export default Loan;
