// styles
import "./Footer.css";

export const Footer = ({ customStyles, helpSupportClick }) => {
  return (
    <div className={"footer-container"} style={customStyles}>
      <div className={"footer-help"} onClick={helpSupportClick}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.6665 18.6C6.43317 18.6 6.1915 18.5417 5.97484 18.425C5.49984 18.175 5.20817 17.675 5.20817 17.1417V15.9583C2.6915 15.7 1.0415 13.85 1.0415 11.2V6.19999C1.0415 3.33333 2.9665 1.40833 5.83317 1.40833H14.1665C17.0332 1.40833 18.9582 3.33333 18.9582 6.19999V11.2C18.9582 14.0667 17.0332 15.9917 14.1665 15.9917H11.0248L7.47484 18.3583C7.23317 18.5167 6.94984 18.6 6.6665 18.6ZM5.83317 2.64999C3.68317 2.64999 2.2915 4.04166 2.2915 6.19166V11.1917C2.2915 13.3417 3.68317 14.7333 5.83317 14.7333C6.17484 14.7333 6.45817 15.0167 6.45817 15.3583V17.1333C6.45817 17.2417 6.52484 17.2917 6.5665 17.3167C6.60817 17.3417 6.6915 17.3667 6.78317 17.3083L10.4915 14.8417C10.5915 14.775 10.7165 14.7333 10.8415 14.7333H14.1748C16.3248 14.7333 17.7165 13.3417 17.7165 11.1917V6.19166C17.7165 4.04166 16.3248 2.64999 14.1748 2.64999H5.83317Z"
            fill="#FFF"
          />
          <path
            d="M10 10.0917C9.65837 10.0917 9.37503 9.80834 9.37503 9.46667V9.29167C9.37503 8.32501 10.0834 7.85001 10.35 7.66667C10.6584 7.45834 10.7584 7.31667 10.7584 7.10001C10.7584 6.68334 10.4167 6.34167 10 6.34167C9.58337 6.34167 9.2417 6.68334 9.2417 7.10001C9.2417 7.44167 8.95837 7.72501 8.6167 7.72501C8.27503 7.72501 7.9917 7.44167 7.9917 7.10001C7.9917 5.99168 8.8917 5.09167 10 5.09167C11.1084 5.09167 12.0084 5.99168 12.0084 7.10001C12.0084 8.05001 11.3084 8.52501 11.05 8.70001C10.725 8.91667 10.625 9.05834 10.625 9.29167V9.46667C10.625 9.81667 10.3417 10.0917 10 10.0917Z"
            fill="#FFF"
          />
          <path
            d="M10 12.1667C9.91803 12.1668 9.83685 12.1507 9.76108 12.1195C9.68531 12.0882 9.61644 12.0423 9.5584 11.9844C9.50037 11.9266 9.4543 11.8578 9.42283 11.7821C9.39136 11.7064 9.37511 11.6253 9.375 11.5433C9.37489 11.4614 9.39093 11.3802 9.42219 11.3044C9.45346 11.2286 9.49934 11.1598 9.55723 11.1017C9.61511 11.0437 9.68385 10.9976 9.75954 10.9662C9.83522 10.9347 9.91637 10.9184 9.99833 10.9183C10.1639 10.9181 10.3227 10.9837 10.4399 11.1006C10.5571 11.2175 10.6231 11.3761 10.6233 11.5417C10.6236 11.7072 10.558 11.8661 10.4411 11.9833C10.3242 12.1005 10.1655 12.1664 10 12.1667Z"
            fill="#FFF"
          />
        </svg>
        <p className={"font-12"}>Help & Support</p>
      </div>
      <div className={"footer-copyright font-12"}>
        &#169; 2023 All rights reserved
      </div>
    </div>
  );
};
