const InfoCircleIcon = ({ color, width, height }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={width || height ? { width: width, height: height} : {}}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 7.59872 7.59872 4 12 4C16.4013 4 20 7.59872 20 12C20 16.4013 16.4013 20 12 20C7.59872 20 4 16.4013 4 12ZM12 5.11628C8.21523 5.11628 5.11628 8.21523 5.11628 12C5.11628 15.7848 8.21523 18.8837 12 18.8837C15.7848 18.8837 18.8837 15.7848 18.8837 12C18.8837 8.21523 15.7848 5.11628 12 5.11628Z"
        fill={color ? color : "#6A6D76"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9985 15.7209C11.6902 15.7209 11.4404 15.471 11.4404 15.1628L11.4404 11.4419C11.4404 11.1336 11.6902 10.8837 11.9985 10.8837C12.3068 10.8837 12.5566 11.1336 12.5566 11.4419L12.5566 15.1628C12.5566 15.471 12.3068 15.7209 11.9985 15.7209Z"
        fill={color ? color : "#6A6D76"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.748 9.20931C12.748 9.62031 12.4149 9.95349 12.0039 9.95349L11.9972 9.95349C11.5862 9.95349 11.253 9.62031 11.253 9.20931C11.253 8.7983 11.5862 8.46512 11.9972 8.46512L12.0039 8.46512C12.4149 8.46512 12.748 8.7983 12.748 9.20931Z"
        fill={color ? color : "#6A6D76"}
      />
    </svg>
  );
};

export default InfoCircleIcon;
