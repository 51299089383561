import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";

// components
import translates from "../../translatesNew.json";
import { Visual } from "../UI/visual/Visual.js";
import { Table } from "../UI/table/Table.js";
import { Button } from "../UI/button/Button.js";
import { ReferralCard } from "../UI/referralCard/ReferralCard.js";
import { Footer } from "../UI/footer/Footer.js";
import { InfoBox } from "../UI/infoBox/InfoBox.js";
import { DisabledPage } from "../UI/disabledPage/DisabledPage.js";
import { FilterBox } from "../UI/filterBox/FilterBox.js";
import { DashboardTable } from "../Dashboard/DashboardTable/DashboardTable.js";
import { Loader } from "../UI/loader/Loader.js";

// svgs
import StickyNoteIcon from "../../assets/svg/StickyNote2.js";
import ReferralPattern from "../../assets/svg/ReferralPattern.js";
import ReferralTreeSvg from "../../assets/svg/ReferralTreeSvg.js";
import ReferralBackSvg from "../../assets/svg/ReferralBackSvg.js";

// hooks
import { useMobileWidth } from "../../hooks/useMobileWidth.js";

import "./Referral.css";

export const Referral = ({
  referralHistoryTableHead,
  rebatesTableData,
  referralHistoryTableEmpty,
  referralRebatesTotal,
  referralHistoryTableLoading,
  handleLevelSystem,
  referralBinaryTableEmpty,
  totalBinaryMembers,
  referralHistoryPaginationCurrent,
  referralHistoryPaginationTotal,
  referralHistoryPaginationEvent,
  referralTreeData,
  referralTreeAddClick,
  referralTreeUserClick,
  referralTreeUserBackClick,
  referralBinaryType,
  referralTreeBtnsLeft,
  referralTreeBtnsRight,
  referralTreeActive,
  referralTreeActiveAddress,
  referralAddress,
  referralTreeTableData,
  referralTreeTableHead,
  referralTableType,
  referralHistoryButtonsRight,
  referralHistoryTableType,
  referralTableTitle,
  referralBackActive,
  isActive,
  uniLVLData,
  disabledAccount,
  referralTreeMainAddressData,
  tableFilterData,
  setTableFilterOutcomingData,
  tableSearchSelect,
  tableHeader,
  helpSupportClick,
  binaryComissionCountUser,
  referralLeftRight,
  setReferralLeftRight,
}) => {
  const [mobileExpand, setMobileExpand] = useState(null);
  const [refItemsCount, setRefItemsCount] = useState(3);
  const [treeInfo, setTreeInfo] = useState(null);
  const [animateTree, setAnimateTree] = useState(false);
  const [activeTreeInfo, setActiveTreeInfo] = useState(null);
  const [activeAddCopy, setActiveAddCopy] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [noBackState, setNoBackState] = useState(false);

  const { width } = useMobileWidth();

  const toLocaleStringForNumber = (num) => {
    return (num ?? 0).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  let infoObject = [];

  const generateInfoObject = (item, referralData, noBack) => {
    if (!item || !item.user_address) return null;

    if (
      noBack ||
      (item.user_address === referralTreeActiveAddress?.user_address &&
        referralTreeActiveAddress?.isParent)
    ) {
      infoObject = [
        {
          title: "Name",
          amount: item?.name ?? "no name",
          icon: "",
        },
        {
          title: "Main Address",
          amount: item.external_address ?? "no address",
          icon: "",
        },
        {
          title: "Total Staked",
          amount: toLocaleStringForNumber(item?.total_staked) + " AONE",
          icon: "",
        },
        {
          title: "Expected Reward",
          amount:
            !referralData?.all_amount_sum &&
            referralData?.all_amount_sum !== 0 ? (
              <Loader />
            ) : (
              referralData?.all_amount_sum + " AONE"
            ),
          icon: "",
        },
        {
          title: "Total Right",
          amount:
            !referralData?.all_amount_sum &&
            referralData?.all_amount_sum !== 0 ? (
              <Loader />
            ) : (
              referralData?.total_right + " AONE"
            ),
          icon: "",
        },
        {
          title: "Total Left",
          amount:
            !referralData?.all_amount_sum &&
            referralData?.all_amount_sum !== 0 ? (
              <Loader />
            ) : (
              referralData?.left_total + " AONE"
            ),
          icon: "",
        },
      ];
    } else {
      infoObject = [
        {
          title: "Name",
          amount:
            item?.joinedAccountMetas?.length > 0
              ? item.joinedAccountMetas[0].name
              : "no name",
          icon: "",
        },
        {
          title: "Main Address",
          amount: item.joinedAccounts?.[0]?.address ?? "no address",
          icon: "",
        },
        {
          title: "Position",
          amount: item.side,
          icon: "",
        },
        {
          title: "Total Staked",
          amount:
            toLocaleStringForNumber(item.joinedAccounts?.[0]?.stakedTotal) +
            " AONE",
          icon: "",
        },
        {
          title: "Expected Reward",
          amount:
            !referralData?.all_amount_sum &&
            referralData?.all_amount_sum !== 0 ? (
              <Loader />
            ) : (
              referralData?.all_amount_sum + " AONE"
            ),
          icon: "",
        },
        {
          title: "Total Right",
          amount:
            !referralData?.all_amount_sum &&
            referralData?.all_amount_sum !== 0 ? (
              <Loader />
            ) : (
              referralData?.total_right + " AONE"
            ),
          icon: "",
        },
        {
          title: "Total Left",
          amount:
            !referralData?.all_amount_sum &&
            referralData?.all_amount_sum !== 0 ? (
              <Loader />
            ) : (
              referralData?.left_total + " AONE"
            ),
          icon: "",
        },
      ];
    }

    return infoObject;
  };

  const openTreeInfo = (item, noBack) => {
    if (item == null) {
      setActiveTreeInfo(null);
      setTreeInfo(null);
      setCurrentItem(null);
      setNoBackState(false);
      return false;
    }
    if (item.user_address !== null) {
      setActiveTreeInfo(item?.user_address);
      setCurrentItem(item);
      setNoBackState(noBack);
      binaryComissionCountUser(item?.user_address);
      const infoObject = generateInfoObject(item, referralLeftRight, noBack);
      setTreeInfo(infoObject);
    }
  };

  useEffect(() => {
    if (currentItem && referralLeftRight) {
      const infoObject = generateInfoObject(
        currentItem,
        referralLeftRight,
        noBackState
      );
      setTreeInfo(infoObject);
    }
  }, [referralLeftRight, currentItem, noBackState]);

  let addCopy = (item) => {
    if (item == null) {
      setActiveAddCopy(null);
      return false;
    }
    if (item.lvl !== null) {
      setActiveAddCopy(item.lvl + "_" + item.position);
      //  + item.position);
      //  + item.position);
      setTimeout(() => {
        setActiveAddCopy(null);
      }, 1500);
    }
  };

  useEffect(() => {
    if (referralBinaryType == "visual") {
      setAnimateTree(true);
    } else {
      setAnimateTree(false);
    }
  }, [referralBinaryType]);

  let mobileExpandFunc = (id) => {
    if (width <= 1300) {
      if (id !== mobileExpand) {
        setMobileExpand(id);
      } else {
        setMobileExpand(null);
      }
    }
  };

  let mobile = width <= 1300;

  let referralTreeTableDataBinaryBody;

  let filter;
  filter = (
    <FilterBox
      tableFilterData={tableFilterData}
      setTableFilterOutcomingData={setTableFilterOutcomingData}
      tableSearchSelect={tableSearchSelect}
      tableHeader={tableHeader}
      customStyles={{ marginBottom: "20px", marginTop: "20px" }}
    />
  );

  if (referralTableType == "binary") {
    referralTreeTableDataBinaryBody = referralTreeTableData?.map(
      (item, index) => {
        const createdAt = new Date(item?.createdAt);
        const createdTime = createdAt.toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
        return (
          <div
            className={`table-parent ${
              mobileExpand == item._id ? "active" : ""
            } dashboard-table-parent`}
            key={index + item?.createdAt}
            onClick={() => {
              mobileExpandFunc(item._id);
            }}
          >
            <div className="table">
              <div
                className={`td col ${
                  referralTreeTableHead[0].mobileWidth ? true : false
                } dashboard-td`}
                style={{
                  width: `${
                    mobile
                      ? referralTreeTableHead[0].mobileWidth
                      : referralTreeTableHead[0].width
                  }%`,
                }}
              >
                <span>
                  {item.joinedAccountMetas.length > 0
                    ? item.joinedAccountMetas[0].name
                    : "No Name"}
                </span>
                <span>{item?.user_address}</span>
              </div>
              <div
                className={`td ${
                  referralTreeTableHead[1].mobileWidth ? true : false
                } dashboard-td`}
                style={{
                  width: `${
                    mobile
                      ? referralTreeTableHead[1].mobileWidth
                      : referralTreeTableHead[1].width
                  }%`,
                }}
              >
                <span>{"Lvl " + item.lvl + "/" + item.side}</span>
              </div>
              <div
                className={`td ${
                  referralTreeTableHead[2].mobileWidth ? true : false
                } dashboard-td`}
                style={{
                  width: `${
                    mobile
                      ? referralTreeTableHead[2].mobileWidth
                      : referralTreeTableHead[2].width
                  }%`,
                }}
              >
                <span>
                  {item.joinedAccounts.length > 0 &&
                  item.joinedAccounts[0].stakedTotal > 0
                    ? item.joinedAccounts[0].stakedTotal?.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    : 0}{" "}
                  AONE
                </span>
              </div>
              <div
                className={`td ${
                  referralTreeTableHead[3].mobileWidth ? true : false
                } dashboard-td`}
                style={{
                  width: `${
                    mobile
                      ? referralTreeTableHead[3].mobileWidth
                      : referralTreeTableHead[3].width
                  }%`,
                }}
              >
                <span>{createdTime}</span>
              </div>
            </div>
            <div className="table-more" />
            <div className="icon-place" style={{ height: "40px" }}>
              <svg
                width="12"
                height="7"
                viewBox="0 0 12 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.299 1.33325L6.47141 5.16089C6.01937 5.61293 5.27968 5.61293 4.82764 5.16089L1 1.33325"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="table-mobile">
              <div className="table-mobile-content">
                <div className="td">
                  <div className="mobile-ttl">User Level / Position</div>
                  <span>{"Lvl " + item.lvl + "/" + item.side}</span>
                </div>
                <div className="td">
                  <div className="mobile-ttl">Joining Date</div>
                  <span>{createdTime}</span>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );
  }
  if (referralTableType == "uni") {
    referralTreeTableDataBinaryBody = referralTreeTableData?.map(
      (item, index) => {
        const createdAt = new Date(item?.createdAt);
        const createdTime = createdAt.toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
        return (
          <div
            className={`table-parent ${
              mobileExpand == item._id ? "active" : ""
            } dashboard-table-parent`}
            key={item?.createdAt + index}
            onClick={() => {
              mobileExpandFunc(item._id);
            }}
          >
            <div className="table">
              <div
                className={`td col ${
                  referralTreeTableHead[0].mobileWidth ? true : false
                } dashboard-td`}
                style={{
                  width: `${
                    mobile
                      ? referralTreeTableHead[0].mobileWidth
                      : referralTreeTableHead[0].width
                  }%`,
                }}
              >
                <span>
                  {item.joinedAccountMetas.length > 0
                    ? item.joinedAccountMetas[0].name
                    : "No Name"}
                </span>
                <span>{item?.user_address}</span>
              </div>
              <div
                className={`td ${
                  referralTreeTableHead[1].mobileWidth ? true : false
                } dashboard-td`}
                style={{
                  width: `${
                    mobile
                      ? referralTreeTableHead[1].mobileWidth
                      : referralTreeTableHead[1].width
                  }%`,
                }}
              >
                <span>{"Lvl " + item.lvl}</span>
              </div>
              <div
                className={`td ${
                  referralTreeTableHead[2].mobileWidth ? true : false
                } dashboard-td`}
                style={{
                  width: `${
                    mobile
                      ? referralTreeTableHead[2].mobileWidth
                      : referralTreeTableHead[2].width
                  }%`,
                }}
              >
                <span>{uniLVLData?.[item?.lvl - 1] ?? 0}%</span>
              </div>
              <div
                className={`td ${
                  referralTreeTableHead[3].mobileWidth ? true : false
                } dashboard-td`}
                style={{
                  width: `${
                    mobile
                      ? referralTreeTableHead[3].mobileWidth
                      : referralTreeTableHead[3].width
                  }%`,
                }}
              >
                <span>
                  {item.joinedAccounts.length > 0 &&
                  item.joinedAccounts[0].stakedTotal > 0
                    ? item.joinedAccounts[0].stakedTotal
                    : 0}{" "}
                  AONE
                </span>
              </div>
              <div
                className={`td ${
                  referralTreeTableHead[4].mobileWidth ? true : false
                } dashboard-td`}
                style={{
                  width: `${
                    mobile
                      ? referralTreeTableHead[4].mobileWidth
                      : referralTreeTableHead[4].width
                  }%`,
                }}
              >
                <span>
                  {item?.joinedTransactions?.[0]?.totalAmount ?? 0} AONE
                </span>
              </div>
              <div
                className={`td ${
                  referralTreeTableHead[5].mobileWidth ? true : false
                } dashboard-td`}
                style={{
                  width: `${
                    mobile
                      ? referralTreeTableHead[5].mobileWidth
                      : referralTreeTableHead[5].width
                  }%`,
                }}
              >
                <span>{createdTime}</span>
              </div>
            </div>
            <div className="table-more" />
            <div className="icon-place" style={{ height: "40px" }}>
              <svg
                width="12"
                height="7"
                viewBox="0 0 12 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.299 1.33325L6.47141 5.16089C6.01937 5.61293 5.27968 5.61293 4.82764 5.16089L1 1.33325"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="table-mobile">
              <div className="table-mobile-content">
                <div className="td">
                  <div className="mobile-ttl">User Level</div>
                  <span>{"Lvl " + item.lvl}</span>
                </div>
                <div className="td">
                  <div className="mobile-ttl">Rate</div>
                  <span>{uniLVLData?.[item?.lvl - 1] ?? 0}%</span>
                </div>
                <div className="td">
                  <div className="mobile-ttl">Joining Date</div>
                  <span>{createdTime}</span>
                </div>
                {/*<div className='td'>*/}
                {/*  <div className='mobile-ttl'>{referralCodeTableHead[3].name}</div>*/}
                {/*  <span>{item._id.percent}</span>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        );
      }
    );
  }

  const tables = [
    {
      type: referralHistoryTableType,
      header: translates.referral_history.en,
      description: translates.history_of.en,
      tableHeader: referralHistoryTableHead,
      data: rebatesTableData,
      tableEmpty: referralHistoryTableEmpty,
      loading: referralHistoryTableLoading,
      tablePagination: true,
      tableButtons: referralHistoryButtonsRight,
      paginationCurrent: referralHistoryPaginationCurrent,
      paginationTotal: referralHistoryPaginationTotal,
      paginationEvent: referralHistoryPaginationEvent,
    },
  ];

  const tableFooterPagination = (
    <div
      className={"dashboard-table-footer alo"}
      style={
        {
          // display: `${codesTableData?.length ? "flex" : "none"}`,
          // padding: `${codesTableData?.length ? "20px" : "0px"}`,
        }
      }
    >
      {/*<TableElement*/}
      {/*  color={"#C38C5C"}*/}
      {/*  type={"pagination"}*/}
      {/*  currentPage={referralTreePaginationCurrent}*/}
      {/*  totalCount={referralTreePaginationTotal}*/}
      {/*  onPageChange={referralTreePaginationEvent}*/}
      {/*/>*/}
    </div>
  );
  const rewardBox = [
    {
      title: "Current Stake",
      amount: "1,220.2 CML",
      icon: false,
    },
    {
      title: "Earn",
      amount: "1,020 CML",
      icon: false,
    },
    {
      title: "Claimed Reward",
      amount: "1,132.1 CML",
      icon: false,
    },
  ];

  useEffect(() => {
    if (mobile) {
      setRefItemsCount(2);
    }
  });

  return (
    <div
      className={`referral-main-wrap ${disabledAccount ? "disabled-page" : ""}`}
    >
      {disabledAccount && <DisabledPage />}
      <div className={"referral-main"}>
        <div className="referral-content">
          <div className="referral-content-container">
            <div className={"referral-content-main"}>
              <h1 className="main_ttl font-60 colorGold">
                {translates?.referral.en}
              </h1>
              <div className="referral-content-info">
                <span className="font-20">
                  {translates?.you_can_earn_rebates.en}
                </span>
                <p className="font-16">
                  {/* {translates?.after_creating_your_first_code.en} need new text */}
                </p>
                <div className="referral-content-info_buttons">
                  <Link
                    to={`/uni-level-stages`}
                    className="btn btn-primary referral-button"
                  >
                    LayerLink Details
                  </Link>
                  <Link
                    to={`/binary-stages`}
                    className="btn btn-primary referral-button"
                  >
                    PairLink Details
                  </Link>
                  <Button
                    element={"referral-button"}
                    label={"Level System"}
                    icon={<StickyNoteIcon className={"referral-button-icon"} />}
                    active={true}
                    // onClick={handleLevelSystem}
                  />
                </div>
              </div>

              <ReferralPattern className={"referral-content-svg"} />
            </div>
            <div className="referral-card-container">
              <ReferralCard
                type={"total-info"}
                referral={referralAddress}
                totalData={referralRebatesTotal}
                label={translates.your_referral_code.en}
                labelTwo={translates.total_referral_rebates.en}
              />
            </div>
          </div>
          <div className="referral-content-bg" />
        </div>
        <div className="referral-binary-wrapper">
          <div className="referral-table-container">
            <Table
              tableHeadMore={
                <div className="dashboard-table-header-container">
                  <Visual
                    element={"table-header"}
                    label={referralTableTitle}
                    // description={`Total Downline Members: ${totalBinaryMembers}`}
                    fontSize={"font-20"}
                    customStyles={{
                      border: "none",
                      padding: "0",
                      width: "100%",
                    }}
                    buttons={referralTreeBtnsRight}
                    labelCustomStyles={{ color: "#C38C5C" }}
                    centerButtons={true}
                    buttonsLeft={referralTreeBtnsLeft}
                  />
                  {referralTableType == "uni" && filter}
                </div>
              }
              tableData={
                referralTreeTableDataBinaryBody?.length > 0
                  ? referralTreeTableDataBinaryBody
                  : false
              }
              tableFooter={tableFooterPagination}
              tableHead={referralTreeTableHead}
              customStyles={{
                border: "1px solid rgba(255, 255, 255, 0.1)",
                borderRadius: "20px",
                background: "rgba(255, 255, 255, 0.01)",
                backdropFilter: "blur(5px)",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
              customHeadStyles={{
                height: "81px",
                alignItems: "center",
              }}
              tableEmptyData={referralBinaryTableEmpty}
              loading={false}
              tableEmulator={
                referralBinaryType === "visual" ? (
                  <div className="referral-tree">
                    <div
                      className={`referral-tree-main ${
                        animateTree && referralTreeActive ? "active" : ""
                      }`}
                    >
                      {referralTreeActive && (
                        <div className="referral-tree-item-level referral-tree-item-level-active">
                          <div
                            className={`referral-tree-item`}
                            style={{ width: 100 + "%" }}
                          >
                            <div
                              className="referral-tree-btn"
                              // onClick={referralTreeUserBackClick}
                              onMouseOver={() => {
                                openTreeInfo(
                                  referralBackActive
                                    ? referralTreeActiveAddress
                                    : referralTreeMainAddressData,
                                  !referralBackActive
                                );
                              }}
                              onMouseLeave={() => {
                                openTreeInfo(null);
                                setReferralLeftRight(null);
                              }}
                            >
                              {treeInfo !== null && (
                                <div
                                  className="referral-tree-info referral-tree-info-main"
                                  style={{ right: mobile ? "0" : "120px" }}
                                >
                                  <InfoBox
                                    type="reward-box"
                                    active={
                                      activeTreeInfo ==
                                        referralTreeActiveAddress.user_address &&
                                      animateTree
                                        ? true
                                        : false
                                    }
                                    cardBody={treeInfo}
                                    customStyle={{ width: "100%" }}
                                  />
                                </div>
                              )}
                              {/* {referralBackActive && (
                              <div className="referral-tree-item-level-active-back">
                                <span>
                                  <ReferralBackSvg />
                                  Back
                                </span>
                              </div>
                            )} */}
                              <div className="referral-tree-btn-out">
                                <div className={`referral-tree-btn-img`}>
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                    alt="hi"
                                  />
                                </div>
                              </div>
                              <div className={`referral-tree-btn-hash-out`}>
                                <div className="referral-tree-btn-hash">
                                  <span>
                                    {referralTreeActiveAddress.user_address}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {referralTreeActive ? (
                        referralTreeData?.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              {index < refItemsCount && (
                                <div
                                  className="referral-tree-item-level"
                                  key={index}
                                >
                                  <div className="referral-tree-lines">
                                    {item.documents.map((suItem, index) => {
                                      return (
                                        <Fragment key={index}>
                                          {index % 2 == 0 && (
                                            <ReferralTreeSvg
                                              suItem={suItem}
                                              index={index}
                                              item={item}
                                              // key={suItem.type + index / 0.1}
                                            />
                                          )}
                                        </Fragment>
                                      );
                                    })}
                                  </div>
                                  <div className="referral-tree-items">
                                    {item.documents.map((suItem, index) => {
                                      return (
                                        <Fragment key={index}>
                                          {suItem.type == "missing" && (
                                            <div
                                              className={`referral-tree-item`}
                                              key={`${suItem.type} ${index}`}
                                              style={{
                                                width:
                                                  100 / item.documents.length +
                                                  "%",
                                              }}
                                            >
                                              <div
                                                className={`referral-tree-btn referral-tree-btn-add ${
                                                  !isActive
                                                    ? "referral-tree-btn-disabled"
                                                    : ""
                                                }`}
                                              >
                                                <div
                                                  className="referral-tree-btn-out"
                                                  onClick={() => {
                                                    referralTreeAddClick(
                                                      suItem.lvl,
                                                      suItem.position
                                                    );
                                                    addCopy(suItem);
                                                  }}
                                                >
                                                  <div
                                                    className={`referral-tree-btn-img ${
                                                      suItem.lvl == 3
                                                        ? "referral-tree-btn-img-sm"
                                                        : ""
                                                    }`}
                                                  >
                                                    <div
                                                      className={`copied ${
                                                        activeAddCopy ==
                                                        suItem.lvl +
                                                          "_" +
                                                          suItem.position
                                                          ? // + suItem.position
                                                            "active"
                                                          : ""
                                                      }`}
                                                    >
                                                      Copied
                                                    </div>
                                                    <svg
                                                      width="14"
                                                      height="14"
                                                      viewBox="0 0 14 14"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M12.8947 5.89474H8.10526V1.10526C8.10526 0.501053 7.60421 0 7 0C6.39579 0 5.89474 0.501053 5.89474 1.10526V5.89474H1.10526C0.501053 5.89474 0 6.39579 0 7C0 7.60421 0.501053 8.10526 1.10526 8.10526H5.89474V12.8947C5.89474 13.4989 6.39579 14 7 14C7.60421 14 8.10526 13.4989 8.10526 12.8947V8.10526H12.8947C13.4989 8.10526 14 7.60421 14 7C14 6.39579 13.4989 5.89474 12.8947 5.89474Z"
                                                        fill="#C38C5C"
                                                      />
                                                    </svg>
                                                  </div>
                                                </div>
                                                <div
                                                  className={`referral-tree-btn-hash-out`}
                                                >
                                                  <div className="referral-tree-btn-hash">
                                                    <span>Add</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {!suItem.type && (
                                            <div
                                              key={suItem.type + index}
                                              className={`referral-tree-item`}
                                              style={{
                                                width:
                                                  100 / item.documents.length +
                                                  "%",
                                              }}
                                            >
                                              <div
                                                className={`referral-tree-btn`}
                                                onClick={() => {
                                                  referralTreeUserClick(
                                                    suItem,
                                                    "child"
                                                  );
                                                }}
                                                onMouseOver={() => {
                                                  openTreeInfo(suItem);
                                                }}
                                                onMouseLeave={() => {
                                                  openTreeInfo(null);
                                                  setReferralLeftRight(null);
                                                }}
                                              >
                                                {treeInfo !== null && (
                                                  <div
                                                    className="referral-tree-info"
                                                    style={
                                                      suItem.side == "left"
                                                        ? {
                                                            left: mobile
                                                              ? "80px"
                                                              : "120px",
                                                          }
                                                        : {
                                                            right: mobile
                                                              ? "80px"
                                                              : "120px",
                                                          }
                                                    }
                                                  >
                                                    <InfoBox
                                                      type="reward-box"
                                                      active={
                                                        activeTreeInfo ==
                                                          suItem.user_address &&
                                                        animateTree
                                                          ? true
                                                          : false
                                                      }
                                                      cardBody={treeInfo}
                                                      customStyle={{
                                                        width: "100%",
                                                      }}
                                                    />
                                                  </div>
                                                )}

                                                <div className="referral-tree-btn-out">
                                                  <div
                                                    className={`referral-tree-btn-img ${
                                                      suItem.lvl == 3
                                                        ? "referral-tree-btn-img-sm"
                                                        : ""
                                                    }`}
                                                  >
                                                    <img
                                                      src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                                      alt="hi"
                                                    />
                                                  </div>
                                                </div>
                                                <div
                                                  className={`referral-tree-btn-hash-out`}
                                                >
                                                  <div className="referral-tree-btn-hash">
                                                    <span>
                                                      {suItem.user_address}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {suItem.type == "nothing" && (
                                            <div
                                              key={suItem.type + index}
                                              className={`referral-tree-item`}
                                              style={{
                                                width:
                                                  100 / item.documents.length +
                                                  "%",
                                              }}
                                            ></div>
                                          )}
                                        </Fragment>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </Fragment>
                          );
                        })
                      ) : (
                        <Loader
                          customStyles={{
                            position: "absolute",
                            height: "45px",
                            width: "45px",
                            left: "calc(50% - 20px",
                            top: "calc(65%",
                          }}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  false
                )
              }
            />
          </div>
        </div>
        {rebatesTableData.length > 0 && (
          <div className="referral-tables-container">
            {tables?.map((item, index) => (
              <DashboardTable
                key={index + item?.description}
                type={item?.type}
                header={item?.header}
                description={item?.description}
                footer={item?.footer}
                rightPanelData={item?.rightPanelData}
                tableHeader={item?.tableHeader}
                referralCardsData={item?.referralCardsData}
                data={item?.data}
                tableEmpty={item?.tableEmpty}
                loading={item?.loading}
                tableButtons={item?.tableButtons}
                tableEmptyValue={item?.tableEmptyValue}
                tablePagination={item?.tablePagination}
                paginationCurrent={item?.paginationCurrent}
                paginationTotal={item?.paginationTotal}
                paginationEvent={item?.paginationEvent}
              />
            ))}
          </div>
        )}
        <Footer helpSupportClick={helpSupportClick} />
      </div>
    </div>
  );
};
