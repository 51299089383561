export let referralHistoryThUni = [
  {
    name: "User Address",
    width: 15,
    mobileWidth: 40,
    id: 0,
  },
  {
    name: "User Level",
    width: 15,
    id: 1,
  },
  {
    name: "Amount",
    width: 15,
    mobileWidth: 50,
    id: 2,
  },
  {
    name: "Status",
    width: 15,
    id: 3,
  },
  {
    name: "Date",
    width: 15,
    id: 4,
  },
];

export let referralHistoryThBinary = [
  {
    name: "Amount",
    width: 15,
    mobileWidth: 45,
    id: 0,
  },
  {
    name: "Position",
    width: 15,
    mobileWidth: 50,
    id: 1,
  },
  {
    name: "Status",
    width: 15,
    id: 2,
  },
  {
    name: "Date",
    width: 15,
    id: 3,
  },
];

export const referralTableTypeBinary = [
  {
    name: "Member Name",
    width: 15,
    mobileWidth: 40,
    id: 0,
  },
  {
    name: "User Level / Position",
    width: 15,
    id: 1,
  },
  {
    name: "Total Staked",
    width: 15,
    mobileWidth: 30,
    id: 2,
  },
  {
    name: "Joining Date",
    width: 15,
    id: 3,
  },
];

export const referralTableTypeUni = [
  {
    name: "Member Name",
    width: 15,
    mobileWidth: 40,
    id: 0,
  },
  {
    name: "User Level",
    width: 15,
    id: 1,
  },
  {
    name: "Rate",
    width: 15,
    id: 2,
  },
  {
    name: "Total Staked",
    width: 15,
    mobileWidth: 30,
    id: 3,
  },
  {
    name: "Total Earned",
    width: 15,
    mobileWidth: 30,
    id: 4,
  },
  {
    name: "Joining Date",
    width: 15,
    id: 5,
  },
];
