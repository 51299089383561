import React from "react";
import Arrow from "../../assets/svg/Arrow";

import styles from "./BinaryStagesTable.module.css";

const BinaryStagesTable = () => {
  const tiers = [
    {
      left: { top: "5,000", bottom: "100,000" },
      right: { top: "5,000", bottom: "100,000" },
      commission: "500",
    },
    {
      left: { top: "105,000", bottom: "300,000" },
      right: { top: "105,000", bottom: "300,000" },
      commission: "300",
    },
    {
      left: { top: "305,000", bottom: "3,000,000" },
      right: { top: "305,000", bottom: "3,000,000" },
      commission: "100",
    },
  ];

  return (
    <div className={styles.greenBackground}>
      <div className={styles.pageContainer}>
        <h2 className={styles.header}>PairLink Reward Distribution:</h2>
        <div className={styles.tableWrapper}>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              PairLink Reward is calculated based on every "5,000 to 5,000 USD"
              matching.
            </li>
            <li className={styles.listItem}>
              The matching amount exceeding 3,000,000 will be flushed.
            </li>
            <li className={styles.listItem}>
              A monthly flush will occur for the unmatched balances.
            </li>
          </ul>
          <div className={styles.tableContainer}>
            <div className={styles.column}>
              <div className={styles.columnHeader}>LEFT (USD)</div>
              {tiers.map((tier, index) => (
                <div key={index} className={styles.cell}>
                  <span>{tier.left.top}</span>
                  <Arrow />
                  <span>{tier.left.bottom}</span>
                </div>
              ))}
            </div>
            <div className={styles.column}>
              <div className={styles.columnHeader}>RIGHT (USD)</div>
              {tiers.map((tier, index) => (
                <div key={index} className={styles.cell}>
                  <span>{tier.right.top}</span>
                  <Arrow />
                  <span>{tier.right.bottom}</span>
                </div>
              ))}
            </div>
            <div className={styles.column}>
              <div className={styles.columnHeader}>REWARD (USD)</div>
              {tiers.map((tier, index) => (
                <div key={index} className={styles.cell}>
                  {tier.commission}
                </div>
              ))}
            </div>
          </div>
        </div>
        <p className={styles.note}>
          * A monthly flush will occur for the unmatched balances.
        </p>
      </div>
    </div>
  );
};

export default BinaryStagesTable;
