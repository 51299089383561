import React from "react";

const ScreenSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 276.779 188.319"
      width={276.779}
      height={188.319}
      data-qa="empty-screen-icon"
    >
      <g transform="translate(1.5 1.5)">
        <path
          d="M367.427 574.92H313.07l4.108-3.833a8.134 8.134 0 0 0 2.583-5.941v-16.1h40.979v16.1a8.128 8.128 0 0 0 2.577 5.941z"
          fill="none"
          transform="translate(-203.449 -389.6)"
        ></path>
        <path
          d="M278.279 475.93v11.727c0 5.347-5.051 9.68-11.282 9.68H15.782c-6.234 0-11.282-4.337-11.282-9.68V475.93zM146.1 485.859c0-2.24-2.111-4.055-4.713-4.055s-4.713 1.813-4.713 4.055 2.111 4.047 4.713 4.047 4.713-1.813 4.713-4.047z"
          fill="none"
          transform="translate(-4.5 -337.891)"
        ></path>
        <path
          d="M278.279 14.18v128.359H4.5V14.18c0-5.343 5.051-9.68 11.282-9.68h251.211c6.238 0 11.286 4.337 11.286 9.68z"
          fill="none"
          transform="translate(-4.5 -4.5)"
        ></path>
        <path
          d="M396.107 495.99a4.051 4.051 0 1 1-4.047 4.055 4.049 4.049 0 0 1 4.047-4.055z"
          fill="none"
          transform="translate(-259.291 -352.077)"
        ></path>
        <path
          d="M278.279 142.539v11.727c0 5.347-5.051 9.68-11.282 9.68H15.782c-6.234 0-11.282-4.337-11.282-9.68V14.18c0-5.343 5.051-9.68 11.282-9.68h251.211c6.234 0 11.282 4.337 11.282 9.68v128.359z"
          fill="none"
          stroke="#334656"
          stroke-miterlimit="10"
          stroke-width="3"
          transform="translate(-4.5 -4.5)"
        ></path>
        <path
          d="M4.5 475.93h274.669"
          fill="none"
          stroke="#334656"
          stroke-miterlimit="10"
          stroke-width="3"
          transform="translate(-4.5 -337.891)"
        ></path>
        <g transform="translate(90.882 159.447)">
          <path
            d="M319.751 549.04v16.1a8.134 8.134 0 0 1-2.583 5.941l-4.108 3.833"
            fill="none"
            stroke="#334656"
            stroke-miterlimit="10"
            stroke-width="3"
            transform="translate(-294.349 -549.04)"
          ></path>
          <path
            d="M475.86 549.04v16.1a8.129 8.129 0 0 0 2.577 5.941l4.114 3.833"
            fill="none"
            stroke="#334656"
            stroke-miterlimit="10"
            stroke-width="3"
            transform="translate(-409.48 -549.04)"
          ></path>
          <path
            d="M249.16 637.4h91.775"
            fill="none"
            stroke="#334656"
            stroke-miterlimit="10"
            stroke-width="3"
            transform="translate(-249.16 -611.527)"
          ></path>
        </g>
        <path
          d="M400.153 500.045a4.047 4.047 0 1 1-4.047-4.055 4.048 4.048 0 0 1 4.047 4.055z"
          fill="none"
          stroke="#334656"
          stroke-miterlimit="10"
          stroke-width="3"
          transform="translate(-259.291 -352.077)"
        ></path>
      </g>
      <path
        fill="#334656"
        d="M0 90.968h15.839v-55.98H0zm26.4 0h15.836V0H26.4zm26.4 0h15.834V55.98H52.8zm26.4 0h15.831V20.993H79.193zm26.4-45.484v45.484h15.839V45.484z"
        transform="translate(77.91 26.989)"
      ></path>
    </svg>
  );
};

export default ScreenSvg;
