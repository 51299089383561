export const keyframes = {
  background: {
    0: {
      scale: 1,
      opacity: 1,
    },
    200: {
      scale: 10,
      opacity: 0,
    },
  },
  opacity: {
    200: {
      translateY: 0,
      opacity: 1,
    },
    300: {
      translateY: -50,
      opacity: 0,
    },
  },
  opacityIn: {
    400: {
      scale: 0.8,
      opacity: 0,
    },
    600: {
      scale: 1,
      opacity: 1,
    },
  },
  scaleOut: {
    0: {
      scale: 0,
      opacity: 0,
      translateY: -100,
    },
    100: {
      scale: 1,
      opacity: 1,
      translateY: 0,
    },
  },
  zoomOut1: {
    3000: {
      scale: 1.2,
      opacity: 0,
    },
    3200: {
      scale: 1,
      opacity: 1,
    },
  },
  zoomOut2: {
    3400: {
      scale: 1.2,
      opacity: 0,
    },
    3600: {
      scale: 1,
      opacity: 1,
    },
  },
  zoomOut3: {
    3800: {
      scale: 1.2,
      opacity: 0,
    },
    4000: {
      scale: 1,
      opacity: 1,
    },
  },
  zoomOut4: {
    4200: {
      scale: 1.2,
      opacity: 0,
    },
    4400: {
      scale: 1,
      opacity: 1,
    },
  },
};

export const faq = (translates) => [
  {
    question: translates?.what_is_this_platform_about.en,
    answer: translates?.atar_is_a_unique_trading_platform.en,
  },
  {
    question: translates?.who_can_use_this_platform.en,
    answer: translates?.our_platform_caters.en,
  },
  {
    question: translates?.what_kind_of_trading_accounts.en,
    answer: translates?.we_offer_four_types.en,
  },
  {
    question: translates?.what_is_staking.en,
    answer: translates?.staking_involves_locking.en,
  },
  {
    question: translates?.can_you_explain.en,
    answer: translates?.margin_trading_enhances.en,
  },
  {
    question: translates?.what_trading_instruments.en,
    answer: translates?.our_platform_offers_over.en,
  },
  {
    question: translates?.how_do_i_get_started.en,
    answer: translates?.simply_sign_up_using_your_email.en,
  },
  {
    question: translates?.can_i_take_loans.en,
    answer: translates?.yes_we_have_a_cutting_edge.en,
  },
  {
    question: translates?.what_educational_resources.en,
    answer: translates?.we_provide_a_comprehensive.en,
  },
  {
    question: translates?.do_you_have_a_referral_program.en,
    answer: translates?.yes_we_have_a_lucrative.en,
  },
  {
    question: translates?.what_is_the_atar_elite.en,
    answer: translates?.the_atar_elite_membership.en,
  },
];

export const howStart = (translates) => [
  {
    question: `1. ${translates?.sign_up.en}`,
    answer: translates?.creating_your_account_is_a_breeze.en,
  },
  {
    question: `2. ${translates?.link_wallet.en}`,
    answer: translates?.fund_your_trading_account.en,
  },
  {
    question: `3. ${translates?.fund_your_account.en}`,
    answer: translates?.transfer_the_necessary_funds.en,
  },
  {
    question: `4. ${translates?.stake_your_funds.en}`,
    answer: translates?.lock_your_funds_in_staking.en,
  },
  {
    question: `5. ${translates?.acquire_trading_credit.en}`,
    answer: translates?.receive_trading_credit.en,
  },
  {
    question: `6. ${translates?.utilize_leverage.en}`,
    answer: translates?.utilize_leverage_descr.en,
  },
];
