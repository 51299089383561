const INIT_STATE = {
  tradeData: [
    {
      symbol: "AUDCAD",
      sell: 0.19032,
      sellRise: false,
      buy: 0.23233,
      buyRise: true,
      charge: 0.04,
      chargeRise: true,
      favourites: true,
      _id: "65855f2c52403094f73cd9c0",
    },
    {
      symbol: "BTCUSD",
      sell: 0.19032,
      sellRise: false,
      buy: 0.23233,
      buyRise: true,
      charge: 0.01,
      chargeRise: false,
      favourites: false,
      _id: "65855eff52403094f73cd984",
    },
    {
      symbol: "BTCUSD",
      sell: 0.19032,
      sellRise: false,
      buy: 0.23233,
      buyRise: true,
      charge: 0.01,
      chargeRise: false,
      favourites: false,
      _id: "65855eff52403094f73cd985",
    },
    {
      symbol: "BTCUSD",
      sell: 0.19032,
      sellRise: false,
      buy: 0.23233,
      buyRise: true,
      charge: 0.01,
      chargeRise: false,
      favourites: false,
      _id: "65855eff52403094f73cd987",
    },
  ],
  positionsData: [
    {
      symbol: "AUDCAD",
      side: "Bought",
      size: 20,
      value: 50,
      profitLoss: -49.9,
      openPrice: 23,
      stopLoss: true,
      takeProfit: true,
      swap: 0,
      _id: "65855f2c52403094f73cd9c0",
    },
    {
      symbol: "AUDCAD",
      side: "Bought",
      size: 20,
      value: 50,
      profitLoss: -49.9,
      openPrice: 23,
      stopLoss: true,
      takeProfit: true,
      swap: 0,
      _id: "65855f2c52403094f73cd9c8",
    },
    {
      symbol: "AUDCAD",
      side: "Bought",
      size: 20,
      value: 50,
      profitLoss: -49.9,
      openPrice: 23,
      stopLoss: true,
      takeProfit: true,
      swap: 0,
      _id: "65855f2c52403094f73cd9c6",
    },
  ],
};

const tradeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_TRADE_DATA":
      return {
        ...state,
        tradeData: state.tradeData.map((item) =>
          item._id === action.payload._id
            ? { ...item, favourites: action.payload.favourites }
            : item
        ),
      };

    default:
      return state;
  }
};

export default tradeReducer;
