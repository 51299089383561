import React from "react";

const SuccessSvg = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45 90C20.1767 90 0 69.8233 0 45C0 20.1767 20.1767 0 45 0C69.8233 0 90 20.1767 90 45C90 69.8233 69.8233 90 45 90ZM45 6.27907C23.6512 6.27907 6.27907 23.6512 6.27907 45C6.27907 66.3488 23.6512 83.7209 45 83.7209C66.3488 83.7209 83.7209 66.3488 83.7209 45C83.7209 23.6512 66.3488 6.27907 45 6.27907Z"
        fill="#66BB6A"
      />
      <path
        d="M39.0565 59.984C38.2241 59.9832 37.4261 59.652 36.8379 59.063L24.9914 47.2165C24.4075 46.6257 24.0801 45.8285 24.0801 44.9979C24.0801 44.1673 24.4075 43.3701 24.9914 42.7793C26.2053 41.5654 28.2146 41.5654 29.4286 42.7793L39.0565 52.4072L60.5728 30.8909C61.7867 29.677 63.796 29.677 65.01 30.8909C66.2239 32.1049 66.2239 34.1142 65.01 35.3281L41.2751 59.063C40.6869 59.652 39.8889 59.9832 39.0565 59.984Z"
        fill="#66BB6A"
      />
    </svg>
  );
};

export default SuccessSvg;
