const Verify = () => {
    return (
        <svg width="130" height="94" viewBox="0 0 130 94" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.05" d="M120.755 70.8829C129.778 61.9635 134.776 27.6663 123.469 16.7829C106.283 0.239919 77.8441 10.0549 56.0811 1.58123C34.3182 -6.89242 2.16748e-05 20.3597 1.96422e-05 43.6094C1.72911e-05 70.5026 41.5476 95.1807 66.0309 93.6854C90.5143 92.19 110.353 81.1663 120.755 70.8829Z" fill="#9CCC65"/>
            <circle opacity="0.6" cx="118.75" cy="7.5" r="2.5" fill="#9CCC65"/>
            <circle opacity="0.4" cx="110" cy="83.75" r="2.5" fill="#9CCC65"/>
            <circle opacity="0.4" cx="13.125" cy="78.125" r="3.125" fill="#9CCC65"/>
            <circle opacity="0.8" cx="16.875" cy="23.125" r="1.875" fill="#9CCC65"/>
            <circle opacity="0.6" cx="18.75" cy="71.25" r="1.25" fill="#9CCC65"/>
            <path d="M54.0625 66.0937H75.9375C82.5 66.0937 86.875 62.8125 86.875 55.1562V39.8437C86.875 32.1875 82.5 28.9062 75.9375 28.9062H54.0625C47.5 28.9062 43.125 32.1875 43.125 39.8437V45.067" stroke="#9CCC65" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M39.8662 57.2005L45.3486 62.4005L56.3329 52.0005" stroke="#9CCC65" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M75.9375 40.9375L69.0906 46.4062C66.8375 48.2 63.1406 48.2 60.8875 46.4062L54.0625 40.9375" stroke="#9CCC65" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
  };
  
  export default Verify;
  