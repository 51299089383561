import React from "react";
import { Input } from "../../../../UI/input/Input";
import { HelpText } from "../../../../UI/helpText/HelpText";
import { Button } from "../../../../UI/button/Button";

const ConfirmList = ({
  amount,
  confirm,
  exchangeAccountType,
  rates,
  stakingLoading,
  handleStakeCurrency,
  sideBar,
  currentObject,
  card,
  handleExchangeSubmit,
  recepientName,
  handleTransferSubmit,
  transferSubmitLoading,
  transactionConfirmCode,
  setTransactionConfirmCode,
  transferCodeLoading,
  handleTransferCodeSumbit,
  verificationCodeModal,
  exchangeLoading,
  fee,
  transferType,
  ratedExchange,
  accountType,
}) => {
  const capitalizeString = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      {sideBar === "stake" ? (
        <div className="confirm-list">
          <div className="confirm-list-item">
            <span>Amount:</span>
            <span>{amount}</span>
          </div>
          <div className="confirm-list-item">
            <span>Currency:</span>
            <span>
              {exchangeAccountType === "ATAR"
                ? "AONE"
                : exchangeAccountType?.toUpperCase()}
            </span>
          </div>
          <div className="confirm-list-item">
            <span>Duration:</span>
            <span>{confirm}</span>
          </div>
          <div className="confirm-list-item">
            <span>APY:</span>
            <span>
              {
                rates?.stakingAPY[exchangeAccountType][
                  parseInt(confirm.split(" ")[0], 10)
                ]
              }{" "}
              %
            </span>
          </div>
          <div className="confirm-list-item">
            <span>Expires:</span>
            <span>
              {new Date(
                Date.now() +
                  parseInt(confirm?.split(" ")[0], 10) * 24 * 60 * 60 * 1000
              )?.toLocaleDateString()}
            </span>
          </div>
          <Button
            element={"button"}
            size={"btn-lg"}
            type={"btn-primary"}
            label={stakingLoading ? "Loading..." : "Confirm"}
            active={true}
            customStyles={{
              width: "100%",
            }}
            onClick={handleStakeCurrency}
            disabled={stakingLoading}
          />
        </div>
      ) : sideBar === "exchange" ? (
        <div className="confirm-list">
          <div className="confirm-list-item">
            <span>From Account:</span>
            <span>
              {exchangeAccountType === "ATAR"
                ? "AONE"
                : exchangeAccountType?.toUpperCase()}
            </span>
          </div>
          <div className="confirm-list-item">
            <span>Amount:</span>
            <span>{Number(currentObject.transfer_amount)}</span>
          </div>
          <div className="confirm-list-item">
            <span>Exchange To:</span>
            <span>{card.title === "ATAR" ? "AONE" : card.title}</span>
          </div>
          <div className="confirm-list-item">
            <span>Exchange Rate:</span>
            <span className="font-14">
              1 {accountType === "ATAR" ? "AONE" : accountType.toUpperCase()} ={" "}
              {ratedExchange && Number(ratedExchange) !== 0
                ? Number(Math.round((1 / ratedExchange) * 100000) / 100000)
                : "... "}{" "}
              {card?.title === "ATAR" ? "AONE" : card?.title}
            </span>
          </div>
          <div className="confirm-list-item">
            <span>Will Receive:</span>
            <span>{Number(currentObject.receive_amount)}</span>
          </div>
          <Button
            element={"button"}
            size={"btn-lg"}
            type={"btn-primary"}
            label={"Confirm"}
            active={true}
            customStyles={{
              width: "100%",
            }}
            disabled={exchangeLoading}
            onClick={handleExchangeSubmit}
          />
        </div>
      ) : sideBar === "transfer" ? (
        <div className="confirm-list">
          {!recepientName && currentObject.transferType === "external" ? (
            <span>
              <HelpText
                title={"No such user exists with provided address"}
                status={"warning"}
                icon={true}
              />
            </span>
          ) : (
            <>
              {currentObject.transferType === "external" && (
                <HelpText
                  title={"Email Verification Required for External Transfers"}
                  status={"warning"}
                  icon={true}
                />
              )}
              <div className="confirm-list-item">
                <span>Transfer Type:</span>
                <span>{capitalizeString(currentObject.transferType)}</span>
              </div>
              {currentObject.transferType === "external" && (
                <div className="confirm-list-item">
                  <span>Name:</span>
                  <span>{recepientName ?? ""}</span>
                </div>
              )}
              <div className="confirm-list-item">
                <span>To:</span>
                <span>
                  {currentObject.transferType === "external"
                    ? currentObject.transferAddress
                    : capitalizeString(currentObject.account)}
                </span>
              </div>
              <div className="confirm-list-item">
                <span>Amount:</span>
                <span>{currentObject.amount}</span>
              </div>
              {currentObject.transferType === "external" ? (
                <div className="confirm-list-item">
                  <span>Will Receive:</span>
                  <span>{currentObject.amount - fee}</span>
                </div>
              ) : (
                <div className="confirm-list-item">
                  <span>Will Receive:</span>
                  <span>{currentObject.amount}</span>
                </div>
              )}
              <Button
                element={"button"}
                size={"btn-lg"}
                type={"btn-primary"}
                label={transferSubmitLoading ? "Loading..." : "Confirm"}
                active={true}
                customStyles={{
                  width: "100%",
                }}
                onClick={handleTransferSubmit}
                disabled={transferSubmitLoading}
              />
            </>
          )}
        </div>
      ) : verificationCodeModal ? (
        <div className="confirm-list">
          <Input
            type={"default"}
            emptyFieldErr={false}
            inputType={"text"}
            value={transactionConfirmCode}
            placeholder={"code"}
            label={`Enter your code`}
            onChange={(e) => setTransactionConfirmCode(e.target.value)}
          />
          <Button
            element={"button"}
            size={"btn-lg"}
            type={"btn-primary"}
            label={transferCodeLoading ? "Loading" : "Enter Code"}
            active={true}
            customStyles={{
              width: "100%",
            }}
            onClick={() => handleTransferCodeSumbit(transactionConfirmCode)}
            disabled={transferCodeLoading}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ConfirmList;
