import { useState } from "react";
import { useMobileWidth } from "../../../hooks/useMobileWidth";
import CopySvg from "../../../assets/svg/CopySvg";
import CopyHoverSvg from "../../../assets/svg/CopyHoverSvg";
import { Loader } from "../loader/Loader";

import "./ReferralCard.css";

export const ReferralCard = ({
  type,
  label,
  item,
  customStyles,
  labelTwo,
  totalData,
  referral,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const { width } = useMobileWidth();

  const handleCopy = () => {
    navigator.clipboard
      .writeText(referral)
      .then(() => {
        console.log("Text copied to clipboard");
        setShowTooltip(true);
        setShowCopiedMessage(true);
        setTimeout(() => {
          setShowTooltip(false);
        }, 1000);
        setTimeout(() => {
          setShowCopiedMessage(false);
        }, 1500);
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  let element = null;

  if (type === "default") {
    element = (
      <div className={"referral-card-wrapper"} style={customStyles}>
        <div className={"referal-card-body"}>
          <h2 className={"font-20"}>{item.label}</h2>
          <p className={"font-16"}>{item.description}</p>
        </div>
        {item.button}
      </div>
    );
  }

  if (type === "total-info") {
    element = (
      <div className={"total-referral-info-container"} style={customStyles}>
        <div className={`total-referral-info`}>
          <h2 className="font-20">{label}</h2>
          <div
            className="referral-copy-container"
            onClick={handleCopy}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => {
              if (!showCopiedMessage) setShowTooltip(false);
            }}
          >
            <p className={`${width <= 767 ? "font-14" : "font-16"}`}>
              {referral}
            </p>
            <CopySvg />
          </div>
          <div
            className={`referral-tooltip ${
              showTooltip ? "referral-tooltip-visible" : ""
            }`}
          >
            <CopyHoverSvg />
            <p className="font-14">
              {showCopiedMessage ? "Copied!" : "Copy to Clipboard"}
            </p>
          </div>
        </div>
        <div className={"total-referral-rebates"}>
          <h2 className="font-20">{labelTwo}</h2>
          {totalData.map((item, index) => (
            <div key={index} className="total-referral-rebates-div">
              <span style={{ display: "block" }}>
                {!item.value && item.value !== 0 ? (
                  <Loader customStyles={{ width: "20px", height: "20px" }} />
                ) : (
                  item.value
                )}
              </span>
              <p className="font-16">{item.title}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return element;
};
