import { Visual } from "../../../../UI/visual/Visual";
import { Tabs } from "../../../../UI/tabs/Tabs";
import { useDispatch } from "react-redux";
import { useState } from "react";
import OrderEntry from "./components/OrderEntry";
import InstrumentDetails from "./components/InstrumentDetails";

const TradeSideBar = () => {
  let data = [
    {
      title: "Order Entry",
      onClick: () => console.log("hi "),
    },
    {
      title: "Instrument Details",
      onClick: () => console.log("hi2"),
    },
  ];

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);

  const activeTabHandler = (index) => {
    setActiveTab(index);
  };

  const handleClose = () => {
    dispatch({ type: "SET_SIDE_BAR", payload: { sideBarOpen: false } });
  };

  return (
    <div>
      <Visual
        label={"BTC/AONE"}
        element={"popup-header"}
        customStyles={{ width: "100%", marginBottom: "10px" }}
        onClick={handleClose}
      />
      <Tabs
        type={"trade"}
        tabsData={data}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        onTabClick={activeTabHandler}
        customStyles={{ width: "100%" }}
      />
      {activeTab === 0 && <OrderEntry />}
      {activeTab === 1 && <InstrumentDetails />}
    </div>
  );
};

export default TradeSideBar;
