export const countriesData = [
  {
    code: "+91",
    flag: "🇮🇳",
    countryKey: "IN",
    country: "India",
  },
  {
    code: "+1",
    flag: "🇨🇦",
    countryKey: "CA",
    country: "Canada",
  },
  {
    code: "+1",
    flag: "🇺🇸",
    countryKey: "US",
    country: "United States",
  },
  {
    code: "+1242",
    flag: "🇧🇸",
    countryKey: "BS",
    country: "Bahamas",
  },
  {
    code: "+1246",
    flag: "🇧🇧",
    countryKey: "BB",
    country: "Barbados",
  },
  {
    code: "+1264",
    flag: "🇦🇮",
    countryKey: "AI",
    country: "Anguilla",
  },
  {
    code: "+1268",
    flag: "🇦🇬",
    countryKey: "AG",
    country: "Antigua and Barbuda",
  },
  {
    code: "+1284",
    flag: "🇻🇬",
    countryKey: "VG",
    country: "Virgin Islands, British",
  },
  {
    code: "+1340",
    flag: "🇻🇮",
    countryKey: "VI",
    country: "Virgin Islands, U.S.",
  },
  {
    code: "+1441",
    flag: "🇧🇲",
    countryKey: "BM",
    country: "Bermuda",
  },
  {
    code: "+1473",
    flag: "🇬🇩",
    countryKey: "GD",
    country: "Grenada",
  },
  {
    code: "+1649",
    flag: "🇹🇨",
    countryKey: "TC",
    country: "Turks and Caicos Islands",
  },
  {
    code: "+1664",
    flag: "🇲🇸",
    countryKey: "MS",
    country: "Montserrat",
  },
  {
    code: "+1670",
    flag: "🇲🇵",
    countryKey: "MP",
    country: "Northern Mariana Islands",
  },
  {
    code: "+1671",
    flag: "🇬🇺",
    countryKey: "GU",
    country: "Guam",
  },
  {
    code: "+1684",
    flag: "🇦🇸",
    countryKey: "AS",
    country: "American Samoa",
  },
  {
    code: "+1758",
    flag: "🇱🇨",
    countryKey: "LC",
    country: "Saint Lucia",
  },
  {
    code: "+1767",
    flag: "🇩🇲",
    countryKey: "DM",
    country: "Dominica",
  },
  {
    code: "+1784",
    flag: "🇻🇨",
    countryKey: "VC",
    country: "Saint Vincent and the Grenadines",
  },
  {
    code: "+1849",
    flag: "🇩🇴",
    countryKey: "DO",
    country: "Dominican Republic",
  },
  {
    code: "+1868",
    flag: "🇹🇹",
    countryKey: "TT",
    country: "Trinidad and Tobago",
  },
  {
    code: "+1869",
    flag: "🇰🇳",
    countryKey: "KN",
    country: "Saint Kitts and Nevis",
  },
  {
    code: "+1876",
    flag: "🇯🇲",
    countryKey: "JM",
    country: "Jamaica",
  },
  {
    code: "+1939",
    flag: "🇵🇷",
    countryKey: "PR",
    country: "Puerto Rico",
  },
  {
    code: "+20",
    flag: "🇪🇬",
    countryKey: "EG",
    country: "Egypt",
  },
  {
    code: "+211",
    flag: "🇸🇸",
    countryKey: "SS",
    country: "South Sudan",
  },
  {
    code: "+212",
    flag: "🇲🇦",
    countryKey: "MA",
    country: "Morocco",
  },
  {
    code: "+213",
    flag: "🇩🇿",
    countryKey: "DZ",
    country: "Algeria",
  },
  {
    code: "+216",
    flag: "🇹🇳",
    countryKey: "TN",
    country: "Tunisia",
  },
  {
    code: "+218",
    flag: "🇱🇾",
    countryKey: "LY",
    country: "Libyan Arab Jamahiriya",
  },
  {
    code: "+220",
    flag: "🇬🇲",
    countryKey: "GM",
    country: "Gambia",
  },
  {
    code: "+221",
    flag: "🇸🇳",
    countryKey: "SN",
    country: "Senegal",
  },
  {
    code: "+222",
    flag: "🇲🇷",
    countryKey: "MR",
    country: "Mauritania",
  },
  {
    code: "+223",
    flag: "🇲🇱",
    countryKey: "ML",
    country: "Mali",
  },
  {
    code: "+224",
    flag: "🇬🇳",
    countryKey: "GN",
    country: "Guinea",
  },
  {
    code: "+225",
    flag: "🇨🇮",
    countryKey: "CI",
    country: "Cote d'Ivoire",
  },
  {
    code: "+226",
    flag: "🇧🇫",
    countryKey: "BF",
    country: "Burkina Faso",
  },
  {
    code: "+227",
    flag: "🇳🇪",
    countryKey: "NE",
    country: "Niger",
  },
  {
    code: "+228",
    flag: "🇹🇬",
    countryKey: "TG",
    country: "Togo",
  },
  {
    code: "+229",
    flag: "🇧🇯",
    countryKey: "BJ",
    country: "Benin",
  },
  {
    code: "+230",
    flag: "🇲🇺",
    countryKey: "MU",
    country: "Mauritius",
  },
  {
    code: "+231",
    flag: "🇱🇷",
    countryKey: "LR",
    country: "Liberia",
  },
  {
    code: "+232",
    flag: "🇸🇱",
    countryKey: "SL",
    country: "Sierra Leone",
  },
  {
    code: "+233",
    flag: "🇬🇭",
    countryKey: "GH",
    country: "Ghana",
  },
  {
    code: "+234",
    flag: "🇳🇬",
    countryKey: "NG",
    country: "Nigeria",
  },
  {
    code: "+235",
    flag: "🇹🇩",
    countryKey: "TD",
    country: "Chad",
  },
  {
    code: "+236",
    flag: "🇨🇫",
    countryKey: "CF",
    country: "Central African Republic",
  },
  {
    code: "+237",
    flag: "🇨🇲",
    countryKey: "CM",
    country: "Cameroon",
  },
  {
    code: "+238",
    flag: "🇨🇻",
    countryKey: "CV",
    country: "Cape Verde",
  },
  {
    code: "+239",
    flag: "🇸🇹",
    countryKey: "ST",
    country: "Sao Tome and Principe",
  },
  {
    code: "+240",
    flag: "🇬🇶",
    countryKey: "GQ",
    country: "Equatorial Guinea",
  },
  {
    code: "+241",
    flag: "🇬🇦",
    countryKey: "GA",
    country: "Gabon",
  },
  {
    code: "+242",
    flag: "🇨🇬",
    countryKey: "CG",
    country: "Congo",
  },
  {
    code: "+243",
    flag: "🇨🇩",
    countryKey: "CD",
    country: "Congo, The Democratic Republic of the Congo",
  },
  {
    code: "+244",
    flag: "🇦🇴",
    countryKey: "AO",
    country: "Angola",
  },
  {
    code: "+245",
    flag: "🇬🇼",
    countryKey: "GW",
    country: "Guinea-Bissau",
  },
  {
    code: "+246",
    flag: "🇮🇴",
    countryKey: "IO",
    country: "British Indian Ocean Territory",
  },
  {
    code: "+248",
    flag: "🇸🇨",
    countryKey: "SC",
    country: "Seychelles",
  },
  {
    code: "+249",
    flag: "🇸🇩",
    countryKey: "SD",
    country: "Sudan",
  },
  {
    code: "+250",
    flag: "🇷🇼",
    countryKey: "RW",
    country: "Rwanda",
  },
  {
    code: "+251",
    flag: "🇪🇹",
    countryKey: "ET",
    country: "Ethiopia",
  },
  {
    code: "+252",
    flag: "🇸🇴",
    countryKey: "SO",
    country: "Somalia",
  },
  {
    code: "+253",
    flag: "🇩🇯",
    countryKey: "DJ",
    country: "Djibouti",
  },
  {
    code: "+254",
    flag: "🇰🇪",
    countryKey: "KE",
    country: "Kenya",
  },
  {
    code: "+255",
    flag: "🇹🇿",
    countryKey: "TZ",
    country: "Tanzania, United Republic of Tanzania",
  },
  {
    code: "+256",
    flag: "🇺🇬",
    countryKey: "UG",
    country: "Uganda",
  },
  {
    code: "+257",
    flag: "🇧🇮",
    countryKey: "BI",
    country: "Burundi",
  },
  {
    code: "+258",
    flag: "🇲🇿",
    countryKey: "MZ",
    country: "Mozambique",
  },
  {
    code: "+260",
    flag: "🇿🇲",
    countryKey: "ZM",
    country: "Zambia",
  },
  {
    code: "+261",
    flag: "🇲🇬",
    countryKey: "MG",
    country: "Madagascar",
  },
  {
    code: "+262",
    flag: "🇹🇫",
    countryKey: "TF",
    country: "French Southern Territories",
  },
  {
    code: "+262",
    flag: "🇾🇹",
    countryKey: "YT",
    country: "Mayotte",
  },
  {
    code: "+262",
    flag: "🇷🇪",
    countryKey: "RE",
    country: "Reunion",
  },
  {
    code: "+263",
    flag: "🇿🇼",
    countryKey: "ZW",
    country: "Zimbabwe",
  },
  {
    code: "+264",
    flag: "🇳🇦",
    countryKey: "NA",
    country: "Namibia",
  },
  {
    code: "+265",
    flag: "🇲🇼",
    countryKey: "MW",
    country: "Malawi",
  },
  {
    code: "+266",
    flag: "🇱🇸",
    countryKey: "LS",
    country: "Lesotho",
  },
  {
    code: "+267",
    flag: "🇧🇼",
    countryKey: "BW",
    country: "Botswana",
  },
  {
    code: "+268",
    flag: "🇸🇿",
    countryKey: "SZ",
    country: "Swaziland",
  },
  {
    code: "+269",
    flag: "🇰🇲",
    countryKey: "KM",
    country: "Comoros",
  },
  {
    code: "+27",
    flag: "🇿🇦",
    countryKey: "ZA",
    country: "South Africa",
  },
  {
    code: "+290",
    flag: "🇸🇭",
    countryKey: "SH",
    country: "Saint Helena, Ascension and Tristan Da Cunha",
  },
  {
    code: "+291",
    flag: "🇪🇷",
    countryKey: "ER",
    country: "Eritrea",
  },
  {
    code: "+297",
    flag: "🇦🇼",
    countryKey: "AW",
    country: "Aruba",
  },
  {
    code: "+298",
    flag: "🇫🇴",
    countryKey: "FO",
    country: "Faroe Islands",
  },
  {
    code: "+299",
    flag: "🇬🇱",
    countryKey: "GL",
    country: "Greenland",
  },
  {
    code: "+30",
    flag: "🇬🇷",
    countryKey: "GR",
    country: "Greece",
  },
  {
    code: "+31",
    flag: "🇳🇱",
    countryKey: "NL",
    country: "Netherlands",
  },
  {
    code: "+32",
    flag: "🇧🇪",
    countryKey: "BE",
    country: "Belgium",
  },
  {
    code: "+33",
    flag: "🇫🇷",
    countryKey: "FR",
    country: "France",
  },
  {
    code: "+34",
    flag: "🇪🇸",
    countryKey: "ES",
    country: "Spain",
  },
  {
    code: "+345",
    flag: "🇰🇾",
    countryKey: "KY",
    country: "Cayman Islands",
  },
  {
    code: "+350",
    flag: "🇬🇮",
    countryKey: "GI",
    country: "Gibraltar",
  },
  {
    code: "+351",
    flag: "🇵🇹",
    countryKey: "PT",
    country: "Portugal",
  },
  {
    code: "+352",
    flag: "🇱🇺",
    countryKey: "LU",
    country: "Luxembourg",
  },
  {
    code: "+353",
    flag: "🇮🇪",
    countryKey: "IE",
    country: "Ireland",
  },
  {
    code: "+354",
    flag: "🇮🇸",
    countryKey: "IS",
    country: "Iceland",
  },
  {
    code: "+355",
    flag: "🇦🇱",
    countryKey: "AL",
    country: "Albania",
  },
  {
    code: "+356",
    flag: "🇲🇹",
    countryKey: "MT",
    country: "Malta",
  },
  {
    code: "+357",
    flag: "🇨🇾",
    countryKey: "CY",
    country: "Cyprus",
  },
  {
    code: "+358",
    flag: "🇦🇽",
    countryKey: "AX",
    country: "Åland Islands",
  },
  {
    code: "+358",
    flag: "🇫🇮",
    countryKey: "FI",
    country: "Finland",
  },
  {
    code: "+359",
    flag: "🇧🇬",
    countryKey: "BG",
    country: "Bulgaria",
  },
  {
    code: "+36",
    flag: "🇭🇺",
    countryKey: "HU",
    country: "Hungary",
  },
  {
    code: "+370",
    flag: "🇱🇹",
    countryKey: "LT",
    country: "Lithuania",
  },
  {
    code: "+371",
    flag: "🇱🇻",
    countryKey: "LV",
    country: "Latvia",
  },
  {
    code: "+372",
    flag: "🇪🇪",
    countryKey: "EE",
    country: "Estonia",
  },
  {
    code: "+373",
    flag: "🇲🇩",
    countryKey: "MD",
    country: "Moldova",
  },
  {
    code: "+374",
    flag: "🇦🇲",
    countryKey: "AM",
    country: "Armenia",
  },
  {
    code: "+375",
    flag: "🇧🇾",
    countryKey: "BY",
    country: "Belarus",
  },
  {
    code: "+376",
    flag: "🇦🇩",
    countryKey: "AD",
    country: "Andorra",
  },
  {
    code: "+377",
    flag: "🇲🇨",
    countryKey: "MC",
    country: "Monaco",
  },
  {
    code: "+378",
    flag: "🇸🇲",
    countryKey: "SM",
    country: "San Marino",
  },
  {
    code: "+379",
    flag: "🇻🇦",
    countryKey: "VA",
    country: "Holy See (Vatican City State)",
  },
  {
    code: "+380",
    flag: "🇺🇦",
    countryKey: "UA",
    country: "Ukraine",
  },
  {
    code: "+381",
    flag: "🇷🇸",
    countryKey: "RS",
    country: "Serbia",
  },
  {
    code: "+382",
    flag: "🇲🇪",
    countryKey: "ME",
    country: "Montenegro",
  },
  {
    code: "+383",
    flag: "🇽🇰",
    countryKey: "XK",
    country: "Kosovo",
  },
  {
    code: "+385",
    flag: "🇭🇷",
    countryKey: "HR",
    country: "Croatia",
  },
  {
    code: "+386",
    flag: "🇸🇮",
    countryKey: "SI",
    country: "Slovenia",
  },
  {
    code: "+387",
    flag: "🇧🇦",
    countryKey: "BA",
    country: "Bosnia and Herzegovina",
  },
  {
    code: "+389",
    flag: "🇲🇰",
    countryKey: "MK",
    country: "Macedonia",
  },
  {
    code: "+39",
    flag: "🇮🇹",
    countryKey: "IT",
    country: "Italy",
  },
  {
    code: "+40",
    flag: "🇷🇴",
    countryKey: "RO",
    country: "Romania",
  },
  {
    code: "+41",
    flag: "🇨🇭",
    countryKey: "CH",
    country: "Switzerland",
  },
  {
    code: "+420",
    flag: "🇨🇿",
    countryKey: "CZ",
    country: "Czech Republic",
  },
  {
    code: "+421",
    flag: "🇸🇰",
    countryKey: "SK",
    country: "Slovakia",
  },
  {
    code: "+423",
    flag: "🇱🇮",
    countryKey: "LI",
    country: "Liechtenstein",
  },
  {
    code: "+43",
    flag: "🇦🇹",
    countryKey: "AT",
    country: "Austria",
  },
  {
    code: "+44",
    flag: "🇬🇬",
    countryKey: "GG",
    country: "Guernsey",
  },
  {
    code: "+44",
    flag: "🇮🇲",
    countryKey: "IM",
    country: "Isle of Man",
  },
  {
    code: "+44",
    flag: "🇯🇪",
    countryKey: "JE",
    country: "Jersey",
  },
  {
    code: "+44",
    flag: "🇬🇧",
    countryKey: "GB",
    country: "United Kingdom",
  },
  {
    code: "+45",
    flag: "🇩🇰",
    countryKey: "DK",
    country: "Denmark",
  },
  {
    code: "+46",
    flag: "🇸🇪",
    countryKey: "SE",
    country: "Sweden",
  },
  {
    code: "+47",
    flag: "🇧🇻",
    countryKey: "BV",
    country: "Bouvet Island",
  },
  {
    code: "+47",
    flag: "🇳🇴",
    countryKey: "NO",
    country: "Norway",
  },
  {
    code: "+47",
    flag: "🇸🇯",
    countryKey: "SJ",
    country: "Svalbard and Jan Mayen",
  },
  {
    code: "+48",
    flag: "🇵🇱",
    countryKey: "PL",
    country: "Poland",
  },
  {
    code: "+49",
    flag: "🇩🇪",
    countryKey: "DE",
    country: "Germany",
  },
  {
    code: "+500",
    flag: "🇫🇰",
    countryKey: "FK",
    country: "Falkland Islands (Malvinas)",
  },
  {
    code: "+500",
    flag: "🇬🇸",
    countryKey: "GS",
    country: "South Georgia and the South Sandwich Islands",
  },
  {
    code: "+501",
    flag: "🇧🇿",
    countryKey: "BZ",
    country: "Belize",
  },
  {
    code: "+502",
    flag: "🇬🇹",
    countryKey: "GT",
    country: "Guatemala",
  },
  {
    code: "+503",
    flag: "🇸🇻",
    countryKey: "SV",
    country: "El Salvador",
  },
  {
    code: "+504",
    flag: "🇭🇳",
    countryKey: "HN",
    country: "Honduras",
  },
  {
    code: "+505",
    flag: "🇳🇮",
    countryKey: "NI",
    country: "Nicaragua",
  },
  {
    code: "+506",
    flag: "🇨🇷",
    countryKey: "CR",
    country: "Costa Rica",
  },
  {
    code: "+507",
    flag: "🇵🇦",
    countryKey: "PA",
    country: "Panama",
  },
  {
    code: "+508",
    flag: "🇵🇲",
    countryKey: "PM",
    country: "Saint Pierre and Miquelon",
  },
  {
    code: "+509",
    flag: "🇭🇹",
    countryKey: "HT",
    country: "Haiti",
  },
  {
    code: "+51",
    flag: "🇵🇪",
    countryKey: "PE",
    country: "Peru",
  },
  {
    code: "+52",
    flag: "🇲🇽",
    countryKey: "MX",
    country: "Mexico",
  },
  {
    code: "+53",
    flag: "🇨🇺",
    countryKey: "CU",
    country: "Cuba",
  },
  {
    code: "+54",
    flag: "🇦🇷",
    countryKey: "AR",
    country: "Argentina",
  },
  {
    code: "+55",
    flag: "🇧🇷",
    countryKey: "BR",
    country: "Brazil",
  },
  {
    code: "+56",
    flag: "🇨🇱",
    countryKey: "CL",
    country: "Chile",
  },
  {
    code: "+57",
    flag: "🇨🇴",
    countryKey: "CO",
    country: "Colombia",
  },
  {
    code: "+58",
    flag: "🇻🇪",
    countryKey: "VE",
    country: "Venezuela, Bolivarian Republic of Venezuela",
  },
  {
    code: "+590",
    flag: "🇬🇵",
    countryKey: "GP",
    country: "Guadeloupe",
  },
  {
    code: "+590",
    flag: "🇧🇱",
    countryKey: "BL",
    country: "Saint Barthelemy",
  },
  {
    code: "+590",
    flag: "🇲🇫",
    countryKey: "MF",
    country: "Saint Martin",
  },
  {
    code: "+591",
    flag: "🇧🇴",
    countryKey: "BO",
    country: "Bolivia, Plurinational State of bolivia",
  },
  {
    code: "+592",
    flag: "🇬🇾",
    countryKey: "GY",
    country: "Guyana",
  },
  {
    code: "+593",
    flag: "🇪🇨",
    countryKey: "EC",
    country: "Ecuador",
  },
  {
    code: "+594",
    flag: "🇬🇫",
    countryKey: "GF",
    country: "French Guiana",
  },
  {
    code: "+595",
    flag: "🇵🇾",
    countryKey: "PY",
    country: "Paraguay",
  },
  {
    code: "+596",
    flag: "🇲🇶",
    countryKey: "MQ",
    country: "Martinique",
  },
  {
    code: "+597",
    flag: "🇸🇷",
    countryKey: "SR",
    country: "Suriname",
  },
  {
    code: "+598",
    flag: "🇺🇾",
    countryKey: "UY",
    country: "Uruguay",
  },
  {
    code: "+599",
    flag: "AN",
    countryKey: "Netherlands",
    country: "Antilles",
  },
  {
    code: "+60",
    flag: "🇲🇾",
    countryKey: "MY",
    country: "Malaysia",
  },
  {
    code: "+61",
    flag: "🇦🇺",
    countryKey: "AU",
    country: "Australia",
  },
  {
    code: "+61",
    flag: "🇨🇽",
    countryKey: "CX",
    country: "Christmas Island",
  },
  {
    code: "+61",
    flag: "🇨🇨",
    countryKey: "CC",
    country: "Cocos (Keeling) Islands",
  },
  {
    code: "+62",
    flag: "🇮🇩",
    countryKey: "ID",
    country: "Indonesia",
  },
  {
    code: "+63",
    flag: "🇵🇭",
    countryKey: "PH",
    country: "Philippines",
  },
  {
    code: "+64",
    flag: "🇳🇿",
    countryKey: "NZ",
    country: "New Zealand",
  },
  {
    code: "+64",
    flag: "🇵🇳",
    countryKey: "PN",
    country: "Pitcairn",
  },
  {
    code: "+65",
    flag: "🇸🇬",
    countryKey: "SG",
    country: "Singapore",
  },
  {
    code: "+66",
    flag: "🇹🇭",
    countryKey: "TH",
    country: "Thailand",
  },
  {
    code: "+670",
    flag: "🇹🇱",
    countryKey: "TL",
    country: "Timor-Leste",
  },
  {
    code: "+672",
    flag: "🇦🇶",
    countryKey: "AQ",
    country: "Antarctica",
  },
  {
    code: "+672",
    flag: "🇭🇲",
    countryKey: "HM",
    country: "Heard Island and Mcdonald Islands",
  },
  {
    code: "+672",
    flag: "🇳🇫",
    countryKey: "NF",
    country: "Norfolk Island",
  },
  {
    code: "+673",
    flag: "🇧🇳",
    countryKey: "BN",
    country: "Brunei Darussalam",
  },
  {
    code: "+674",
    flag: "🇳🇷",
    countryKey: "NR",
    country: "Nauru",
  },
  {
    code: "+675",
    flag: "🇵🇬",
    countryKey: "PG",
    country: "Papua New Guinea",
  },
  {
    code: "+676",
    flag: "🇹🇴",
    countryKey: "TO",
    country: "Tonga",
  },
  {
    code: "+677",
    flag: "🇸🇧",
    countryKey: "SB",
    country: "Solomon Islands",
  },
  {
    code: "+678",
    flag: "🇻🇺",
    countryKey: "VU",
    country: "Vanuatu",
  },
  {
    code: "+679",
    flag: "🇫🇯",
    countryKey: "FJ",
    country: "Fiji",
  },
  {
    code: "+680",
    flag: "🇵🇼",
    countryKey: "PW",
    country: "Palau",
  },
  {
    code: "+681",
    flag: "🇼🇫",
    countryKey: "WF",
    country: "Wallis and Futuna",
  },
  {
    code: "+682",
    flag: "🇨🇰",
    countryKey: "CK",
    country: "Cook Islands",
  },
  {
    code: "+683",
    flag: "🇳🇺",
    countryKey: "NU",
    country: "Niue",
  },
  {
    code: "+685",
    flag: "🇼🇸",
    countryKey: "WS",
    country: "Samoa",
  },
  {
    code: "+686",
    flag: "🇰🇮",
    countryKey: "KI",
    country: "Kiribati",
  },
  {
    code: "+687",
    flag: "🇳🇨",
    countryKey: "NC",
    country: "New Caledonia",
  },
  {
    code: "+688",
    flag: "🇹🇻",
    countryKey: "TV",
    country: "Tuvalu",
  },
  {
    code: "+689",
    flag: "🇵🇫",
    countryKey: "PF",
    country: "French Polynesia",
  },
  {
    code: "+690",
    flag: "🇹🇰",
    countryKey: "TK",
    country: "Tokelau",
  },
  {
    code: "+691",
    flag: "🇫🇲",
    countryKey: "FM",
    country: "Micronesia, Federated States of Micronesia",
  },
  {
    code: "+692",
    flag: "🇲🇭",
    countryKey: "MH",
    country: "Marshall Islands",
  },
  {
    code: "+7",
    flag: "🇰🇿",
    countryKey: "KZ",
    country: "Kazakhstan",
  },
  {
    code: "+7",
    flag: "🇷🇺",
    countryKey: "RU",
    country: "Russia",
  },
  {
    code: "+81",
    flag: "🇯🇵",
    countryKey: "JP",
    country: "Japan",
  },
  {
    code: "+82",
    flag: "🇰🇷",
    countryKey: "KR",
    country: "Korea, Republic of South Korea",
  },
  {
    code: "+84",
    flag: "🇻🇳",
    countryKey: "VN",
    country: "Vietnam",
  },
  {
    code: "+850",
    flag: "🇰🇵",
    countryKey: "KP",
    country: "Korea, Democratic People's Republic of Korea",
  },
  {
    code: "+852",
    flag: "🇭🇰",
    countryKey: "HK",
    country: "Hong Kong",
  },
  {
    code: "+853",
    flag: "🇲🇴",
    countryKey: "MO",
    country: "Macao",
  },
  {
    code: "+855",
    flag: "🇰🇭",
    countryKey: "KH",
    country: "Cambodia",
  },
  {
    code: "+856",
    flag: "🇱🇦",
    countryKey: "LA",
    country: "Laos",
  },
  {
    code: "+86",
    flag: "🇨🇳",
    countryKey: "CN",
    country: "China",
  },
  {
    code: "+880",
    flag: "🇧🇩",
    countryKey: "BD",
    country: "Bangladesh",
  },
  {
    code: "+886",
    flag: "🇹🇼",
    countryKey: "TW",
    country: "Taiwan",
  },
  {
    code: "+90",
    flag: "🇹🇷",
    countryKey: "TR",
    country: "Turkey",
  },
  {
    code: "+92",
    flag: "🇵🇰",
    countryKey: "PK",
    country: "Pakistan",
  },
  {
    code: "+93",
    flag: "🇦🇫",
    countryKey: "AF",
    country: "Afghanistan",
  },
  {
    code: "+94",
    flag: "🇱🇰",
    countryKey: "LK",
    country: "Sri Lanka",
  },
  {
    code: "+95",
    flag: "🇲🇲",
    countryKey: "MM",
    country: "Myanmar",
  },
  {
    code: "+960",
    flag: "🇲🇻",
    countryKey: "MV",
    country: "Maldives",
  },
  {
    code: "+961",
    flag: "🇱🇧",
    countryKey: "LB",
    country: "Lebanon",
  },
  {
    code: "+962",
    flag: "🇯🇴",
    countryKey: "JO",
    country: "Jordan",
  },
  {
    code: "+963",
    flag: "🇸🇾",
    countryKey: "SY",
    country: "Syrian Arab Republic",
  },
  {
    code: "+964",
    flag: "🇮🇶",
    countryKey: "IQ",
    country: "Iraq",
  },
  {
    code: "+965",
    flag: "🇰🇼",
    countryKey: "KW",
    country: "Kuwait",
  },
  {
    code: "+966",
    flag: "🇸🇦",
    countryKey: "SA",
    country: "Saudi Arabia",
  },
  {
    code: "+967",
    flag: "🇾🇪",
    countryKey: "YE",
    country: "Yemen",
  },
  {
    code: "+968",
    flag: "🇴🇲",
    countryKey: "OM",
    country: "Oman",
  },
  {
    code: "+970",
    flag: "🇵🇸",
    countryKey: "PS",
    country: "Palestinian Territory, Occupied",
  },
  {
    code: "+971",
    flag: "🇦🇪",
    countryKey: "AE",
    country: "United Arab Emirates",
  },
  {
    code: "+972",
    flag: "🇮🇱",
    countryKey: "IL",
    country: "Israel",
  },
  {
    code: "+973",
    flag: "🇧🇭",
    countryKey: "BH",
    country: "Bahrain",
  },
  {
    code: "+974",
    flag: "🇶🇦",
    countryKey: "QA",
    country: "Qatar",
  },
  {
    code: "+975",
    flag: "🇧🇹",
    countryKey: "BT",
    country: "Bhutan",
  },
  {
    code: "+976",
    flag: "🇲🇳",
    countryKey: "MN",
    country: "Mongolia",
  },
  {
    code: "+977",
    flag: "🇳🇵",
    countryKey: "NP",
    country: "Nepal",
  },
  {
    code: "+98",
    flag: "🇮🇷",
    countryKey: "IR",
    country: "Iran, Islamic Republic of Persian Gulf",
  },
  {
    code: "+992",
    flag: "🇹🇯",
    countryKey: "TJ",
    country: "Tajikistan",
  },
  {
    code: "+993",
    flag: "🇹🇲",
    countryKey: "TM",
    country: "Turkmenistan",
  },
  {
    code: "+994",
    flag: "🇦🇿",
    countryKey: "AZ",
    country: "Azerbaijan",
  },
  {
    code: "+995",
    flag: "🇬🇪",
    countryKey: "GE",
    country: "Georgia",
  },
  {
    code: "+996",
    flag: "🇰🇬",
    countryKey: "KG",
    country: "Kyrgyzstan",
  },
  {
    code: "+998",
    flag: "🇺🇿",
    countryKey: "UZ",
    country: "Uzbekistan",
  },
];
