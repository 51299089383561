import React from "react";

const Signals = ({ color }) => {
  return (
    <svg 
      width="32" 
      height="32" 
      viewBox="0 0 32 32" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg" 
      data-qa="signals-icon"
      style={{ width: "100%", height: "100%"}}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M13.2929 13.7929C13.6834 13.4024 14.3166 13.4024 14.7071 13.7929L18 17.0858L21.2929 13.7929C21.6834 13.4024 22.3166 13.4024 22.7071 13.7929C23.0976 14.1834 23.0976 14.8166 22.7071 15.2071L18.7071 19.2071C18.3166 19.5976 17.6834 19.5976 17.2929 19.2071L14 15.9142L10.7071 19.2071C10.3166 19.5976 9.68342 19.5976 9.29289 19.2071C8.90237 18.8166 8.90237 18.1834 9.29289 17.7929L13.2929 13.7929Z" fill={color ? color : "white"} fillOpacity="0.9"></path>
      <path d="M4.89964 25.7945C4.46679 26.157 3.81904 26.1017 3.48591 25.6459C1.51756 22.9528 0.464949 19.6876 0.50089 16.3339C0.536831 12.9802 1.65917 9.73828 3.68479 7.08798C4.02762 6.63943 4.67639 6.5981 5.10138 6.96973V6.96973C5.52637 7.34136 5.56611 7.98503 5.2278 8.437C3.52069 10.7176 2.57594 13.4897 2.54522 16.3558C2.51451 19.222 3.39963 22.0136 5.05747 24.3303C5.38602 24.7894 5.3325 25.4321 4.89964 25.7945V25.7945Z" fill={color ? color : "white"} fillOpacity="0.9"></path>
      <path d="M26.9365 7.01318C27.3629 6.64324 28.0115 6.68715 28.3526 7.13706C30.3676 9.7954 31.4771 13.0418 31.4996 16.3956C31.5222 19.7494 30.4566 23.0104 28.4776 25.6957C28.1426 26.1501 27.4947 26.2028 27.0633 25.8386V25.8386C26.6319 25.4745 26.5809 24.8316 26.9113 24.3738C28.5783 22.0637 29.4746 19.2756 29.4552 16.4094C29.4359 13.5431 28.5022 10.7673 26.8042 8.47993C26.4677 8.02662 26.51 7.38311 26.9365 7.01318V7.01318Z" fill={color ? color : "white"} fillOpacity="0.9"></path>
      <path d="M8.17727 23.5436C7.77773 23.9034 7.15846 23.8734 6.83377 23.4448C5.3105 21.4344 4.48474 18.9702 4.50021 16.43C4.51568 13.8897 5.37139 11.4357 6.91903 9.44404C7.24892 9.01951 7.8685 8.99704 8.26363 9.36163V9.36163C8.65876 9.72621 8.67895 10.3395 8.35644 10.7696C7.134 12.4001 6.45964 14.387 6.44713 16.4418C6.43461 18.4966 7.08472 20.4916 8.28722 22.1369C8.60446 22.5709 8.57681 23.1839 8.17727 23.5436V23.5436Z" fill={color ? color : "white"} fillOpacity="0.9"></path>
      <path d="M23.6986 9.32093C24.0918 8.95426 24.7115 8.97346 25.0436 9.39625C26.6018 11.3798 27.4704 13.8292 27.4993 16.3693C27.5281 18.9095 26.7154 21.378 25.2027 23.3964C24.8803 23.8266 24.2612 23.8599 23.8598 23.5023V23.5023C23.4583 23.1446 23.4274 22.5318 23.7424 22.0961C24.9362 20.4445 25.5758 18.4462 25.5524 16.3914C25.5291 14.3367 24.8443 12.3534 23.6132 10.7294C23.2885 10.3009 23.3054 9.68759 23.6986 9.32093V9.32093Z" fill={color ? color : "white"} fillOpacity="0.9"></path>
    </svg>
  );
};

export default Signals;
