import axios from "../api/axios";

export const axiosUtility = async (url, body, type) => {
  try {
    let response = null;

    if (type === "GET") {
      response = await axios.get(url);
    } else {
      response = await axios.post(url, body);
    }
    
    return response;
  } catch (error) {
    console.error("Axios utility error:", error);
  }
};

export const axiosMultyUtility = async (params) => {
  try {
    let promises = [];

    for (let i = 0; i < params.length; i++) {
      if (params[i].type === "POST") {
        promises.push(axios.post(params[i].url, params[i].body));
      } else {
        promises.push(axios.get(params[i].url, params[i].body));
      }
    }

    let responses = await Promise.all(promises);

    return responses;
  } catch (e) {
    console.error("An error occurred:", e);
  }
};