import React from "react";
import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.privacyPolicy}>
      <h1>Privacy Policy</h1>
      <p className={styles.dateOfUpdate}>Last updated: Feb 26, 2024</p>
      <p>
        Welcome to a1.gold web app! This Privacy Policy (“Policy”) outlines how
        we collect, process, use, safeguard, and disclose personal information
        and your rights and choices regarding this information. For the purposes
        of this Policy, “personal information” refers to any information related
        to an identified or identifiable individual. We may update this Policy
        periodically, and we encourage you to review it regularly. If you have
        any questions, please contact us using the details in the “Contact Us”
        section of this Policy.
      </p>
      <h2>Applicability of This Policy</h2>
      <p>
        This Policy applies to personal information collected in connection with
        the services provided by a1.gold (“Services”). By accessing or using the
        Services, you agree to the terms of this Policy. If you do not agree
        with the terms of this Policy, do not use the Services
      </p>
      <h2>What We Collect</h2>
      <p>
        When you interact with the Services, we may collect personal information
        from various sources, including: Personal information you provide to us:
      </p>
      <ul className={styles.orderedList}>
        <li>
          <strong>Contact Information:</strong>
          Your name, email address, physical address, and country information.
        </li>
        <li>
          <strong>Financial Information:</strong>
          Ethereum network address, cryptocurrency wallet information,
          transaction history, trading data, and associated fees paid.
        </li>
        <li>
          <strong>Transaction Information:</strong>
          Details about transactions made on the Services, such as transaction
          type, amount, and timestamp.
        </li>
        <li>
          <strong>Correspondence:</strong>
          Feedback, questionnaire responses, and information provided to our
          support teams via help chat or social media messaging channels.
          Personal information collected via automated means:
        </li>
        <li>
          <strong>Online Identifiers:</strong>
          Username, geo-location or tracking details, browser fingerprint,
          operating system, browser name and version, and IP addresses.
        </li>
        <li>
          <strong>Usage and Diagnostics Data:</strong>
          Conversion events, user preferences, crash logs, device information,
          and other data collected through cookies and similar technologies.
        </li>
        <li>
          <strong>
            nformation from cookies and other tracking technologies:
          </strong>
          Cookies, web beacons, and similar technologies to record preferences,
          track Services usage, and collect information about your interactions
          with the Services.
        </li>
        <p>Personal information we receive from third parties:</p>
        <li>
          <strong>Information from Third Parties:</strong>
          Personal information from other sources, including public databases,
          to comply with legal obligations and prevent fraudulent or illicit
          activities.
        </li>
      </ul>
      <h2>How We Use Your Information</h2>
      <p>
        We use your personal information for various purposes, including but not
        limited to:
      </p>
      <ul className={styles.orderedList}>
        <li>
          <strong>Providing Services and Features:</strong> Operating,
          maintaining, customizing, measuring, and improving the Services;
          creating and updating user accounts; processing transactions; and
          sending information, notices, updates, and support messages.
        </li>
        <li>
          <strong>Safety and Security:</strong> Protecting against fraudulent,
          unauthorized, or illegal activity; monitoring and verifying identity;
          enforcing agreements; and complying with security laws and
          regulations.
        </li>
        <li>
          <strong>Customer Support:</strong> Providing customer support,
          addressing user concerns, and improving support responses and
          processes.
        </li>
        <li>
          <strong>Personalization:</strong> Personalizing user experiences and
          communications.
        </li>
        <li>
          <strong>Research and Development:</strong> Testing, research,
          analysis, and product development to enhance Services safety,
          security, and features.
        </li>
        <li>
          <strong>Legal and Regulatory Compliance:</strong> Enforcing compliance
          with terms and policies, defending against legal claims, and complying
          with applicable laws and regulations.
        </li>
        <li>
          <strong>Direct Marketing:</strong> Marketing Services, features,
          promotions, surveys, news, updates, and events (with opt-out options).
        </li>
        <li>
          <strong>Anonymization and Aggregation:</strong> Aggregating or
          anonymizing personal information for statistical analysis and other
          purposes.
        </li>
      </ul>
      <h2>When We Share and Disclose Information</h2>
      <p>We may share your information in the following circumstances:</p>
      <ul className={styles.orderedList}>
        <li>
          <strong>To Comply with Legal Obligations:</strong> Cooperating with
          government investigations, responding to legal procedures, preventing
          harm, reporting illegal activity, and investigating violations of
          terms and policies.
        </li>
        <li>
          <strong>With Service Providers or Other Third Parties:</strong>{" "}
          Engaging third parties for business and compliance operations,
          including marketing and technology services.
        </li>
        <li>
          <strong>Partners:</strong> Sharing information with business partners,
          subject to any consent requirements.
        </li>
        <li>
          <strong>Affiliates:</strong> Sharing information with subsidiaries and
          affiliates for consistent purposes.
        </li>
        <li>
          <strong>During a Change to Our Business:</strong> In case of mergers,
          acquisitions, bankruptcies, or similar transactions, sharing or
          transferring personal information with confidentiality arrangements.
        </li>
        <li>
          <strong>Aggregated or De-identified Data:</strong> Sharing aggregated
          or anonymized data with third parties.
        </li>
      </ul>
      <h2>International Data Transfers</h2>
      <p>
        Your personal information may be processed outside your home country. We
        will ensure relevant safeguards are in place to protect your personal
        information in accordance with applicable data protection laws.
      </p>
      <h2>Data Retention</h2>
      <p>
        We retain your personal information only for as long as necessary to
        fulfill the purposes for which it was collected and processed, in
        accordance with applicable laws.
      </p>
      <h2>Your Rights and Choices</h2>
      <p>You may have rights under applicable law, including the right to:</p>
      <ul className={styles.orderedList}>
        <li>
          <strong>Access:</strong> Request access to your personal information.
        </li>
        <li>
          <strong>Correction or Deletion:</strong> Request corrections or
          deletions of your personal information.
        </li>
        <li>
          <strong>Restrict Processing:</strong> Request to restrict or object to
          processing.
        </li>
        <li>
          <strong>Withdraw Consent:</strong> Withdraw consent to processing
          based on consent.
        </li>
        <li>
          <strong>Data Portability:</strong> Request the receipt or transfer of
          personal information to another organization.
        </li>
        <li>
          <strong>Complaints:</strong> Lodge complaints with the local data
          protection authority. To exercise these rights, contact us using the
          details in the “Contact Us” section of this Policy. We may require
          identity verification before responding.
        </li>
      </ul>
      <h2>Security</h2>
      <p>
        We maintain safeguards to protect personal information against
        unauthorized access, use, disclosure, alteration, or destruction, in
        line with applicable data protection laws.
      </p>
      <h2>Age Limitations</h2>
      <p>
        The Services are not intended for individuals under 18 years old. If we
        learn that someone under 18 has provided personal data unlawfully, we
        will take steps to delete the information.
      </p>
      <h2>Third-Party Matters</h2>
      <p>
        Links provided in the Services to third-party websites are not governed
        by this Policy. Check the legal and privacy statements on those websites
        for their practices.
      </p>
      <h2>Changes to This Policy</h2>
      <p>
        We may update this Policy, with changes reflected in the “Last Updated”
        date. Material changes will have additional notice provided.
      </p>
      <h2>Online Tracking</h2>
      <p>
        We use cookies, pixels, local storage, and device identifiers for
        various purposes, including security, functionality improvement, and
        Services performance measurement. You can manage cookie preferences in
        your browser settings. Types of Cookies Used:
      </p>
      <ul className={styles.orderedList}>
        <li>
          <strong>Strictly necessary cookies:</strong> Essential for Services
          functionality.
        </li>
        <li>
          <strong>Analytical or Performance cookies:</strong> Measure and
          improve Services performance.
        </li>
        <li>
          <strong>Functional cookies:</strong> Recognize you when you return,
          personalize content, and remember preferences.
        </li>
        <li>
          <strong>Marketing cookies:</strong> Display personalized advertising.
        </li>
        <li>
          <strong>Options to Control Cookies:</strong>
          <ul>
            <li>
              <strong>Browser Settings:</strong> Manage cookies through browser
              settings.
            </li>
            <li>
              <strong>Mobile Settings:</strong> Limit advertising ID use in
              mobile settings.
            </li>
            <li>
              <strong>Privacy Plugins or Browsers:</strong> Use privacy-focused
              browsers or plugins.
            </li>
            <li>
              <strong>Opt-Out Tools:</strong> Utilize opt-out options provided
              by advertising partners.
            </li>
          </ul>
        </li>
      </ul>
      For more information, visit{" "}
      <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>
      <h2>
        Additional Disclosure for Our Consumers and Customers (“Additional
        Disclosure”)
      </h2>
      <p>
        This section governs personal information collection related to trading
        on our platform. Types of Personal Information Collected:
      </p>
      <ul className={styles.orderedList}>
        <li>Contact details</li>
        <li>IP addresses</li>
        <li>Trading history</li>
        <li>Cryptocurrency balances and wallets</li>
        <li>Conversion events</li>
      </ul>
      <p>
        <strong>Reasons for Sharing Personal Information:</strong>
      </p>
      <ul className={styles.orderedList}>
        <li>Everyday business purposes</li>
        <li>Marketing purposes (with opt-out options)</li>
      </ul>
      <p>
        <strong>How We Collect Personal Information:</strong>
      </p>
      <ul className={styles.orderedList}>
        <li>
          Depositing, trading, and withdrawing crypto assets on the platform.
        </li>
        <li>Collecting from other companies.</li>
      </ul>
      <p>
        <strong>Limitations on Sharing:</strong>
      </p>
      <ul className={styles.orderedList}>
        <li>
          Sharing necessary for everyday business purposes cannot be limited.
        </li>
        <li>
          Sharing for marketing purposes can be limited (with opt-out options).
        </li>
      </ul>
      <h2>Contact Us</h2>
      <p>
        For questions or to exercise rights, contact us at{" "}
        <a href="mailto:privacy@a1.gold">privacy@a1.gold</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
