import EmptyContent from "./emptyContent/EmptyContent";

const History = () => {
  return (
    <div>
      <EmptyContent description="No transactions matching your filters" />
    </div>
  );
};

export default History;
