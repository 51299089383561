import { http, createConfig } from "@wagmi/core";
import { bsc } from "@wagmi/core/chains"; 
import { injected, walletConnect } from "wagmi/connectors";
import { decryptEnv } from "./decryptEnv";

const projectId = decryptEnv(process.env.REACT_APP_PROJECT_ID);
const connectors = [injected(), walletConnect({ projectId })];

export const config = createConfig({
    chains: [bsc],
    connectors,
    ssr: true,
    transports: {
      [bsc.id]: http()
    },
});