import React from "react";

const AccountsSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.45 13.64V14.64C21.45 14.7709 21.3987 14.8966 21.3071 14.9901C21.2155 15.0836 21.0909 15.1374 20.96 15.14H19.5C18.97 15.14 18.49 14.75 18.45 14.23C18.42 13.92 18.54 13.63 18.74 13.43C18.8305 13.3363 18.9394 13.2621 19.0598 13.2122C19.1803 13.1623 19.3097 13.1378 19.44 13.14H20.95C21.24 13.15 21.45 13.37 21.45 13.64Z"
        fill="white"
      />
      <path
        d="M17.99 12.69C17.49 13.18 17.25 13.91 17.45 14.67C17.71 15.6 18.62 16.19 19.58 16.19H20.45C21 16.19 21.45 16.64 21.45 17.19V17.38C21.45 19.45 19.76 21.14 17.69 21.14H6.21001C4.14001 21.14 2.45001 19.45 2.45001 17.38V10.65C2.45001 9.42001 3.04001 8.33001 3.95001 7.65001C4.58001 7.17001 5.36001 6.89001 6.21001 6.89001H17.69C19.76 6.89001 21.45 8.58001 21.45 10.65V11.09C21.45 11.64 21 12.09 20.45 12.09H19.43C18.87 12.09 18.36 12.31 17.99 12.69Z"
        fill="white"
      />
      <path
        d="M16.2 4.82002C16.47 5.09002 16.24 5.51002 15.86 5.51002L8.18 5.50002C7.74 5.50002 7.51 4.96002 7.83 4.65002L9.45 3.02002C10.11 2.3666 11.0012 2.00006 11.93 2.00006C12.8588 2.00006 13.75 2.3666 14.41 3.02002L16.16 4.79002C16.17 4.80002 16.19 4.81002 16.2 4.82002Z"
        fill="#B3B3B3"
      />
    </svg>
  );
};

export default AccountsSvg;
