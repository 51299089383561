import React from "react";

const EtheriumLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
    >
      <rect
        x="0.5"
        y="1"
        width="31"
        height="31"
        rx="15.5"
        fill="url(#paint0_linear_40_8922)"
        stroke="url(#paint1_linear_40_8922)"
      />
      <path
        d="M15.998 8.02954V14.2918L21.291 16.657L15.998 8.02954Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M15.9978 8.02954L10.7041 16.657L15.9978 14.2918V8.02954Z"
        fill="white"
      />
      <path
        d="M15.998 20.7154V24.9705L21.2945 17.6428L15.998 20.7154Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M15.9978 24.9705V20.7147L10.7041 17.6428L15.9978 24.9705Z"
        fill="white"
      />
      <path
        d="M15.998 19.7298L21.291 16.6566L15.998 14.2928V19.7298Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M10.7041 16.6566L15.9978 19.7298V14.2928L10.7041 16.6566Z"
        fill="white"
        fillOpacity="0.6"
      />
      <defs>
        <linearGradient
          id="paint0_linear_40_8922"
          x1="0.36923"
          y1="2.12319"
          x2="33.9012"
          y2="4.39119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="1" stopColor="white" stopOpacity="0.02" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_40_8922"
          x1="6.95385"
          y1="0.499999"
          x2="24.2795"
          y2="14.2939"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopColor="white" stopOpacity="0.05" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EtheriumLogo;
