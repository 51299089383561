const INIT_STATE = {
  connectionType: "",
  accountSigned: false,
  sideBarOpen: false,
  loggedWithEmail: false,
  sideBar: "",
  userData: null,
  triedReconnect: false,
  isExtensionsLoaded: false,
  coinbaseLoading: false,
  connectionError: "",
  accountsData: [],
  dashboardTransactionsDataReload: {},
  dashboardAccountType: "main",
  exchangeAccountType: "",
  feeWarnAccountType: "",
  attemptSign: null,
  metaMaskConneconLoading: false,
  walletconnectLoading: false,
  rates: null,
  tiers: [],
  ranges: [],
  fees: [],
  transactionAmount: [],
  tradeData: [
    {
      symbol: "AUDCAD",
      sell: 0.19032,
      sellRise: false,
      buy: 0.23233,
      buyRise: true,
      charge: 0.04,
      chargeRise: true,
      favourites: true,
      _id: "65855f2c52403094f73cd9c0",
    },
    {
      symbol: "BTCUSD",
      sell: 0.19032,
      sellRise: false,
      buy: 0.23233,
      buyRise: true,
      charge: 0.01,
      chargeRise: false,
      favourites: false,
      _id: "65855eff52403094f73cd984",
    },
    {
      symbol: "BTCUSD",
      sell: 0.19032,
      sellRise: false,
      buy: 0.23233,
      buyRise: true,
      charge: 0.01,
      chargeRise: false,
      favourites: false,
      _id: "65855eff52403094f73cd985",
    },
    {
      symbol: "BTCUSD",
      sell: 0.19032,
      sellRise: false,
      buy: 0.23233,
      buyRise: true,
      charge: 0.01,
      chargeRise: false,
      favourites: false,
      _id: "65855eff52403094f73cd987",
    },
  ],
};

const appStateReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_TRADE_DATA":
      return {
        ...state,
        tradeData: state.tradeData.map((item) =>
          item._id === action.payload._id
            ? { ...item, favourites: action.payload.favourites }
            : item
        ),
      };

    case "SET_SIDE_BAR":
      return { ...state, ...action.payload };

    case "SET_LOGGED_WITH_EMAIL":
      return {
        ...state,
        loggedWithEmail: action.payload,
      };

    case "SET_LOGOUT_WITH_EMAIL":
      return {
        sideBarOpen: false,
        loggedWithEmail: false,
        sideBar: "",
        userData: null,
        triedReconnect: false,
        isExtensionsLoaded: false,
        coinbaseLoading: false,
        connectionError: "",
        accountsData: [],
        dashboardTransactionsDataReload: {},
        dashboardAccountType: "main",
        exchangeAccountType: "",
        feeWarnAccountType: "",
      };

    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.payload,
        emailVerified: action.payload?.meta?.verified,
        hasPasswordSet: action.payload.hasPasswordSet,
        otp_enabled: action.payload.otp_enabled,
        otp_verified: action.payload.otp_verified,
      };

    case "SET_USER_AUTH":
      return {
        ...state,
        hasPasswordSet: action.payload.hasPasswordSet,
        otp_enabled: action.payload.otp_enabled,
        otp_verified: action.payload.otp_verified,
      };

    case "SET_META_DATA":
      return {
        ...state,
        userData: {
          ...state?.userData,
          meta: action.payload,
        },
        emailVerified: action.payload?.verified,
      };

    case "SET_SYSTEM_ACCOUNT_DATA":
      return {
        ...state,
        userData: {
          ...state?.userData,
          ...action.payload,
        },
        accountsData: state.accountsData.map((account) =>
          account.account_category === "main"
            ? { ...account, balance: action.payload.balance }
            : account
        ),
      };

    case "SET_TRIED_RECONNECT":
      return {
        ...state,
        triedReconnect: action.payload,
      };

    case "SET_EXTENSIONS_LOADED":
      return {
        ...state,
        isExtensionsLoaded: action.payload,
      };

    case "UPDATE_COINBASE_LOADING": // add this case
      return {
        ...state,
        coinbaseLoading: action.payload.value,
      };

    case "CONNECTION_ERROR":
      return {
        ...state,
        connectionError: action.payload,
      };

    case "SET_ACCOUNTS_DATA":
      return {
        ...state,
        accountsData: action.payload,
      };

    case "SET_DASHBOARD_TRANSACTIONS_DATA_RELOAD":
      return {
        ...state,
        dashboardTransactionsDataReload: { ...action.payload },
      };

    case "SET_DASHBOARD_ACCOUNT_TYPE":
      return {
        ...state,
        dashboardAccountType: action.payload,
      };

    case "SET_EXCHANGE_ACCOUNT_TYPE":
      return {
        ...state,
        exchangeAccountType: action.payload,
      };

    case "SET_FEE_WARN_TYPE":
      return {
        ...state,
        feeWarnAccountType: action.payload,
      };

    case "SET_ACCOUNT_SIGNED":
      return {
        ...state,
        accountSigned: action.payload,
      };

    case "SET_CONNECTION_TYPE":
      return {
        ...state,
        connectionType: action.payload,
      };

    case "SET_ATTEMPT_SIGN":
      return {
        ...state,
        attemptSign: { ...action.payload },
      };

    case "SET_METAMASK_CONNECT_LOADING":
      return {
        ...state,
        metaMaskConnectionLoading: action.payload,
      };

    case "SET_WALLETCONNECT_LOADING":
      return {
        ...state,
        walletconnectLoading: action.payload,
      };

    case "SET_RATES":
      return {
        ...state,
        rates: action.payload,
      };

    case "SET_TIERS":
      return {
        ...state,
        tiers: action.payload,
      };

    case "SET_TRANSACTION_FEES":
      return {
        ...state,
        fees: action.payload,
      };

    case "SET_TRANSACTION_AMOUNT":
      return {
        ...state,
        transactionAmount: action.payload,
      };

    case "SET_RANGES":
      return {
        ...state,
        ranges: action.payload,
      };

    default:
      return state;
  }
};

export default appStateReducer;
