import BigNumber from "bignumber.js";
import WBNB from "../abi/WBNB.json";
import { readContract } from "wagmi/actions";
import { config } from "../utils/wagmiConfig";

export async function getBalance(
  providerType,
  library,
  tokenAddress,
  account
) {
  if (providerType === "metaMask" && library) {
    let tokenContract = new library.eth.Contract(WBNB, tokenAddress);
    let decimals = await tokenContract.methods.decimals().call();
    let getBalance = await tokenContract.methods.balanceOf(account).call();
    let balance = new BigNumber(getBalance);
    let pow = new BigNumber(10).pow(decimals);

    let balanceInEth = balance.div(pow);

    return balanceInEth.toString();
  }

  if (providerType === "walletConnect") {
    const decimals = await readContract(config, {
      address: tokenAddress,
      abi: WBNB,
      functionName: "decimals",
      args: [],
    });

    const getBalance = await readContract(config, {
      address: tokenAddress,
      abi: WBNB,
      functionName: "balanceOf",
      args: [account],
    });

    let balance = new BigNumber(getBalance);
    let pow = new BigNumber(10).pow(Number(decimals));

    return balance.div(pow).toString();
  }
}
