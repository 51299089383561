import React from "react";

const Trade = () => {
  return (
    <svg 
      width="38" 
      height="38" 
      viewBox="0 0 38 38" 
      fill="currentColor" 
      xmlns="http://www.w3.org/2000/svg" 
      data-qa="trade-icon"
      style={{ width:"100%", height:"100%" }}
    >
      <path d="M8 10.76C8 10.3403 8.34026 10 8.76 10C9.17974 10 9.52 10.3403 9.52 10.76V25.2C9.52 25.6197 9.86026 25.96 10.28 25.96H30.04C30.4597 25.96 30.8 26.3003 30.8 26.72C30.8 27.1397 30.4597 27.48 30.04 27.48H9.52C8.68053 27.48 8 26.7995 8 25.96V10.76Z" fill="white"></path>
      <path d="M24.72 12.0351C24.72 11.6154 25.0603 11.2751 25.48 11.2751C25.8997 11.2751 26.24 11.6154 26.24 12.0351V21.9151C26.24 22.3348 25.8997 22.6751 25.48 22.6751C25.0603 22.6751 24.72 22.3348 24.72 21.9151V12.0351Z" fill="white"></path>
      <path d="M20.92 16.5951C20.92 16.1754 21.2603 15.8351 21.68 15.8351C22.0997 15.8351 22.44 16.1754 22.44 16.5951V21.9151C22.44 22.3348 22.0997 22.6751 21.68 22.6751C21.2603 22.6751 20.92 22.3348 20.92 21.9151V16.5951Z" fill="white"></path>
      <path d="M17.12 13.5551C17.12 13.1354 17.4603 12.7951 17.88 12.7951C18.2997 12.7951 18.64 13.1354 18.64 13.5551V21.9151C18.64 22.3348 18.2997 22.6751 17.88 22.6751C17.4603 22.6751 17.12 22.3348 17.12 21.9151V13.5551Z" fill="white"></path>
      <path d="M13.32 19.6351C13.32 19.2154 13.6603 18.8751 14.08 18.8751C14.4997 18.8751 14.84 19.2154 14.84 19.6351V21.9151C14.84 22.3348 14.4997 22.6751 14.08 22.6751C13.6603 22.6751 13.32 22.3348 13.32 21.9151V19.6351Z" fill="white"></path>
      <path d="M8 10.76C8 10.3403 8.34026 10 8.76 10C9.17974 10 9.52 10.3403 9.52 10.76V25.2C9.52 25.6197 9.86026 25.96 10.28 25.96H30.04C30.4597 25.96 30.8 26.3003 30.8 26.72C30.8 27.1397 30.4597 27.48 30.04 27.48H9.52C8.68053 27.48 8 26.7995 8 25.96V10.76Z" stroke="white" strokeWidth="0.45"></path>
      <path d="M24.72 12.0351C24.72 11.6154 25.0603 11.2751 25.48 11.2751C25.8997 11.2751 26.24 11.6154 26.24 12.0351V21.9151C26.24 22.3348 25.8997 22.6751 25.48 22.6751C25.0603 22.6751 24.72 22.3348 24.72 21.9151V12.0351Z" stroke="white" strokeWidth="0.45"></path>
      <path d="M20.92 16.5951C20.92 16.1754 21.2603 15.8351 21.68 15.8351C22.0997 15.8351 22.44 16.1754 22.44 16.5951V21.9151C22.44 22.3348 22.0997 22.6751 21.68 22.6751C21.2603 22.6751 20.92 22.3348 20.92 21.9151V16.5951Z" stroke="white" strokeWidth="0.45"></path>
      <path d="M17.12 13.5551C17.12 13.1354 17.4603 12.7951 17.88 12.7951C18.2997 12.7951 18.64 13.1354 18.64 13.5551V21.9151C18.64 22.3348 18.2997 22.6751 17.88 22.6751C17.4603 22.6751 17.12 22.3348 17.12 21.9151V13.5551Z" stroke="white" strokeWidth="0.45"></path>
      <path d="M13.32 19.6351C13.32 19.2154 13.6603 18.8751 14.08 18.8751C14.4997 18.8751 14.84 19.2154 14.84 19.6351V21.9151C14.84 22.3348 14.4997 22.6751 14.08 22.6751C13.6603 22.6751 13.32 22.3348 13.32 21.9151V19.6351Z" stroke="white" strokeWidth="0.45"></path>
    </svg>
  );
};

export default Trade;
