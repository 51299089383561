import React, { useState, useEffect } from "react";
import { Transactions as TransactionsUI } from "./Transactions/Transactions";

import { useSelector } from "react-redux";
import { useConnectMeta } from "../hooks/use-connect";
import axios from "../api/axios";
import {
  transactionHeader,
  transactionsTableEmpty,
  footer,
  inputs,
} from "../dummyComponents/transactions";

const Transactions = () => {
  const [transactionsData, setTransactionsData] = useState({});
  const [totalTransactions, setTotalTransactions] = useState({});
  const [loading, setLoading] = useState(false);
  const [itemsLimit, setItemsLimit] = useState(10);
  const [transactionsPaginationTotal, setTransactionsPaginationTotal] =
    useState(1);
  const [transactionsCurrentPage, setTransactionsCurrentPage] = useState(1);
  const [filterObject, setFilterObject] = useState({
    type: "",
    account: "",
    time: "",
  });

  const appState = useSelector((state) => state.appState);
  const { account } = useConnectMeta();

  const generateTransactionsData = async () => {
    setLoading(true);

    try {
      const apiUrl = "/api/transactions/get_transactions_of_user";
      const time = filterObject?.time;
      let year, month, day;

      if (time instanceof Date) {
        year = time.getFullYear();
        month = time.getMonth();
        day = time.getDate();
      }

      const requestBody = {
        address: account?.toLowerCase(),
        limit: itemsLimit,
        page: transactionsCurrentPage,
        ...filterObject,
        account:
          filterObject?.account === "main" ? "main" : filterObject?.account,
        time:
          time instanceof Date
            ? `${year}-${(month + 1).toString().padStart(2, "0")}-${day
                .toString()
                .padStart(2, "0")}`
            : filterObject?.time,
      };

      const response = await axios.post(apiUrl, requestBody);

      const data = response.data;

      const amountsToFrom = data?.amounts_to_from?.[0] || {};
      setTransactionsPaginationTotal(data?.total_pages);
      setTransactionsData(data);
      setTotalTransactions({
        total_transaction: data?.total_transaction || 0,
        received: amountsToFrom?.toSum || 0,
        spent: amountsToFrom?.fromSum || 0,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (appState?.accountSigned) {
      generateTransactionsData();
    }
  }, [
    appState?.accountSigned,
    itemsLimit,
    filterObject?.type,
    filterObject?.time,
    filterObject?.account,
    transactionsCurrentPage,
  ]);

  const rightPanelData = [
    {
      title: "Incoming",
      value: totalTransactions?.received,
    },
    {
      title: "Outgoing",
      value: totalTransactions?.spent,
    },
  ];

  let description = (
    <span className="font-14">
      Total number of activities:{" "}
      <span className="dashboard-transactions-span">
        {totalTransactions?.total_transaction}
      </span>
    </span>
  );

  return (
    <TransactionsUI
      header={"Transactions"}
      description={description}
      accountAddress={account?.toLowerCase()}
      rightPanelData={rightPanelData}
      footer={footer}
      tableHead={transactionHeader}
      data={transactionsData?.transactions}
      paginationCurrent={transactionsCurrentPage}
      paginationTotal={transactionsPaginationTotal}
      paginationEvent={(page) => setTransactionsCurrentPage(page)}
      inputs={inputs(setFilterObject, setItemsLimit)}
      currentObject={filterObject}
      loading={loading}
      tableEmpty={transactionsTableEmpty}
      transactionLink={process.env.REACT_APP_TRANSACTION_LINK}
    />
  );
};

export default Transactions;
