import React from "react";
import { FeeWarning } from "./UI/feeWarning/FeeWarning";
import { Popup } from "./UI/popup/Popup";
import { useExtensionsData } from "../hooks/useExtensionsData";
import { useSelector, useDispatch } from "react-redux";
import { Extensions as ExtensionsUI } from "./Extensions/Extensions";

const Extensions = (props) => {
  const { extensionsCardsData, handleChangeExtension } = useExtensionsData();
  const appState = useSelector((state) => state.appState);

  const dispatch = useDispatch();

  return (
    <>
      {appState.feeWarnAccountType && (
        <Popup
          popUpElement={
            <FeeWarning
              handleProceed={() => {
                if (appState.feeWarnAccountType === "loan") {
                  handleChangeExtension("loan", true);
                }
                if (appState.feeWarnAccountType === "trade") {
                  handleChangeExtension("trade", true);
                }
                dispatch({ type: "SET_FEE_WARN_TYPE", payload: null });
              }}
              handleCancel={() =>
                dispatch({ type: "SET_FEE_WARN_TYPE", payload: null })
              }
            />
          }
          label={"Opening account fee"}
          handlePopUpClose={() =>
            dispatch({ type: "SET_FEE_WARN_TYPE", payload: null })
          }
        />
      )}
      <ExtensionsUI
        translates={props.translates}
        extensionsCardsData={extensionsCardsData}
        disabledAccount={
          !appState?.userData?.active && appState?.userData?.step == "6"
        }
        helpSupportClick={props.helpSupportClick}
      />
    </>
  );
};

export default Extensions;
