import React from "react";

const BnbLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="15.5"
        fill="url(#paint0_linear_1305_19)"
        stroke="url(#paint1_linear_1305_19)"
      />
      <g clipPath="url(#clip0_1305_19)">
        <path
          d="M12.8943 14.723L16.0018 11.6168L19.1106 14.7255L20.9177 12.9171L16.0018 8L11.0859 12.9159L12.8943 14.723Z"
          fill="white"
        />
        <path
          d="M8 16.0006L9.80712 14.1923L11.6155 16.0006L9.80712 17.8078L8 16.0006Z"
          fill="white"
        />
        <path
          d="M12.8943 17.277L16.0018 20.3845L19.1106 17.2758L20.919 19.0816L16.0031 23.9988L11.0859 19.0854L12.8943 17.277Z"
          fill="white"
        />
        <path
          d="M20.3848 16.0006L22.1919 14.1923L24.0003 15.9994L22.1919 17.809L20.3848 16.0006Z"
          fill="white"
        />
        <path
          d="M17.8353 15.9994L16.0016 14.1644L14.6457 15.5204L14.489 15.6758L14.168 15.9968L16.0016 17.8292L17.8353 16.0006V15.9994Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1305_19"
          x1="0.36923"
          y1="1.62319"
          x2="33.9012"
          y2="3.89119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="1" stopColor="white" stopOpacity="0.02" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1305_19"
          x1="6.95385"
          y1="-7.97174e-07"
          x2="24.2795"
          y2="13.7939"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopColor="white" stopOpacity="0.05" />
        </linearGradient>
        <clipPath id="clip0_1305_19">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BnbLogo;
