import React from "react";

const TronLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="15.5"
        fill="url(#paint0_linear_1359_3)"
        stroke="url(#paint1_linear_1359_3)"
      />
      <path
        d="M24.7414 13.4246C23.8556 12.6436 22.6252 11.4537 21.6287 10.6117L21.5671 10.5751C21.4687 10.5019 21.358 10.4409 21.2411 10.3982C18.8296 9.97105 7.60901 7.97582 7.39371 8.00022C7.33219 8.00632 7.27067 8.03073 7.22146 8.06124L7.16609 8.10395C7.09843 8.17107 7.04306 8.25039 7.0123 8.34191L7 8.37852V8.57988V8.61038C8.26109 11.9541 13.2501 22.9004 14.2344 25.4753C14.2959 25.6522 14.4066 25.9817 14.6158 26H14.665C14.7757 26 15.2555 25.3959 15.2555 25.3959C15.2555 25.3959 23.8248 15.5235 24.6922 14.474C24.8029 14.3459 24.9013 14.2056 24.9875 14.0591C25.0121 13.9432 24.9998 13.8273 24.9567 13.7174C24.9136 13.6076 24.8337 13.5039 24.7414 13.4246ZM17.4455 14.5778L21.0996 11.6978L23.2465 13.5771L17.4455 14.5778ZM16.0245 14.3886L9.73134 9.48292L19.9185 11.2707L16.0245 14.3886ZM16.5904 15.67L23.0312 14.6815L15.6677 23.12L16.5904 15.67ZM8.87626 9.97715L15.5016 15.3161L14.5419 23.1261L8.87626 9.97715Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1359_3"
          x1="0.36923"
          y1="1.62319"
          x2="33.9012"
          y2="3.89119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stopOpacity="0.1" />
          <stop offset="1" stop-color="white" stopOpacity="0.02" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1359_3"
          x1="6.95385"
          y1="-7.97174e-07"
          x2="24.2795"
          y2="13.7939"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stopOpacity="0.5" />
          <stop offset="1" stop-color="white" stopOpacity="0.05" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TronLogo;
