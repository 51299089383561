import React from "react";

const StakingIcon = () => {
  return (
    <svg
      width="20"
      height="30"
      viewBox="0 0 20 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.75 15.9751V21.5251C19.75 26.2051 15.385 30.0001 10 30.0001C4.615 30.0001 0.25 26.2051 0.25 21.5251V15.9751C0.25 20.6551 4.615 24.0001 10 24.0001C15.385 24.0001 19.75 20.6551 19.75 15.9751Z"
        fill="white"
      />
      <path
        d="M19.75 8.4751V15.9751C19.75 20.6551 15.385 24.0001 10 24.0001C4.615 24.0001 0.25 20.6551 0.25 15.9751V8.4751C0.25 9.8401 0.625 11.1001 1.285 12.1801C2.89 14.8201 6.19 16.5001 10 16.5001C13.81 16.5001 17.11 14.8201 18.715 12.1801C19.375 11.1001 19.75 9.8401 19.75 8.4751Z"
        fill="#B3B3B3"
      />
      <path
        d="M19.75 8.475C19.75 9.84 19.375 11.1 18.715 12.18C17.11 14.82 13.81 16.5 10 16.5C6.19 16.5 2.89 14.82 1.285 12.18C0.625 11.1 0.25 9.84 0.25 8.475C0.25 3.795 4.615 0 10 0C12.7 0 15.13 0.945 16.9 2.475C18.655 4.02 19.75 6.135 19.75 8.475Z"
        fill="white"
      />
    </svg>
  );
};

export default StakingIcon;
