import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { useAccount, useDisconnect } from "wagmi";

export const useConnectMeta = () => {
  const [connectionLoading, setConnectionLoading] = useState(false);

  const isConn = useSelector((state) => state.connect.isConnected);
  const providerType = useSelector((state) => state.connect.providerType);
  const lastConnectionType = useSelector(
    (state) => state.connect.lastConnectionType
  );
  const addressFromEmail = useSelector((state) => state.connect.address);

  const dispatch = useDispatch();
  let { activate, account, library, deactivate, chainId, active } =
    useWeb3React();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  if (providerType === "walletConnect") account = address;
  if (lastConnectionType === "email") {
    account = addressFromEmail;
  }
  // console.log(providerType, "providerType");
  async function metaMaskEagerlyConnect(
    injected,
    connectCallback,
    errCallback
  ) {
    if (providerType === "metaMask" || true) {
      try {
        let isAuthorized = await injected.isAuthorized();

        if (isAuthorized && isConn) {
          await connectMetaMask(providerType, injected, connectCallback);
        } else {
          dispatch({
            type: "UPDATE_STATE",
            isConnected: false,
            providerType: "",
          });
          dispatch({ type: "SET_TRIED_RECONNECT", payload: true });
          if (errCallback) {
            errCallback();
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  const switchToBscTestnet = async (params = []) => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: params,
        });
        dispatch({
          type: "CONNECTION_ERROR",
          payload: "",
        });
      } else {
        console.log("Can't setup the BSC Testnet on BSC network");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const connectMetaMask = async (
    providerType,
    provider,
    callback,
    errorCallback
  ) => {
    if (typeof window.ethereum === "undefined" && providerType === "metaMask") {
      dispatch({
        type: "CONNECTION_ERROR",
        payload: "No MetaMask detected",
      });
      return;
    }

    if (providerType === "metaMask") {
      setConnectionLoading(true);
    }

    // if (active) {
    //   await disconnectAccount();
    // }

    try {
      new Promise((resolve, reject) => {
        activate(provider, undefined, true).then(resolve).catch(reject);
      })
        .then(() => {
          if (callback) callback();
          dispatch({
            type: "UPDATE_STATE",
            isConnected: true,
            providerType,
          });
        })
        .catch((e) => {
          dispatch({
            type: "UPDATE_STATE",
            isConnected: false,
            providerType: "",
          });

          if (
            e.toString().startsWith("UnsupportedChainIdError") ||
            e.toString().startsWith("t: Unsupported chain id")
          ) {
            dispatch({
              type: "CONNECTION_ERROR",
              payload: "Please switch your network in wallet",
            });
          }

          if (errorCallback) errorCallback();
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({ type: "SET_TRIED_RECONNECT", payload: true });
          }, 500);

          setConnectionLoading(false);
        });
    } catch (error) {
      console.log("Error on connecting: ", error);
    }
  };

  async function disconnectAccount() {
    console.log("disconnect account");
    try {
      if (providerType === "walletConnect") {
        disconnect();
      }

      if (providerType === "metaMask") {
        if (library && library.provider && library.provider.close) {
          await library.provider.close();
          deactivate();
        }
      }

      dispatch({
        type: "UPDATE_STATE",
        providerType: "",
        isConnected: false,
      });
    } catch (error) {
      console.log("Error on disconnect: ", error);
    }
  }

  async function web3PersonalSign(
    library,
    account,
    message,
    callback,
    errCallback
  ) {
    try {
      const signature = await library.eth.personal.sign(message, account);
      if (callback) callback(account, signature);
    } catch (err) {
      if (errCallback) errCallback(err);
    }
  }

  const values = useMemo(
    () => ({
      account: account ?? "",
      active,
      library,
      connectionLoading,
      chainId,
      connectMetaMask,
      disconnectAccount,
      metaMaskEagerlyConnect,
      switchToBscTestnet,
      web3PersonalSign,
    }),
    [account, active, connectionLoading, chainId, library]
  );

  return values;
};
