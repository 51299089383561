import { React, useState } from "react";
import { Link } from "react-router-dom";

import { useMobileWidth } from "../../../hooks/useMobileWidth";
import { LoadingScreen } from "../../UI/loadingScreen/LoadingScreen";
import { DisabledPage } from "../../UI/disabledPage/DisabledPage";
import translates from "../../../translatesNew.json";

import ThreeLineSvg from "../../../assets/svg/ThreeLineSvg";
import FourCubeSvg from "../../../assets/svg/FourCubeSvg";
import HelpQUestionSvg from "../../../assets/svg/HelpQUestionSvg";
import CrownSvg from "../../../assets/svg/CrownSvg";
import DashboardLyautSvg from "../../../assets/svg/DashboardLyautSvg";

import "./DashboardSharedLayout.css";

export const DashboardSharedLayout = ({
  links,
  children,
  loading,
  disabledAccount,
  becomeEliteOnClick,
  helpSupportClick,
  eliteMemberBtnLabel,
  eliteMemberBtnDisabled,
}) => {
  const [navigation, setNavigation] = useState(false);
  const { width } = useMobileWidth();
  let location = window.location;

  let openMenu = (event) => {
    if (width > 1025) {
      if (event) {
        setNavigation(true);
      } else {
        setNavigation(false);
      }
    }
  };
  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="dashboard-content-container">
          <div
            // navigation
            className={`dashboard-sidebar-container ${
              navigation ? "open" : ""
            }`}
            onMouseEnter={() => {
              openMenu(true);
            }}
            onMouseLeave={() => {
              openMenu(false);
            }}
          >
            <div className="dashboard-sidebar-links">
              {links?.map((item, index) => (
                <Link
                  key={index}
                  to={item.to}
                  className={`${
                    width <= 1025 ? "font-12" : "font-14"
                  } dashboard-sidebar-link ${
                    location.pathname === item.to ? "active" : ""
                  }`}
                >
                  {item.svg}
                  <span className="dashboard-sidebar__horisontal-link">
                    {item.label}
                  </span>
                </Link>
              ))}
            </div>
            <div
              className="sidebar-footer"
              style={{ display: `${width <= 1025 ? "none" : "flex"}` }}
            >
              <div className="sidebar-footer-support font-16">
                <ThreeLineSvg />
                <Link to="/term" className="dashboard-sidebar__horisontal-link">
                  {translates.term_use.en}
                </Link>
              </div>
              <div className="sidebar-footer-support font-16">
                <FourCubeSvg />
                <Link
                  to="/privacy"
                  className="dashboard-sidebar__horisontal-link"
                >
                  {translates.privacy_policy.en}
                </Link>
              </div>
              <div className="sidebar-footer-support font-16">
                <HelpQUestionSvg />
                <div
                  className="dashboard-sidebar__horisontal-link"
                  onClick={helpSupportClick}
                >
                  {translates.help_support.en}
                </div>
              </div>
              <div
                className={`dashboard-sidebar-link dashboard-sidebar-link-member`}
                onClick={becomeEliteOnClick}
              >
                <CrownSvg />
                <span className="dashboard-sidebar__horisontal-link font-12">
                  {translates.become_elite_member.en}
                </span>
              </div>
              <div className="sidebar-footer-copyright font-12">
                <Link to="#">&copy; 2023 All rights reserved</Link>
              </div>
            </div>
          </div>
          <div
            className={`dashboard-main-container ${navigation ? "open" : ""} ${
              disabledAccount ? "disabled-page" : ""
            }`}
          >
            {disabledAccount && <DisabledPage order={"dashboard"} />}
            <div className="dashboard-fixed-border" />
            <div className="dashboard-border-container">
              <div className="dashboard-border" />
            </div>
            <div className="dashboard-layout-content">{children}</div>
            <div className="dashboard-layout-background" />
            <DashboardLyautSvg />
          </div>
        </div>
      )}
    </>
  );
};
